import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import Radio from '@atoms/Radio/Radio';
import { setSaveCard, setSelectedCard } from '@slices/checkoutSlice';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import Button from '@atoms/Button/Button';
import KEYS from '@helpers/keys';
import Icon from '@atoms/Icon/Icon';
import Checkbox from '@atoms/Checkbox/Checkbox';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import useCustomer from '@hooks/useCustomer';
import useTranslation from 'next-translate/useTranslation';
import {
  StyledCheckboxWrapper,
  StyledIcon,
  StyledPaymentModeOption,
  StyledPaymentModeOptionLabel,
  StyledPaymentModesBody,
  StyledPipe,
} from '@molecules/CheckoutPaymentOption/CheckoutPaymentOption.styles';
import trackCardPaymentOption, { CardPaymentOption } from '@helpers/analyticsHelpers/trackCardPaymentOption';
import { CreditCardPaymentInfoData } from '@api/generated/storefront';
import { selectCheckoutPayment } from '@slices/checkoutSlice';
import Card from '@icons/card.svg';
import Delete from '@icons/delete.svg';
import Info from '@icons/info.svg';

interface Props {
  savedCards: CreditCardPaymentInfoData[];
  disabled: boolean;
  setDeleteCardId: (cardId: string) => void;
}

const PayexOption = ({ savedCards, disabled, setDeleteCardId }: Props) => {
  const payment = useAppSelector(selectCheckoutPayment);
  const { t } = useTranslation('paymentModes');
  const { customer } = useCustomer();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (payment.saveCard) trackCardPaymentOption(CardPaymentOption.SAVE);
  }, [payment.saveCard]);

  useEffect(() => {
    if (payment.selectedCard?.id === 'new') {
      trackCardPaymentOption(CardPaymentOption.NEW);
    } else if (payment.selectedCard?.id) {
      trackCardPaymentOption(CardPaymentOption.EXISTING);
    }
  }, [payment.selectedCard]);

  return (
    <StyledPaymentModesBody noCards={savedCards.length === 0}>
      {savedCards.map((card) => (
        <StyledPaymentModeOption data-testid="payment-mode-option" key={card.id}>
          <Radio
            theme="secondary"
            checked={payment.selectedCard.id === card.id}
            onChange={() => dispatch(setSelectedCard(card))}
            changeOnKeyEnter
            disabled={disabled}
            id={card.id as string}
          >
            <StyledPaymentModeOptionLabel>
              <StyledIcon svg={Card} size={20} color={disabled ? 'mediumGray' : undefined} />
              <span data-hj-suppress="true">
                {card.id !== 'new' && <ScreenReaderMessage>{t('card->maskedNumber')}</ScreenReaderMessage>}
                <span>{card.maskedNumber}</span>
                {card.expireDate && (
                  <>
                    <StyledPipe>|</StyledPipe>
                    <ScreenReaderMessage>{t('card->expireDate')}</ScreenReaderMessage>
                    {card.expireDate}
                  </>
                )}
              </span>
            </StyledPaymentModeOptionLabel>
          </Radio>
          {card.id !== 'new' && (
            <Button
              type="button"
              tabIndex={0}
              theme="transparent"
              onClick={() => {
                setDeleteCardId(card.id as string);
              }}
              onKeyDown={(e) => {
                if (e.key === KEYS.ENTER) setDeleteCardId(card.id as string);
              }}
              disabled={disabled}
            >
              <Icon title={t('card->delete')} svg={Delete} size={20} />
            </Button>
          )}
        </StyledPaymentModeOption>
      ))}
      {(!savedCards.length || payment.selectedCard.id === 'new') && !customer?.isAnonymous && (
        <StyledCheckboxWrapper>
          <Checkbox
            checked={payment.saveCard}
            onChange={() => dispatch(setSaveCard(!payment.saveCard))}
            id="saveCardCheckbox"
          >
            {t('card->save')}
          </Checkbox>
          <IconTooltip iconProps={{ svg: Info, size: 16 }} content={t('tooltip->saveCard')} />
        </StyledCheckboxWrapper>
      )}
    </StyledPaymentModesBody>
  );
};

export default PayexOption;
