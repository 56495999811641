import React, { forwardRef, ReactNode, Ref, useImperativeHandle, useRef, useState } from 'react';
import Icon from '@atoms/Icon/Icon';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import ErrorMessage from '@molecules/Form/ErrorMessage';
import useInputValidator from '@helpers/form/useInputValidator';
import useTranslation from 'next-translate/useTranslation';
import {
  StyledAppendButton,
  StyledBackButton,
  StyledButtonsAndInputFieldWrapper,
  StyledClearButton,
  StyledContainer,
  StyledInputField,
  StyledInputLabel,
  StyledInputLabelWrapper,
  StyledPrependIcon,
} from './InputField.styles';
import Search from '@icons/search.svg';
import EyeOpen from '@icons/eye-open.svg';
import EyeClosed from '@icons/eye-closed.svg';
import Clear from '@icons/clear.svg';
import ArrowLeft from '@icons/arrow-left.svg';
import Info from '@icons/info.svg';

type VariantType = 'box' | 'line' | 'rounded';

enum PasswordFieldTypes {
  VISIBLE = 'text',
  HIDDEN = 'password',
}

export interface InputFieldProps
  extends Pick<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    | 'aria-autocomplete'
    | 'aria-haspopup'
    | 'aria-label'
    | 'autoComplete'
    | 'className'
    | 'disabled'
    | 'readOnly'
    | 'inputMode'
    | 'maxLength'
    | 'minLength'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'onPaste'
    | 'onPaste'
    | 'pattern'
    | 'placeholder'
    | 'required'
    | 'role'
    | 'tabIndex'
    | 'type'
    | 'value'
  > {
  name: string; // Required for accessible error message
  autocompleteComponent?: ReactNode;
  clearable?: boolean;
  customValidator?: (value: string) => React.ReactNode | string;
  customValidity?: string;
  hasItems?: boolean;
  isQuantityField?: boolean;
  label?: string;
  labelTooltip?: string;
  noValidate?: boolean;
  onClear?: () => void;
  onCloseTakeover?: () => void;
  onValidityChange?: (validity: boolean) => void;
  showPrependIcon?: boolean;
  takeover?: boolean;
  variant?: VariantType;
  passwordVisibilityToggle?: boolean;
  autoFocus?: boolean;
  testId?: string;
  roundedCorners?: boolean;
}

const InputField = forwardRef(
  (
    {
      roundedCorners = false,
      autoComplete,
      autocompleteComponent,
      className,
      clearable,
      customValidator,
      customValidity = '',
      disabled,
      readOnly,
      hasItems = false,
      inputMode,
      isQuantityField = false,
      label,
      labelTooltip,
      maxLength = 250,
      minLength,
      name,
      noValidate,
      onBlur,
      onChange,
      onClear,
      onCloseTakeover,
      onFocus,
      onKeyDown,
      onKeyUp,
      onPaste,
      pattern,
      placeholder,
      required,
      role,
      showPrependIcon = false,
      tabIndex,
      takeover = false,
      type = 'text',
      value = '',
      variant = 'box',
      onValidityChange,
      passwordVisibilityToggle,
      autoFocus,
      testId,
      ...props
    }: InputFieldProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { t } = useTranslation('common');
    const inputRef = useRef<HTMLInputElement>(null);
    const [passwordType, setPasswordType] = useState<PasswordFieldTypes>(PasswordFieldTypes.HIDDEN);

    useImperativeHandle(ref, () => inputRef.current!, [inputRef]);

    const [isDirty, customValidityState] = useInputValidator(inputRef, value as string, customValidity, {
      noValidate,
      required,
      minLength,
      maxLength,
      type,
      customValidator,
      pattern,
      onValidityChange,
    });

    const handlePasswordVisibility = () => {
      setPasswordType(
        passwordType === PasswordFieldTypes.VISIBLE ? PasswordFieldTypes.HIDDEN : PasswordFieldTypes.VISIBLE
      );
    };

    return (
      <StyledContainer invalid={!!customValidityState} takeover={takeover}>
        {(label || labelTooltip) && (
          <StyledInputLabelWrapper>
            {label && (
              <StyledInputLabel>
                {label}
                {labelTooltip && <ScreenReaderMessage>{labelTooltip}</ScreenReaderMessage>}
              </StyledInputLabel>
            )}
            {labelTooltip && (
              <IconTooltip
                content={labelTooltip}
                iconProps={{ svg: Info, size: 16 }}
                align="center"
                position="top"
                color="black"
              />
            )}
          </StyledInputLabelWrapper>
        )}
        <StyledButtonsAndInputFieldWrapper>
          {(takeover || showPrependIcon) && (
            <StyledPrependIcon svg={Search} size={16} color="primary" isTakeover={takeover} />
          )}
          <StyledInputField
            ref={inputRef}
            data-testid={testId}
            aria-haspopup={!!autocompleteComponent || props['aria-haspopup']}
            aria-autocomplete={autocompleteComponent ? 'list' : props['aria-autocomplete']}
            role={role}
            name={name}
            type={type === PasswordFieldTypes.HIDDEN ? passwordType : type}
            autoCapitalize={type === 'email' ? 'none' : undefined}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onPaste={onPaste}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            maxLength={maxLength}
            minLength={minLength}
            pattern={pattern}
            aria-label={props['aria-label'] || label}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            inputMode={inputMode}
            autoComplete={autoComplete}
            takeover={takeover}
            variant={variant}
            className={className}
            showPrependIcon={showPrependIcon}
            isDirty={isDirty}
            aria-describedby={customValidityState && name ? `input-${name}-error` : undefined}
            isQuantityField={isQuantityField}
            hasItems={hasItems}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            hasClearIcon={!!(clearable && value)}
            roundedCorners={roundedCorners}
          />
          {passwordVisibilityToggle && (
            <StyledAppendButton
              onClick={handlePasswordVisibility}
              tabIndex={-1}
              size="x-small"
              color="white"
              aria-label={
                passwordType === PasswordFieldTypes.VISIBLE
                  ? t('common:interaction->hidePassword')
                  : t('common:interaction->showPassword')
              }
            >
              <Icon svg={passwordType === PasswordFieldTypes.VISIBLE ? EyeClosed : EyeOpen} size={16} />
            </StyledAppendButton>
          )}
          {!!clearable && !!value && (
            <StyledClearButton
              onClick={onClear}
              tabIndex={-1}
              theme="transparent"
              aria-label={t('common:interaction->clearInput')}
              isTakeover={takeover}
            >
              <Icon svg={Clear} size={16} />
            </StyledClearButton>
          )}
        </StyledButtonsAndInputFieldWrapper>
        {takeover && (
          <StyledBackButton
            data-testid="search-back-button"
            onClick={onCloseTakeover}
            tabIndex={0}
            size="large"
            color="black"
          >
            <Icon svg={ArrowLeft} size={16} color="white" />
          </StyledBackButton>
        )}
        {autocompleteComponent}
        {isDirty && name && customValidityState && <ErrorMessage name={name}>{customValidityState}</ErrorMessage>}
      </StyledContainer>
    );
  }
);
InputField.displayName = 'InputField';

export default InputField;
