/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Address } from '../cms-dto';
// @ts-ignore
import { Cart } from '../cms-dto';
// @ts-ignore
import { CartList } from '../cms-dto';
// @ts-ignore
import { CartModification } from '../cms-dto';
// @ts-ignore
import { DeliveryMode } from '../cms-dto';
// @ts-ignore
import { DeliveryModeList } from '../cms-dto';
// @ts-ignore
import { OrderEntry } from '../cms-dto';
// @ts-ignore
import { OrderEntryList } from '../cms-dto';
// @ts-ignore
import { PaymentDetails } from '../cms-dto';
// @ts-ignore
import { PromotionResultList } from '../cms-dto';
// @ts-ignore
import { VoucherList } from '../cms-dto';
/**
 * CartsApi - axios parameter creator
 * @export
 */
export const CartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a product to the cart.
         * @summary Adds a product to the cart.
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the product code (product.code), the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCartEntryUsingPOST: async (userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addCartEntryUsingPOST', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('addCartEntryUsingPOST', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('addCartEntryUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'entry' is not null or undefined
            assertParamExists('addCartEntryUsingPOST', 'entry', entry)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Defines the details of a new credit card, and assigns this payment option to the cart.
         * @summary Defines and assigns details of a new credit card payment to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {PaymentDetails} paymentDetails Request body parameter that contains details such as the name on the card (accountHolderName), the card number (cardNumber), the card type (cardType.code), the month of the expiry date (expiryMonth), the year of the expiry date (expiryYear), whether the payment details should be saved (saved), whether the payment details should be set as default (defaultPaymentInfo), and the billing address (billingAddress.firstName, billingAddress.lastName, billingAddress.titleCode, billingAddress.country.isocode, billingAddress.line1, billingAddress.line2, billingAddress.town, billingAddress.postalCode, billingAddress.region.isocode)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPaymentDetailsUsingPOST: async (baseSiteId: string, userId: string, cartId: string, paymentDetails: PaymentDetails, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('addPaymentDetailsUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addPaymentDetailsUsingPOST', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('addPaymentDetailsUsingPOST', 'cartId', cartId)
            // verify required parameter 'paymentDetails' is not null or undefined
            assertParamExists('addPaymentDetailsUsingPOST', 'paymentDetails', paymentDetails)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/paymentdetails`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enables a promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Enables promotions based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromotionUsingPOST: async (promotionId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('applyPromotionUsingPOST', 'promotionId', promotionId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('applyPromotionUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('applyPromotionUsingPOST', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('applyPromotionUsingPOST', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/promotions`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (promotionId !== undefined) {
                localVarQueryParameter['promotionId'] = promotionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Applies a voucher based on the voucherId defined for the cart.
         * @summary Applies a voucher based on the voucherId defined for the cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyVoucherForCartUsingPOST: async (voucherId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('applyVoucherForCartUsingPOST', 'voucherId', voucherId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('applyVoucherForCartUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('applyVoucherForCartUsingPOST', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('applyVoucherForCartUsingPOST', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (voucherId !== undefined) {
                localVarQueryParameter['voucherId'] = voucherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an address and assigns it to the cart as the delivery address.
         * @summary Creates a delivery address for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {Address} address Request body parameter that contains details such as the customer\&#39;s first name (firstName), the customer\&#39;s last name (lastName), the customer\&#39;s title (titleCode), the country (country.isocode), the first part of the address (line1), the second part of the address (line2), the town (town), the postal code (postalCode), and the region (region.isocode).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSetAddressUsingPOST1: async (baseSiteId: string, userId: string, cartId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('createAndSetAddressUsingPOST1', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createAndSetAddressUsingPOST1', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('createAndSetAddressUsingPOST1', 'cartId', cartId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('createAndSetAddressUsingPOST1', 'address', address)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new cart or restores an anonymous cart as a user\'s cart (if an old Cart Id is given in the request).
         * @summary Creates or restore a cart for a user.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [oldCartId] Anonymous cart GUID.
         * @param {string} [toMergeCartGuid] The GUID of the user\&#39;s cart that will be merged with the anonymous cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCartUsingPOST: async (baseSiteId: string, userId: string, oldCartId?: string, toMergeCartGuid?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('createCartUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createCartUsingPOST', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (oldCartId !== undefined) {
                localVarQueryParameter['oldCartId'] = oldCartId;
            }

            if (toMergeCartGuid !== undefined) {
                localVarQueryParameter['toMergeCartGuid'] = toMergeCartGuid;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a cart with a given cart id.
         * @summary Deletes a cart with a given cart id.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCartUsingDELETE: async (baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('deleteCartUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCartUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('deleteCartUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the delivery mode selected for the cart.
         * @summary Get the delivery mode selected for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartDeliveryModeUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCartDeliveryModeUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCartDeliveryModeUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getCartDeliveryModeUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns cart entries.
         * @summary Get cart entries.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartEntriesUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCartEntriesUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCartEntriesUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getCartEntriesUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the cart entries.
         * @summary Get the details of the cart entries.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartEntryUsingGET: async (entryNumber: number, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryNumber' is not null or undefined
            assertParamExists('getCartEntryUsingGET', 'entryNumber', entryNumber)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCartEntryUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCartEntryUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getCartEntryUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}`
                .replace(`{${"entryNumber"}}`, encodeURIComponent(String(entryNumber)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the cart with a given identifier.
         * @summary Get a cart with a given identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCartUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCartUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getCartUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all customer carts.
         * @summary Get all customer carts.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {boolean} [savedCartsOnly] Optional parameter. If the parameter is provided and its value is true, only saved carts are returned.
         * @param {number} [currentPage] Optional pagination parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 0.
         * @param {number} [pageSize] Optional {@link PaginationData} parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 20.
         * @param {string} [sort] Optional sort criterion in case of savedCartsOnly &#x3D;&#x3D; true. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartsUsingGET: async (baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', savedCartsOnly?: boolean, currentPage?: number, pageSize?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCartsUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCartsUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (savedCartsOnly !== undefined) {
                localVarQueryParameter['savedCartsOnly'] = savedCartsOnly;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about a promotion (with a specific promotionId), that has been applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionUsingGET: async (promotionId: string, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('getPromotionUsingGET', 'promotionId', promotionId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPromotionUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPromotionUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getPromotionUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about the promotions applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPromotionsUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPromotionsUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getPromotionsUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/promotions`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all delivery modes supported for the current base store and cart delivery address. A delivery address must be set for the cart, otherwise an empty list will be returned.
         * @summary Get all delivery modes for the current store and delivery address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedDeliveryModesUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getSupportedDeliveryModesUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSupportedDeliveryModesUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getSupportedDeliveryModesUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymodes`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of vouchers applied to the cart.
         * @summary Get a list of vouchers applied to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVouchersUsingGET: async (baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getVouchersUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getVouchersUsingGET', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getVouchersUsingGET', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Assigns an email to the cart. This step is required to make a guest checkout.
         * @summary Assigns an email to the cart.
         * @param {string} email Email of the guest user. It will be used during the checkout process.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestLoginUsingPUT: async (email: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('guestLoginUsingPUT', 'email', email)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('guestLoginUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('guestLoginUsingPUT', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('guestLoginUsingPUT', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/email`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a voucher based on the voucherId defined for the current cart.
         * @summary Delete a voucher defined for the current cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseVoucherFromCartUsingDELETE: async (voucherId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('releaseVoucherFromCartUsingDELETE', 'voucherId', voucherId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('releaseVoucherFromCartUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('releaseVoucherFromCartUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('releaseVoucherFromCartUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/vouchers/{voucherId}`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the delivery address from the cart.
         * @summary Delete the delivery address from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartDeliveryAddressUsingDELETE: async (baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('removeCartDeliveryAddressUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeCartDeliveryAddressUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('removeCartDeliveryAddressUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes cart entry.
         * @summary Deletes cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartEntryUsingDELETE: async (entryNumber: number, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryNumber' is not null or undefined
            assertParamExists('removeCartEntryUsingDELETE', 'entryNumber', entryNumber)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('removeCartEntryUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeCartEntryUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('removeCartEntryUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}`
                .replace(`{${"entryNumber"}}`, encodeURIComponent(String(entryNumber)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the delivery mode from the cart.
         * @summary Delete the delivery mode from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeliveryModeUsingDELETE: async (baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('removeDeliveryModeUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeDeliveryModeUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('removeDeliveryModeUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disables the promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Disables the promotion based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePromotionUsingDELETE: async (promotionId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('removePromotionUsingDELETE', 'promotionId', promotionId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('removePromotionUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removePromotionUsingDELETE', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('removePromotionUsingDELETE', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets a delivery address for the cart. The address country must be placed among the delivery countries of the current base store.
         * @summary Sets a delivery address for the cart.
         * @param {string} addressId Address identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartDeliveryAddressUsingPUT: async (addressId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('setCartDeliveryAddressUsingPUT', 'addressId', addressId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('setCartDeliveryAddressUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setCartDeliveryAddressUsingPUT', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('setCartDeliveryAddressUsingPUT', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/addresses/delivery`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the delivery mode with a given identifier for the cart.
         * @summary Sets the delivery mode for a cart.
         * @param {string} deliveryModeId Delivery mode identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartDeliveryModeUsingPUT: async (deliveryModeId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryModeId' is not null or undefined
            assertParamExists('setCartDeliveryModeUsingPUT', 'deliveryModeId', deliveryModeId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('setCartDeliveryModeUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setCartDeliveryModeUsingPUT', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('setCartDeliveryModeUsingPUT', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/deliverymode`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (deliveryModeId !== undefined) {
                localVarQueryParameter['deliveryModeId'] = deliveryModeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up. Attributes not provided in request will be defined again (set to null or default)
         * @summary Set quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartEntryUsingPUT1: async (entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryNumber' is not null or undefined
            assertParamExists('setCartEntryUsingPUT1', 'entryNumber', entryNumber)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setCartEntryUsingPUT1', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('setCartEntryUsingPUT1', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('setCartEntryUsingPUT1', 'baseSiteId', baseSiteId)
            // verify required parameter 'entry' is not null or undefined
            assertParamExists('setCartEntryUsingPUT1', 'entry', entry)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}`
                .replace(`{${"entryNumber"}}`, encodeURIComponent(String(entryNumber)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets credit card payment details for the cart.
         * @summary Sets credit card payment details for the cart.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentDetailsUsingPUT: async (paymentDetailsId: string, baseSiteId: string, userId: string, cartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetailsId' is not null or undefined
            assertParamExists('setPaymentDetailsUsingPUT', 'paymentDetailsId', paymentDetailsId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('setPaymentDetailsUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setPaymentDetailsUsingPUT', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('setPaymentDetailsUsingPUT', 'cartId', cartId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/paymentdetails`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (paymentDetailsId !== undefined) {
                localVarQueryParameter['paymentDetailsId'] = paymentDetailsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up.
         * @summary Update quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartEntryUsingPATCH1: async (entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryNumber' is not null or undefined
            assertParamExists('updateCartEntryUsingPATCH1', 'entryNumber', entryNumber)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCartEntryUsingPATCH1', 'userId', userId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('updateCartEntryUsingPATCH1', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('updateCartEntryUsingPATCH1', 'baseSiteId', baseSiteId)
            // verify required parameter 'entry' is not null or undefined
            assertParamExists('updateCartEntryUsingPATCH1', 'entry', entry)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}`
                .replace(`{${"entryNumber"}}`, encodeURIComponent(String(entryNumber)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartsApi - functional programming interface
 * @export
 */
export const CartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a product to the cart.
         * @summary Adds a product to the cart.
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the product code (product.code), the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCartEntryUsingPOST(userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartModification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCartEntryUsingPOST(userId, cartId, baseSiteId, entry, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Defines the details of a new credit card, and assigns this payment option to the cart.
         * @summary Defines and assigns details of a new credit card payment to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {PaymentDetails} paymentDetails Request body parameter that contains details such as the name on the card (accountHolderName), the card number (cardNumber), the card type (cardType.code), the month of the expiry date (expiryMonth), the year of the expiry date (expiryYear), whether the payment details should be saved (saved), whether the payment details should be set as default (defaultPaymentInfo), and the billing address (billingAddress.firstName, billingAddress.lastName, billingAddress.titleCode, billingAddress.country.isocode, billingAddress.line1, billingAddress.line2, billingAddress.town, billingAddress.postalCode, billingAddress.region.isocode)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPaymentDetailsUsingPOST(baseSiteId: string, userId: string, cartId: string, paymentDetails: PaymentDetails, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPaymentDetailsUsingPOST(baseSiteId, userId, cartId, paymentDetails, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enables a promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Enables promotions based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyPromotionUsingPOST(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyPromotionUsingPOST(promotionId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Applies a voucher based on the voucherId defined for the cart.
         * @summary Applies a voucher based on the voucherId defined for the cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyVoucherForCartUsingPOST(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyVoucherForCartUsingPOST(voucherId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an address and assigns it to the cart as the delivery address.
         * @summary Creates a delivery address for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {Address} address Request body parameter that contains details such as the customer\&#39;s first name (firstName), the customer\&#39;s last name (lastName), the customer\&#39;s title (titleCode), the country (country.isocode), the first part of the address (line1), the second part of the address (line2), the town (town), the postal code (postalCode), and the region (region.isocode).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndSetAddressUsingPOST1(baseSiteId: string, userId: string, cartId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndSetAddressUsingPOST1(baseSiteId, userId, cartId, address, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new cart or restores an anonymous cart as a user\'s cart (if an old Cart Id is given in the request).
         * @summary Creates or restore a cart for a user.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [oldCartId] Anonymous cart GUID.
         * @param {string} [toMergeCartGuid] The GUID of the user\&#39;s cart that will be merged with the anonymous cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCartUsingPOST(baseSiteId: string, userId: string, oldCartId?: string, toMergeCartGuid?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCartUsingPOST(baseSiteId, userId, oldCartId, toMergeCartGuid, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a cart with a given cart id.
         * @summary Deletes a cart with a given cart id.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCartUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCartUsingDELETE(baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the delivery mode selected for the cart.
         * @summary Get the delivery mode selected for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartDeliveryModeUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryMode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartDeliveryModeUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns cart entries.
         * @summary Get cart entries.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartEntriesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderEntryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartEntriesUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the cart entries.
         * @summary Get the details of the cart entries.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartEntryUsingGET(entryNumber: number, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartEntryUsingGET(entryNumber, baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the cart with a given identifier.
         * @summary Get a cart with a given identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists all customer carts.
         * @summary Get all customer carts.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {boolean} [savedCartsOnly] Optional parameter. If the parameter is provided and its value is true, only saved carts are returned.
         * @param {number} [currentPage] Optional pagination parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 0.
         * @param {number} [pageSize] Optional {@link PaginationData} parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 20.
         * @param {string} [sort] Optional sort criterion in case of savedCartsOnly &#x3D;&#x3D; true. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartsUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', savedCartsOnly?: boolean, currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartsUsingGET(baseSiteId, userId, fields, savedCartsOnly, currentPage, pageSize, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about a promotion (with a specific promotionId), that has been applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionUsingGET(promotionId: string, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionUsingGET(promotionId, baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about the promotions applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionsUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionsUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all delivery modes supported for the current base store and cart delivery address. A delivery address must be set for the cart, otherwise an empty list will be returned.
         * @summary Get all delivery modes for the current store and delivery address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedDeliveryModesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryModeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedDeliveryModesUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of vouchers applied to the cart.
         * @summary Get a list of vouchers applied to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVouchersUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVouchersUsingGET(baseSiteId, userId, cartId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Assigns an email to the cart. This step is required to make a guest checkout.
         * @summary Assigns an email to the cart.
         * @param {string} email Email of the guest user. It will be used during the checkout process.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guestLoginUsingPUT(email: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guestLoginUsingPUT(email, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a voucher based on the voucherId defined for the current cart.
         * @summary Delete a voucher defined for the current cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseVoucherFromCartUsingDELETE(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releaseVoucherFromCartUsingDELETE(voucherId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the delivery address from the cart.
         * @summary Delete the delivery address from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCartDeliveryAddressUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCartDeliveryAddressUsingDELETE(baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes cart entry.
         * @summary Deletes cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCartEntryUsingDELETE(entryNumber: number, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCartEntryUsingDELETE(entryNumber, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the delivery mode from the cart.
         * @summary Delete the delivery mode from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDeliveryModeUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDeliveryModeUsingDELETE(baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Disables the promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Disables the promotion based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePromotionUsingDELETE(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePromotionUsingDELETE(promotionId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets a delivery address for the cart. The address country must be placed among the delivery countries of the current base store.
         * @summary Sets a delivery address for the cart.
         * @param {string} addressId Address identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCartDeliveryAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCartDeliveryAddressUsingPUT(addressId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets the delivery mode with a given identifier for the cart.
         * @summary Sets the delivery mode for a cart.
         * @param {string} deliveryModeId Delivery mode identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCartDeliveryModeUsingPUT(deliveryModeId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCartDeliveryModeUsingPUT(deliveryModeId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up. Attributes not provided in request will be defined again (set to null or default)
         * @summary Set quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCartEntryUsingPUT1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartModification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCartEntryUsingPUT1(entryNumber, userId, cartId, baseSiteId, entry, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets credit card payment details for the cart.
         * @summary Sets credit card payment details for the cart.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPaymentDetailsUsingPUT(paymentDetailsId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPaymentDetailsUsingPUT(paymentDetailsId, baseSiteId, userId, cartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up.
         * @summary Update quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCartEntryUsingPATCH1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartModification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCartEntryUsingPATCH1(entryNumber, userId, cartId, baseSiteId, entry, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartsApi - factory interface
 * @export
 */
export const CartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartsApiFp(configuration)
    return {
        /**
         * Adds a product to the cart.
         * @summary Adds a product to the cart.
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the product code (product.code), the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCartEntryUsingPOST(userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CartModification> {
            return localVarFp.addCartEntryUsingPOST(userId, cartId, baseSiteId, entry, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Defines the details of a new credit card, and assigns this payment option to the cart.
         * @summary Defines and assigns details of a new credit card payment to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {PaymentDetails} paymentDetails Request body parameter that contains details such as the name on the card (accountHolderName), the card number (cardNumber), the card type (cardType.code), the month of the expiry date (expiryMonth), the year of the expiry date (expiryYear), whether the payment details should be saved (saved), whether the payment details should be set as default (defaultPaymentInfo), and the billing address (billingAddress.firstName, billingAddress.lastName, billingAddress.titleCode, billingAddress.country.isocode, billingAddress.line1, billingAddress.line2, billingAddress.town, billingAddress.postalCode, billingAddress.region.isocode)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPaymentDetailsUsingPOST(baseSiteId: string, userId: string, cartId: string, paymentDetails: PaymentDetails, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PaymentDetails> {
            return localVarFp.addPaymentDetailsUsingPOST(baseSiteId, userId, cartId, paymentDetails, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Enables a promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Enables promotions based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromotionUsingPOST(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.applyPromotionUsingPOST(promotionId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Applies a voucher based on the voucherId defined for the cart.
         * @summary Applies a voucher based on the voucherId defined for the cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyVoucherForCartUsingPOST(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.applyVoucherForCartUsingPOST(voucherId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an address and assigns it to the cart as the delivery address.
         * @summary Creates a delivery address for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {Address} address Request body parameter that contains details such as the customer\&#39;s first name (firstName), the customer\&#39;s last name (lastName), the customer\&#39;s title (titleCode), the country (country.isocode), the first part of the address (line1), the second part of the address (line2), the town (town), the postal code (postalCode), and the region (region.isocode).  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSetAddressUsingPOST1(baseSiteId: string, userId: string, cartId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Address> {
            return localVarFp.createAndSetAddressUsingPOST1(baseSiteId, userId, cartId, address, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new cart or restores an anonymous cart as a user\'s cart (if an old Cart Id is given in the request).
         * @summary Creates or restore a cart for a user.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [oldCartId] Anonymous cart GUID.
         * @param {string} [toMergeCartGuid] The GUID of the user\&#39;s cart that will be merged with the anonymous cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCartUsingPOST(baseSiteId: string, userId: string, oldCartId?: string, toMergeCartGuid?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Cart> {
            return localVarFp.createCartUsingPOST(baseSiteId, userId, oldCartId, toMergeCartGuid, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a cart with a given cart id.
         * @summary Deletes a cart with a given cart id.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCartUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCartUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the delivery mode selected for the cart.
         * @summary Get the delivery mode selected for the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartDeliveryModeUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<DeliveryMode> {
            return localVarFp.getCartDeliveryModeUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns cart entries.
         * @summary Get cart entries.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartEntriesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<OrderEntryList> {
            return localVarFp.getCartEntriesUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the cart entries.
         * @summary Get the details of the cart entries.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartEntryUsingGET(entryNumber: number, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<OrderEntry> {
            return localVarFp.getCartEntryUsingGET(entryNumber, baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the cart with a given identifier.
         * @summary Get a cart with a given identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Cart> {
            return localVarFp.getCartUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all customer carts.
         * @summary Get all customer carts.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {boolean} [savedCartsOnly] Optional parameter. If the parameter is provided and its value is true, only saved carts are returned.
         * @param {number} [currentPage] Optional pagination parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 0.
         * @param {number} [pageSize] Optional {@link PaginationData} parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 20.
         * @param {string} [sort] Optional sort criterion in case of savedCartsOnly &#x3D;&#x3D; true. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartsUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', savedCartsOnly?: boolean, currentPage?: number, pageSize?: number, sort?: string, options?: any): AxiosPromise<CartList> {
            return localVarFp.getCartsUsingGET(baseSiteId, userId, fields, savedCartsOnly, currentPage, pageSize, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about a promotion (with a specific promotionId), that has been applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionUsingGET(promotionId: string, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PromotionResultList> {
            return localVarFp.getPromotionUsingGET(promotionId, baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about the promotions applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Get information about promotions applied on cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PromotionResultList> {
            return localVarFp.getPromotionsUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all delivery modes supported for the current base store and cart delivery address. A delivery address must be set for the cart, otherwise an empty list will be returned.
         * @summary Get all delivery modes for the current store and delivery address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedDeliveryModesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<DeliveryModeList> {
            return localVarFp.getSupportedDeliveryModesUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of vouchers applied to the cart.
         * @summary Get a list of vouchers applied to the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVouchersUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<VoucherList> {
            return localVarFp.getVouchersUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Assigns an email to the cart. This step is required to make a guest checkout.
         * @summary Assigns an email to the cart.
         * @param {string} email Email of the guest user. It will be used during the checkout process.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guestLoginUsingPUT(email: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.guestLoginUsingPUT(email, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a voucher based on the voucherId defined for the current cart.
         * @summary Delete a voucher defined for the current cart.
         * @param {string} voucherId Voucher identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseVoucherFromCartUsingDELETE(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.releaseVoucherFromCartUsingDELETE(voucherId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the delivery address from the cart.
         * @summary Delete the delivery address from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartDeliveryAddressUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeCartDeliveryAddressUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes cart entry.
         * @summary Deletes cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCartEntryUsingDELETE(entryNumber: number, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeCartEntryUsingDELETE(entryNumber, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the delivery mode from the cart.
         * @summary Delete the delivery mode from the cart.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeliveryModeUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeDeliveryModeUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Disables the promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
         * @summary Disables the promotion based on the promotionsId of the cart.
         * @param {string} promotionId Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePromotionUsingDELETE(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removePromotionUsingDELETE(promotionId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets a delivery address for the cart. The address country must be placed among the delivery countries of the current base store.
         * @summary Sets a delivery address for the cart.
         * @param {string} addressId Address identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartDeliveryAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setCartDeliveryAddressUsingPUT(addressId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets the delivery mode with a given identifier for the cart.
         * @summary Sets the delivery mode for a cart.
         * @param {string} deliveryModeId Delivery mode identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartDeliveryModeUsingPUT(deliveryModeId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setCartDeliveryModeUsingPUT(deliveryModeId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up. Attributes not provided in request will be defined again (set to null or default)
         * @summary Set quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCartEntryUsingPUT1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CartModification> {
            return localVarFp.setCartEntryUsingPUT1(entryNumber, userId, cartId, baseSiteId, entry, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets credit card payment details for the cart.
         * @summary Sets credit card payment details for the cart.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentDetailsUsingPUT(paymentDetailsId: string, baseSiteId: string, userId: string, cartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setPaymentDetailsUsingPUT(paymentDetailsId, baseSiteId, userId, cartId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up.
         * @summary Update quantity and store details of a cart entry.
         * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartEntryUsingPATCH1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CartModification> {
            return localVarFp.updateCartEntryUsingPATCH1(entryNumber, userId, cartId, baseSiteId, entry, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartsApi - object-oriented interface
 * @export
 * @class CartsApi
 * @extends {BaseAPI}
 */
export class CartsApi extends BaseAPI {
    /**
     * Adds a product to the cart.
     * @summary Adds a product to the cart.
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {OrderEntry} entry Request body parameter that contains details such as the product code (product.code), the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name).  The DTO is in XML or .json format.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public addCartEntryUsingPOST(userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).addCartEntryUsingPOST(userId, cartId, baseSiteId, entry, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Defines the details of a new credit card, and assigns this payment option to the cart.
     * @summary Defines and assigns details of a new credit card payment to the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {PaymentDetails} paymentDetails Request body parameter that contains details such as the name on the card (accountHolderName), the card number (cardNumber), the card type (cardType.code), the month of the expiry date (expiryMonth), the year of the expiry date (expiryYear), whether the payment details should be saved (saved), whether the payment details should be set as default (defaultPaymentInfo), and the billing address (billingAddress.firstName, billingAddress.lastName, billingAddress.titleCode, billingAddress.country.isocode, billingAddress.line1, billingAddress.line2, billingAddress.town, billingAddress.postalCode, billingAddress.region.isocode)  The DTO is in XML or .json format.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public addPaymentDetailsUsingPOST(baseSiteId: string, userId: string, cartId: string, paymentDetails: PaymentDetails, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).addPaymentDetailsUsingPOST(baseSiteId, userId, cartId, paymentDetails, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enables a promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     * @summary Enables promotions based on the promotionsId of the cart.
     * @param {string} promotionId Promotion identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public applyPromotionUsingPOST(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).applyPromotionUsingPOST(promotionId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Applies a voucher based on the voucherId defined for the cart.
     * @summary Applies a voucher based on the voucherId defined for the cart.
     * @param {string} voucherId Voucher identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public applyVoucherForCartUsingPOST(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).applyVoucherForCartUsingPOST(voucherId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an address and assigns it to the cart as the delivery address.
     * @summary Creates a delivery address for the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {Address} address Request body parameter that contains details such as the customer\&#39;s first name (firstName), the customer\&#39;s last name (lastName), the customer\&#39;s title (titleCode), the country (country.isocode), the first part of the address (line1), the second part of the address (line2), the town (town), the postal code (postalCode), and the region (region.isocode).  The DTO is in XML or .json format.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public createAndSetAddressUsingPOST1(baseSiteId: string, userId: string, cartId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).createAndSetAddressUsingPOST1(baseSiteId, userId, cartId, address, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new cart or restores an anonymous cart as a user\'s cart (if an old Cart Id is given in the request).
     * @summary Creates or restore a cart for a user.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [oldCartId] Anonymous cart GUID.
     * @param {string} [toMergeCartGuid] The GUID of the user\&#39;s cart that will be merged with the anonymous cart.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public createCartUsingPOST(baseSiteId: string, userId: string, oldCartId?: string, toMergeCartGuid?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).createCartUsingPOST(baseSiteId, userId, oldCartId, toMergeCartGuid, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a cart with a given cart id.
     * @summary Deletes a cart with a given cart id.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public deleteCartUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).deleteCartUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the delivery mode selected for the cart.
     * @summary Get the delivery mode selected for the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getCartDeliveryModeUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getCartDeliveryModeUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns cart entries.
     * @summary Get cart entries.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getCartEntriesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getCartEntriesUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the cart entries.
     * @summary Get the details of the cart entries.
     * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getCartEntryUsingGET(entryNumber: number, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getCartEntryUsingGET(entryNumber, baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the cart with a given identifier.
     * @summary Get a cart with a given identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getCartUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getCartUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists all customer carts.
     * @summary Get all customer carts.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {boolean} [savedCartsOnly] Optional parameter. If the parameter is provided and its value is true, only saved carts are returned.
     * @param {number} [currentPage] Optional pagination parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 0.
     * @param {number} [pageSize] Optional {@link PaginationData} parameter in case of savedCartsOnly &#x3D;&#x3D; true. Default value 20.
     * @param {string} [sort] Optional sort criterion in case of savedCartsOnly &#x3D;&#x3D; true. No default value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getCartsUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', savedCartsOnly?: boolean, currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getCartsUsingGET(baseSiteId, userId, fields, savedCartsOnly, currentPage, pageSize, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about a promotion (with a specific promotionId), that has been applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     * @summary Get information about promotions applied on cart.
     * @param {string} promotionId Promotion identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getPromotionUsingGET(promotionId: string, baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getPromotionUsingGET(promotionId, baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about the promotions applied on the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     * @summary Get information about promotions applied on cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getPromotionsUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getPromotionsUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all delivery modes supported for the current base store and cart delivery address. A delivery address must be set for the cart, otherwise an empty list will be returned.
     * @summary Get all delivery modes for the current store and delivery address.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getSupportedDeliveryModesUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getSupportedDeliveryModesUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of vouchers applied to the cart.
     * @summary Get a list of vouchers applied to the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public getVouchersUsingGET(baseSiteId: string, userId: string, cartId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).getVouchersUsingGET(baseSiteId, userId, cartId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Assigns an email to the cart. This step is required to make a guest checkout.
     * @summary Assigns an email to the cart.
     * @param {string} email Email of the guest user. It will be used during the checkout process.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public guestLoginUsingPUT(email: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).guestLoginUsingPUT(email, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a voucher based on the voucherId defined for the current cart.
     * @summary Delete a voucher defined for the current cart.
     * @param {string} voucherId Voucher identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public releaseVoucherFromCartUsingDELETE(voucherId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).releaseVoucherFromCartUsingDELETE(voucherId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the delivery address from the cart.
     * @summary Delete the delivery address from the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public removeCartDeliveryAddressUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).removeCartDeliveryAddressUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes cart entry.
     * @summary Deletes cart entry.
     * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public removeCartEntryUsingDELETE(entryNumber: number, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).removeCartEntryUsingDELETE(entryNumber, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the delivery mode from the cart.
     * @summary Delete the delivery mode from the cart.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public removeDeliveryModeUsingDELETE(baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).removeDeliveryModeUsingDELETE(baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disables the promotion for the order based on the promotionId defined for the cart. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers, and as a result, some of them are currently not compatible with the new promotions engine.
     * @summary Disables the promotion based on the promotionsId of the cart.
     * @param {string} promotionId Promotion identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public removePromotionUsingDELETE(promotionId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).removePromotionUsingDELETE(promotionId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets a delivery address for the cart. The address country must be placed among the delivery countries of the current base store.
     * @summary Sets a delivery address for the cart.
     * @param {string} addressId Address identifier
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public setCartDeliveryAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).setCartDeliveryAddressUsingPUT(addressId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets the delivery mode with a given identifier for the cart.
     * @summary Sets the delivery mode for a cart.
     * @param {string} deliveryModeId Delivery mode identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public setCartDeliveryModeUsingPUT(deliveryModeId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).setCartDeliveryModeUsingPUT(deliveryModeId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up. Attributes not provided in request will be defined again (set to null or default)
     * @summary Set quantity and store details of a cart entry.
     * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public setCartEntryUsingPUT1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).setCartEntryUsingPUT1(entryNumber, userId, cartId, baseSiteId, entry, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets credit card payment details for the cart.
     * @summary Sets credit card payment details for the cart.
     * @param {string} paymentDetailsId Payment details identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public setPaymentDetailsUsingPUT(paymentDetailsId: string, baseSiteId: string, userId: string, cartId: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).setPaymentDetailsUsingPUT(paymentDetailsId, baseSiteId, userId, cartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the quantity of a single cart entry and the details of the store where the cart entry will be picked up.
     * @summary Update quantity and store details of a cart entry.
     * @param {number} entryNumber The entry number. Each entry in a cart has an entry number. Cart entries are numbered in ascending order, starting with zero (0).
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {OrderEntry} entry Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)  The DTO is in XML or .json format.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public updateCartEntryUsingPATCH1(entryNumber: number, userId: string, cartId: string, baseSiteId: string, entry: OrderEntry, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).updateCartEntryUsingPATCH1(entryNumber, userId, cartId, baseSiteId, entry, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
