import { StyledHotJarStyleChanger } from './HotJarStyleChanger.styles';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import useResponsive from '@hooks/useResponsive';

const HotJarStyleChanger = () => {
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { fromDesktop } = useResponsive();

  return <StyledHotJarStyleChanger hide={fromDesktop && isCartPreviewOpen} />;
};

export default HotJarStyleChanger;
