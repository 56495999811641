/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Product } from '../cms-dto';
// @ts-ignore
import { ProductExpressUpdateElementList } from '../cms-dto';
// @ts-ignore
import { ProductReferenceList } from '../cms-dto';
// @ts-ignore
import { ProductSearchPage } from '../cms-dto';
// @ts-ignore
import { Review } from '../cms-dto';
// @ts-ignore
import { ReviewList } from '../cms-dto';
// @ts-ignore
import { Stock } from '../cms-dto';
// @ts-ignore
import { StoreFinderStockSearchPage } from '../cms-dto';
// @ts-ignore
import { SuggestionList } from '../cms-dto';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of a product\'s stock levels. The following two sets of parameters are available: location (required); or longitude (required), and latitude (required).
         * @summary Get header with a total number of product\'s stock levels
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countSearchProductStockByLocationUsingHEAD: async (productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('countSearchProductStockByLocationUsingHEAD', 'productCode', productCode)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('countSearchProductStockByLocationUsingHEAD', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/{productCode}/stock`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * In the response header, the \"x-total-count\" indicates the total number of products satisfying a query.
         * @summary Get a header with total number of products
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countSearchProductsUsingHEAD: async (baseSiteId: string, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('countSearchProductsUsingHEAD', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/search`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new customer review as an anonymous user.
         * @summary Creates a new customer review as an anonymous user
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {Review} review Object contains review details like : rating, alias, headline, comment
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewUsingPOST1: async (productCode: string, baseSiteId: string, review: Review, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('createReviewUsingPOST1', 'productCode', productCode)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('createReviewUsingPOST1', 'baseSiteId', baseSiteId)
            // verify required parameter 'review' is not null or undefined
            assertParamExists('createReviewUsingPOST1', 'review', review)
            const localVarPath = `/{baseSiteId}/products/{productCode}/reviews`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(review, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns references for a product with a given product code. Reference type specifies which references to return.
         * @summary Get a product reference
         * @param {string} productCode Product identifier
         * @param {string} referenceType Reference type according to enum ProductReferenceTypeEnum
         * @param {string} baseSiteId Base site identifier
         * @param {number} [pageSize] Maximum size of returned results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductReferencesUsingGET: async (productCode: string, referenceType: string, baseSiteId: string, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('exportProductReferencesUsingGET', 'productCode', productCode)
            // verify required parameter 'referenceType' is not null or undefined
            assertParamExists('exportProductReferencesUsingGET', 'referenceType', referenceType)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('exportProductReferencesUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/{productCode}/references`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (referenceType !== undefined) {
                localVarQueryParameter['referenceType'] = referenceType;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns products added to the express update feed. Returns only elements updated after the provided timestamp. The queue is cleared using a defined cronjob.
         * @summary Get products added to the express update feed
         * @param {string} timestamp Only items newer than the given parameter are retrieved from the queue. This parameter should be in ISO-8601 format.
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalog] Only products from this catalog are returned. Format: catalogId:catalogVersion
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expressUpdateUsingGET: async (timestamp: string, baseSiteId: string, catalog?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('expressUpdateUsingGET', 'timestamp', timestamp)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('expressUpdateUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/expressupdate`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (catalog !== undefined) {
                localVarQueryParameter['catalog'] = catalog;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns details of a single product according to a product code.
         * @summary Get product details
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByCodeUsingGET: async (productCode: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getProductByCodeUsingGET', 'productCode', productCode)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getProductByCodeUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/{productCode}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the reviews for a product with a given product code.
         * @summary Get reviews for a product
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {number} [maxCount] Maximum count of reviews
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingGET: async (productCode: string, baseSiteId: string, maxCount?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getProductReviewsUsingGET', 'productCode', productCode)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getProductReviewsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/{productCode}/reviews`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (maxCount !== undefined) {
                localVarQueryParameter['maxCount'] = maxCount;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a product\'s stock level for a particular store (in other words, for a particular point of sale).
         * @summary Get a product\'s stock level for a store
         * @param {string} baseSiteId Base site identifier
         * @param {string} productCode Product identifier
         * @param {string} storeName Store identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockDataUsingGET: async (baseSiteId: string, productCode: string, storeName: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getStockDataUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getStockDataUsingGET', 'productCode', productCode)
            // verify required parameter 'storeName' is not null or undefined
            assertParamExists('getStockDataUsingGET', 'storeName', storeName)
            const localVarPath = `/{baseSiteId}/products/{productCode}/stock/{storeName}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"storeName"}}`, encodeURIComponent(String(storeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all available suggestions related to a given term and limits the results to a specific value of the max parameter.
         * @summary Get a list of available suggestions
         * @param {string} term Specified term
         * @param {number} max Specifies the limit of results.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionsUsingGET: async (term: string, max: number, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            assertParamExists('getSuggestionsUsingGET', 'term', term)
            // verify required parameter 'max' is not null or undefined
            assertParamExists('getSuggestionsUsingGET', 'max', max)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getSuggestionsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/suggestions`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a product\'s stock levels sorted by distance from the specified location, which is provided using the free-text \"location\" parameter, or by using the longitude and latitude parameters. The following two sets of parameters are available: location (required), currentPage (optional), pageSize (optional); or longitude (required), latitude (required), currentPage (optional), pageSize(optional).
         * @summary Get a product\'s stock level
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductStockByLocationUsingGET: async (productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('searchProductStockByLocationUsingGET', 'productCode', productCode)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('searchProductStockByLocationUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/{productCode}/stock`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of products and additional data, such as available facets, available sorting, and pagination options. It can also include spelling suggestions. To make spelling suggestions work, you need to make sure that \"enableSpellCheck\" on the SearchQuery is set to \"true\" (by default, it should already be set to \"true\"). You also need to have indexed properties configured to be used for spellchecking.
         * @summary Get a list of products and additional data
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [sort] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {string} [fields] The context to be used in the search query.
         * @param {string} [searchQueryContext] searchQueryContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductsUsingGET: async (baseSiteId: string, query?: string, currentPage?: number, pageSize?: number, sort?: 'BASIC' | 'DEFAULT' | 'FULL', fields?: string, searchQueryContext?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('searchProductsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/products/search`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (searchQueryContext !== undefined) {
                localVarQueryParameter['searchQueryContext'] = searchQueryContext;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of a product\'s stock levels. The following two sets of parameters are available: location (required); or longitude (required), and latitude (required).
         * @summary Get header with a total number of product\'s stock levels
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countSearchProductStockByLocationUsingHEAD(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countSearchProductStockByLocationUsingHEAD(productCode, baseSiteId, location, latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * In the response header, the \"x-total-count\" indicates the total number of products satisfying a query.
         * @summary Get a header with total number of products
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countSearchProductsUsingHEAD(baseSiteId: string, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countSearchProductsUsingHEAD(baseSiteId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new customer review as an anonymous user.
         * @summary Creates a new customer review as an anonymous user
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {Review} review Object contains review details like : rating, alias, headline, comment
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReviewUsingPOST1(productCode: string, baseSiteId: string, review: Review, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReviewUsingPOST1(productCode, baseSiteId, review, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns references for a product with a given product code. Reference type specifies which references to return.
         * @summary Get a product reference
         * @param {string} productCode Product identifier
         * @param {string} referenceType Reference type according to enum ProductReferenceTypeEnum
         * @param {string} baseSiteId Base site identifier
         * @param {number} [pageSize] Maximum size of returned results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProductReferencesUsingGET(productCode: string, referenceType: string, baseSiteId: string, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReferenceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportProductReferencesUsingGET(productCode, referenceType, baseSiteId, pageSize, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns products added to the express update feed. Returns only elements updated after the provided timestamp. The queue is cleared using a defined cronjob.
         * @summary Get products added to the express update feed
         * @param {string} timestamp Only items newer than the given parameter are retrieved from the queue. This parameter should be in ISO-8601 format.
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalog] Only products from this catalog are returned. Format: catalogId:catalogVersion
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expressUpdateUsingGET(timestamp: string, baseSiteId: string, catalog?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductExpressUpdateElementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expressUpdateUsingGET(timestamp, baseSiteId, catalog, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns details of a single product according to a product code.
         * @summary Get product details
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByCodeUsingGET(productCode: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByCodeUsingGET(productCode, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the reviews for a product with a given product code.
         * @summary Get reviews for a product
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {number} [maxCount] Maximum count of reviews
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductReviewsUsingGET(productCode: string, baseSiteId: string, maxCount?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductReviewsUsingGET(productCode, baseSiteId, maxCount, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a product\'s stock level for a particular store (in other words, for a particular point of sale).
         * @summary Get a product\'s stock level for a store
         * @param {string} baseSiteId Base site identifier
         * @param {string} productCode Product identifier
         * @param {string} storeName Store identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockDataUsingGET(baseSiteId: string, productCode: string, storeName: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stock>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockDataUsingGET(baseSiteId, productCode, storeName, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all available suggestions related to a given term and limits the results to a specific value of the max parameter.
         * @summary Get a list of available suggestions
         * @param {string} term Specified term
         * @param {number} max Specifies the limit of results.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestionsUsingGET(term: string, max: number, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestionsUsingGET(term, max, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a product\'s stock levels sorted by distance from the specified location, which is provided using the free-text \"location\" parameter, or by using the longitude and latitude parameters. The following two sets of parameters are available: location (required), currentPage (optional), pageSize (optional); or longitude (required), latitude (required), currentPage (optional), pageSize(optional).
         * @summary Get a product\'s stock level
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductStockByLocationUsingGET(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreFinderStockSearchPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductStockByLocationUsingGET(productCode, baseSiteId, location, latitude, longitude, currentPage, pageSize, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of products and additional data, such as available facets, available sorting, and pagination options. It can also include spelling suggestions. To make spelling suggestions work, you need to make sure that \"enableSpellCheck\" on the SearchQuery is set to \"true\" (by default, it should already be set to \"true\"). You also need to have indexed properties configured to be used for spellchecking.
         * @summary Get a list of products and additional data
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [sort] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {string} [fields] The context to be used in the search query.
         * @param {string} [searchQueryContext] searchQueryContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductsUsingGET(baseSiteId: string, query?: string, currentPage?: number, pageSize?: number, sort?: 'BASIC' | 'DEFAULT' | 'FULL', fields?: string, searchQueryContext?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductsUsingGET(baseSiteId, query, currentPage, pageSize, sort, fields, searchQueryContext, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of a product\'s stock levels. The following two sets of parameters are available: location (required); or longitude (required), and latitude (required).
         * @summary Get header with a total number of product\'s stock levels
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countSearchProductStockByLocationUsingHEAD(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, options?: any): AxiosPromise<void> {
            return localVarFp.countSearchProductStockByLocationUsingHEAD(productCode, baseSiteId, location, latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * In the response header, the \"x-total-count\" indicates the total number of products satisfying a query.
         * @summary Get a header with total number of products
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countSearchProductsUsingHEAD(baseSiteId: string, query?: string, options?: any): AxiosPromise<void> {
            return localVarFp.countSearchProductsUsingHEAD(baseSiteId, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new customer review as an anonymous user.
         * @summary Creates a new customer review as an anonymous user
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {Review} review Object contains review details like : rating, alias, headline, comment
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewUsingPOST1(productCode: string, baseSiteId: string, review: Review, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Review> {
            return localVarFp.createReviewUsingPOST1(productCode, baseSiteId, review, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns references for a product with a given product code. Reference type specifies which references to return.
         * @summary Get a product reference
         * @param {string} productCode Product identifier
         * @param {string} referenceType Reference type according to enum ProductReferenceTypeEnum
         * @param {string} baseSiteId Base site identifier
         * @param {number} [pageSize] Maximum size of returned results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductReferencesUsingGET(productCode: string, referenceType: string, baseSiteId: string, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductReferenceList> {
            return localVarFp.exportProductReferencesUsingGET(productCode, referenceType, baseSiteId, pageSize, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns products added to the express update feed. Returns only elements updated after the provided timestamp. The queue is cleared using a defined cronjob.
         * @summary Get products added to the express update feed
         * @param {string} timestamp Only items newer than the given parameter are retrieved from the queue. This parameter should be in ISO-8601 format.
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalog] Only products from this catalog are returned. Format: catalogId:catalogVersion
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expressUpdateUsingGET(timestamp: string, baseSiteId: string, catalog?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductExpressUpdateElementList> {
            return localVarFp.expressUpdateUsingGET(timestamp, baseSiteId, catalog, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns details of a single product according to a product code.
         * @summary Get product details
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByCodeUsingGET(productCode: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Product> {
            return localVarFp.getProductByCodeUsingGET(productCode, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the reviews for a product with a given product code.
         * @summary Get reviews for a product
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {number} [maxCount] Maximum count of reviews
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingGET(productCode: string, baseSiteId: string, maxCount?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ReviewList> {
            return localVarFp.getProductReviewsUsingGET(productCode, baseSiteId, maxCount, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a product\'s stock level for a particular store (in other words, for a particular point of sale).
         * @summary Get a product\'s stock level for a store
         * @param {string} baseSiteId Base site identifier
         * @param {string} productCode Product identifier
         * @param {string} storeName Store identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockDataUsingGET(baseSiteId: string, productCode: string, storeName: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Stock> {
            return localVarFp.getStockDataUsingGET(baseSiteId, productCode, storeName, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all available suggestions related to a given term and limits the results to a specific value of the max parameter.
         * @summary Get a list of available suggestions
         * @param {string} term Specified term
         * @param {number} max Specifies the limit of results.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionsUsingGET(term: string, max: number, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SuggestionList> {
            return localVarFp.getSuggestionsUsingGET(term, max, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a product\'s stock levels sorted by distance from the specified location, which is provided using the free-text \"location\" parameter, or by using the longitude and latitude parameters. The following two sets of parameters are available: location (required), currentPage (optional), pageSize (optional); or longitude (required), latitude (required), currentPage (optional), pageSize(optional).
         * @summary Get a product\'s stock level
         * @param {string} productCode Product identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [location] Free-text location
         * @param {number} [latitude] Latitude location parameter.
         * @param {number} [longitude] Longitude location parameter.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductStockByLocationUsingGET(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<StoreFinderStockSearchPage> {
            return localVarFp.searchProductStockByLocationUsingGET(productCode, baseSiteId, location, latitude, longitude, currentPage, pageSize, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of products and additional data, such as available facets, available sorting, and pagination options. It can also include spelling suggestions. To make spelling suggestions work, you need to make sure that \"enableSpellCheck\" on the SearchQuery is set to \"true\" (by default, it should already be set to \"true\"). You also need to have indexed properties configured to be used for spellchecking.
         * @summary Get a list of products and additional data
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [sort] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {string} [fields] The context to be used in the search query.
         * @param {string} [searchQueryContext] searchQueryContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductsUsingGET(baseSiteId: string, query?: string, currentPage?: number, pageSize?: number, sort?: 'BASIC' | 'DEFAULT' | 'FULL', fields?: string, searchQueryContext?: string, options?: any): AxiosPromise<ProductSearchPage> {
            return localVarFp.searchProductsUsingGET(baseSiteId, query, currentPage, pageSize, sort, fields, searchQueryContext, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * In the response header, the \"x-total-count\" indicates the total number of a product\'s stock levels. The following two sets of parameters are available: location (required); or longitude (required), and latitude (required).
     * @summary Get header with a total number of product\'s stock levels
     * @param {string} productCode Product identifier
     * @param {string} baseSiteId Base site identifier
     * @param {string} [location] Free-text location
     * @param {number} [latitude] Latitude location parameter.
     * @param {number} [longitude] Longitude location parameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public countSearchProductStockByLocationUsingHEAD(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).countSearchProductStockByLocationUsingHEAD(productCode, baseSiteId, location, latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * In the response header, the \"x-total-count\" indicates the total number of products satisfying a query.
     * @summary Get a header with total number of products
     * @param {string} baseSiteId Base site identifier
     * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public countSearchProductsUsingHEAD(baseSiteId: string, query?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).countSearchProductsUsingHEAD(baseSiteId, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new customer review as an anonymous user.
     * @summary Creates a new customer review as an anonymous user
     * @param {string} productCode Product identifier
     * @param {string} baseSiteId Base site identifier
     * @param {Review} review Object contains review details like : rating, alias, headline, comment
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createReviewUsingPOST1(productCode: string, baseSiteId: string, review: Review, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createReviewUsingPOST1(productCode, baseSiteId, review, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns references for a product with a given product code. Reference type specifies which references to return.
     * @summary Get a product reference
     * @param {string} productCode Product identifier
     * @param {string} referenceType Reference type according to enum ProductReferenceTypeEnum
     * @param {string} baseSiteId Base site identifier
     * @param {number} [pageSize] Maximum size of returned results.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public exportProductReferencesUsingGET(productCode: string, referenceType: string, baseSiteId: string, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).exportProductReferencesUsingGET(productCode, referenceType, baseSiteId, pageSize, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns products added to the express update feed. Returns only elements updated after the provided timestamp. The queue is cleared using a defined cronjob.
     * @summary Get products added to the express update feed
     * @param {string} timestamp Only items newer than the given parameter are retrieved from the queue. This parameter should be in ISO-8601 format.
     * @param {string} baseSiteId Base site identifier
     * @param {string} [catalog] Only products from this catalog are returned. Format: catalogId:catalogVersion
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public expressUpdateUsingGET(timestamp: string, baseSiteId: string, catalog?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).expressUpdateUsingGET(timestamp, baseSiteId, catalog, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns details of a single product according to a product code.
     * @summary Get product details
     * @param {string} productCode Product identifier
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductByCodeUsingGET(productCode: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductByCodeUsingGET(productCode, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the reviews for a product with a given product code.
     * @summary Get reviews for a product
     * @param {string} productCode Product identifier
     * @param {string} baseSiteId Base site identifier
     * @param {number} [maxCount] Maximum count of reviews
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductReviewsUsingGET(productCode: string, baseSiteId: string, maxCount?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductReviewsUsingGET(productCode, baseSiteId, maxCount, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a product\'s stock level for a particular store (in other words, for a particular point of sale).
     * @summary Get a product\'s stock level for a store
     * @param {string} baseSiteId Base site identifier
     * @param {string} productCode Product identifier
     * @param {string} storeName Store identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getStockDataUsingGET(baseSiteId: string, productCode: string, storeName: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getStockDataUsingGET(baseSiteId, productCode, storeName, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all available suggestions related to a given term and limits the results to a specific value of the max parameter.
     * @summary Get a list of available suggestions
     * @param {string} term Specified term
     * @param {number} max Specifies the limit of results.
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getSuggestionsUsingGET(term: string, max: number, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getSuggestionsUsingGET(term, max, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a product\'s stock levels sorted by distance from the specified location, which is provided using the free-text \"location\" parameter, or by using the longitude and latitude parameters. The following two sets of parameters are available: location (required), currentPage (optional), pageSize (optional); or longitude (required), latitude (required), currentPage (optional), pageSize(optional).
     * @summary Get a product\'s stock level
     * @param {string} productCode Product identifier
     * @param {string} baseSiteId Base site identifier
     * @param {string} [location] Free-text location
     * @param {number} [latitude] Latitude location parameter.
     * @param {number} [longitude] Longitude location parameter.
     * @param {number} [currentPage] The current result page requested.
     * @param {number} [pageSize] The number of results returned per page.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public searchProductStockByLocationUsingGET(productCode: string, baseSiteId: string, location?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).searchProductStockByLocationUsingGET(productCode, baseSiteId, location, latitude, longitude, currentPage, pageSize, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of products and additional data, such as available facets, available sorting, and pagination options. It can also include spelling suggestions. To make spelling suggestions work, you need to make sure that \"enableSpellCheck\" on the SearchQuery is set to \"true\" (by default, it should already be set to \"true\"). You also need to have indexed properties configured to be used for spellchecking.
     * @summary Get a list of products and additional data
     * @param {string} baseSiteId Base site identifier
     * @param {string} [query] Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
     * @param {number} [currentPage] The current result page requested.
     * @param {number} [pageSize] The number of results returned per page.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [sort] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {string} [fields] The context to be used in the search query.
     * @param {string} [searchQueryContext] searchQueryContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public searchProductsUsingGET(baseSiteId: string, query?: string, currentPage?: number, pageSize?: number, sort?: 'BASIC' | 'DEFAULT' | 'FULL', fields?: string, searchQueryContext?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).searchProductsUsingGET(baseSiteId, query, currentPage, pageSize, sort, fields, searchQueryContext, options).then((request) => request(this.axios, this.basePath));
    }
}
