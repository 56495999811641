import { CardDataType, discountTypes } from '@molecules/PersonalElementsCard/PersonalElementsCard';
import { dateToHourAndMinutes, dateToShortMonth, formatToShortDate, isToday } from '@helpers/date';
import type {
  AxfoodDigitalReceiptElementViewModel,
  AxfoodExternalVoucher,
  AxfoodOrderElementViewModel,
  CustomerData,
} from '@api/generated/storefront';
import type { Translate } from 'next-translate';

export const handleOrderData = (
  data: AxfoodOrderElementViewModel,
  t: Translate,
  customer?: CustomerData,
  cart?: any
): CardDataType => {
  const { nextDay, deliveryType, slotStartDate = 0, slotEndDate, orderStatus, orderCode } = data;
  // Set title
  const title =
    orderStatus === 'COMPLETED'
      ? t('order->status->delivered')
      : orderStatus === 'CANCELLED' || orderStatus === 'CANCELLING'
      ? t('order->status->cancelledOrCancelling')
      : t('order->status->ongoing');

  // Set text
  const time = `${dateToHourAndMinutes(slotStartDate)}-${dateToHourAndMinutes(slotEndDate)}`;
  const date = nextDay
    ? `${t('order->tomorrow')} ${time}`
    : isToday(slotStartDate)
    ? `${t('order->today')} ${time}`
    : `${dateToShortMonth(slotStartDate)} ${time}`;
  const text = `${t(`order->${deliveryType?.code}`)} ${date}`;

  // Set button
  let button = t('order->button->show');

  if (customer?.idInSourceSystem === data.placedByIdInSourceSystem) {
    if (orderStatus === 'CHANGING' || cart?.orderReference === orderCode) {
      button = t('order->button->cancel');
    } else if (data.editable) {
      button = t('order->button->change');
    }
  }

  return { title, text, button };
};

export const handleReceiptData = (data: AxfoodDigitalReceiptElementViewModel, t: Translate): CardDataType => {
  return {
    title: t('digitalReceipt->title'),
    text: t('digitalReceipt->text', {
      mode: data.digitalReceiptAvailable ? t('digitalReceipt->inStore') : t('digitalReceipt->online'),
      date: data.bookingDate ? formatToShortDate(data.bookingDate) : '',
    }),
    button: t('digitalReceipt->button'),
  };
};

export const handleVoucherData = (voucher: AxfoodExternalVoucher, t: Translate): CardDataType => {
  const unit = voucher.discountType === discountTypes.ABSOLUTE ? 'kr' : '%';
  const title = `${t('voucher->title')}:${voucher.discountValue}${unit}`;
  const text = `${t('voucher->text')} ${voucher.expirationDate}`;
  const button = t('voucher->button');
  return { title, text, button };
};
