import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';

const arrangePriceString = (priceString: string) => {
  if (priceString.indexOf(',') !== -1) {
    // eslint-disable-next-line no-param-reassign
    priceString = priceString.replace(',', '.');
  }
  if (priceString.indexOf('kr') !== -1) {
    // eslint-disable-next-line no-param-reassign
    priceString = priceString.slice(0, -3);
  }
  return priceString;
};

// Converts a floating point amount in kr to öre
const convertKronorToOre = (numberOfKronor: string | number) => {
  if (typeof numberOfKronor === 'string') {
    // eslint-disable-next-line no-param-reassign
    numberOfKronor = arrangePriceString(numberOfKronor);
  }
  // This will not always be correct, but is good enough for analytics
  return Math.round(+numberOfKronor * 100);
};

// running inside react native part of axfood app (iOS or Android)
const isReactNative = () => !!window?.ReactNativeWebView;
// running inside iOS WKWebView inside axfood app
// @ts-ignore
const isIosApp = () => typeof webkit !== 'undefined';
// running inside Android webview inside axfood app
const isAndroidApp = () => typeof window?.axfoodAppHandler !== 'undefined';

export const sendMessageToAppViaJavascript = (messageDictionary: Record<string, any>) => {
  if (isReactNative()) {
    // running inside React Native webview
    window?.ReactNativeWebView.postMessage(JSON.stringify(messageDictionary));
  } else if (isIosApp()) {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    webkit?.messageHandlers?.axfoodAppHandler?.postMessage(messageDictionary);
  } else if (isAndroidApp()) {
    const json = JSON.stringify(messageDictionary);
    window?.axfoodAppHandler?.postMessage(json);
  } else {
    // No app handler available. Ignore attempt to send message.
  }
};

// This file defines the API between the Willys and its hybrid apps.
// It consists of a number of methods that the website can call to send messages
// to the app.
//
// The API is defined by the app developers, and supplied to the web developers.
//
// To use the API, the file should be included on the website,
// which can then send messages to the app via the global webToAppApi object.
//
// Examples:
//      webToAppApi.eventCartUpdated(12)
//      webToAppApi.actionRouteToAddress('Mölndalsvägen 85, Göteborg')
const webToAppApi = {
  eventAddToCart: (product: AxfoodProductDetailsViewModel, quantity: number, cartcount: number) => {
    // const { code: productId, name: productName, priceValue } = product;
    // const event = 'addtocart';
    // const currency = 'SEK';
    // const fromProductDetails = 'unknown';
    // const productCategory = 'unknown';
    // const price = convertKronorToOre(priceValue);
    // const message = {
    //   event,
    //   cartcount,
    //   currency,
    //   fromProductDetails,
    //   productCategory,
    //   price,
    //   productId,
    //   productName,
    //   quantity,
    // };
    // Implement when app is ready
    // sendMessageToAppViaJavascript(message);
  },

  eventRemoveFromCart: (product: AxfoodProductDetailsViewModel, cartcount: number) => {
    // const { code: productId } = product;
    // const event = 'removefromcart';
    // const message = { event, cartcount, productId };
    // Implement when app is ready
    // sendMessageToAppViaJavascript(message);
  },

  eventCartUpdated: (cartcount: number) => {
    const message = { event: 'cartupdated', cartcount };
    sendMessageToAppViaJavascript(message);
  },

  eventBeginCheckout: (cartcount: number, price: string, currency: string) => {
    const message = {
      event: 'begincheckout',
      cartcount,
      price: convertKronorToOre(price),
      currency,
    };
    sendMessageToAppViaJavascript(message);
  },

  eventPurchase: (
    cartcount: number,
    price: string | number, // - price of the items in the cart as a floating point number (kr)
    currency: string, // - currency in 3-letter <a href="http://en.wikipedia.org/wiki/ISO_4217#Active_codes">ISO_4217</a> format, e.g. 'SEK'
    tax: string | number, // - the tax amount, as a floating point number (kr)
    shipping: string | number,
    coupon: string,
    transactionId: string
  ) => {
    const message = {
      event: 'purchase',
      cartcount,
      price: convertKronorToOre(price),
      currency,
      tax: convertKronorToOre(tax),
      shipping: convertKronorToOre(shipping),
      coupon,
      transactionId,
    };
    sendMessageToAppViaJavascript(message);
  },

  actionEndPurchaseFlow: () => {
    const message = { action: 'endpurchaseflow' };
    sendMessageToAppViaJavascript(message);
  },

  eventShoppingListCreated: (listName: string) => {
    const message = { event: 'listcreated', listName };
    sendMessageToAppViaJavascript(message);
  },

  eventShoppingListAddItem: (listId: string, productEntry: ProductEntryType) => {
    const { entryType: itemType, productCode, promotionCode, productName, quantity, price } = productEntry;
    const productId = productCode || promotionCode;

    const message = {
      event: 'listadditem',
      listId,
      itemType,
      productId,
      productName,
      productCategory: '',
      quantity,
      price: convertKronorToOre(price),
      currency: 'SEK',
    };

    sendMessageToAppViaJavascript(message);
  },

  eventShoppingListRemoveItem: (listId: string, productId: string) => {
    const message = { event: 'listremoveitem', listId, productId };
    sendMessageToAppViaJavascript(message);
  },

  eventShoppingListRemoved: (listId: string) => {
    const message = { event: 'listremoved', listId };
    sendMessageToAppViaJavascript(message);
  },

  eventShoppingListAddedToCart: (cartcount: number, listId: string) => {
    const message = { event: 'shoppinglistaddedtocart', cartcount, listId };
    sendMessageToAppViaJavascript(message);
  },

  eventOrderAddedToList: (listId: string, orderId: string, quantity: number) => {
    const message = { event: 'orderaddedtolist', listId, orderId, quantity };
    sendMessageToAppViaJavascript(message);
  },

  eventDeliveryMethodChanged: () => {
    const message = { event: 'deliverymethodchanged' };
    sendMessageToAppViaJavascript(message);
  },

  eventBasestoreChanged: () => {
    const message = { event: 'basestorechanged' };
    sendMessageToAppViaJavascript(message);
  },

  eventAppReady: () => {
    sendMessageToAppViaJavascript({ event: 'frontendready' });
  },

  eventCancelOrderUpdate: (cartcount: number) => {
    const message = { event: 'cancelorderupdate', cartcount };
    sendMessageToAppViaJavascript(message);
  },

  eventCreditCardUpdate: () => {
    const message = { event: 'creditcardupdate' };
    sendMessageToAppViaJavascript(message);
  },

  actionShowUpdatedCartAfterAddingOrderToCart: (cartcount: number) => {
    const message = { action: 'showupdatedcartafteraddingordertocart', cartcount };
    sendMessageToAppViaJavascript(message);
  },

  actionShowUpdatedCartAfterReopeningOrder: (cartcount: number) => {
    const message = { action: 'showupdatedcartafterreopeningorder', cartcount };
    sendMessageToAppViaJavascript(message);
  },

  actionTextMessage: (to: string, text: string) => {
    const message = { action: 'textmessage', to, text };
    sendMessageToAppViaJavascript(message);
  },

  actionTextMessageWithoutRecepient: (text: string) => {
    const message = { action: 'textmessagewithoutrecepient', text };
    sendMessageToAppViaJavascript(message);
  },

  actionOpenOrderDetails: (orderId?: string, orderURL?: string) => {
    const message = { action: 'openorderdetails', orderId, orderURL };
    sendMessageToAppViaJavascript(message);
  },

  actionOpenFAQItem: (faqItemId: string, faqItemURL: string) => {
    const message = { action: 'openfaqitem', faqItemId, faqItemURL };
    sendMessageToAppViaJavascript(message);
  },

  actionOpenBonusInfo: (elementId: string) => {
    const message = { action: 'openbonusinfo', elementId };
    sendMessageToAppViaJavascript(message);
  },

  actionDisplayCartCount: (cartcount: number) => {
    const message = { action: 'displaycartcount', cartcount };
    sendMessageToAppViaJavascript(message);
  },

  actionLogoutOnRemoveAccount: () => {
    const message = { action: 'logout_on_remove_account' };
    sendMessageToAppViaJavascript(message);
  },

  actionRenewAccessToken: () => {
    const message = { action: 'renew_access_token' };
    sendMessageToAppViaJavascript(message);
  },

  // Call this to let the app know that the user has entered a new "step"
  // of a flow, e.g. forgot-password, create-account or login.
  // Supply a human-readable stepId, e.g. "forgot_password_step_2"
  eventBeginStep: (stepId: string) => {
    const message = { event: 'beginstep', step: stepId };
    sendMessageToAppViaJavascript(message);
  },

  // Call this to let the app know that a validation error was presented
  // to the user, e.g. in the forgot-password or create-account flows,
  // so the app can log it to analytics
  eventValidationError: (error: string) => {
    const message = { event: 'validationError', error };
    sendMessageToAppViaJavascript(message);
  },

  eventStoreFlyerOpened: (storeId?: string, url?: string) => {
    const message = { event: 'storeflyeropened', store_id: storeId, url };
    sendMessageToAppViaJavascript(message);
  },

  eventOffersSwitchedTab: (tab: string) => {
    const tabId = (tab === 'butik' ? 1 : 2).toString();
    const tabName = tab === 'butik' ? 'Erbjudanden i butik' : 'Erbjudanden online';
    const message = { event: 'offersswitchedtab', tab_id: tabId, tab_name: tabName };
    sendMessageToAppViaJavascript(message);
  },

  eventOffersSwitchedStore: (storeId: string) => {
    const message = { event: 'offersswitchedstore', store_id: storeId };
    sendMessageToAppViaJavascript(message);
  },

  eventOffersFiltered: (filterType: string) => {
    const message = { event: 'offersfiltered', filter_type: filterType };
    sendMessageToAppViaJavascript(message);
  },

  eventClickLogin: () => {
    const message = { event: 'click_login' };
    sendMessageToAppViaJavascript(message);
  },

  eventLoginFailure: (userId: string, error: string) => {
    const message = { event: 'login_failure', error, user_id: userId };
    sendMessageToAppViaJavascript(message);
  },

  eventClickForgotPassword: () => {
    const message = { event: 'click_login_forgot_password' };
    sendMessageToAppViaJavascript(message);
  },

  eventResetPasswordSuccess: (method: string) => {
    const message = { event: 'reset_password_success', method };
    sendMessageToAppViaJavascript(message);
  },

  eventResetPasswordFailure: (method: string, error: string) => {
    const message = { event: 'reset_password_failure', method, error };
    sendMessageToAppViaJavascript(message);
  },

  actionEndPasswordResetFlow: () => {
    const message = { action: 'end_reset_password_flow' };
    sendMessageToAppViaJavascript(message);
  },

  eventClickCreateAccount: () => {
    const message = { event: 'click_login_create_account' };
    sendMessageToAppViaJavascript(message);
  },

  eventCreateAccountSuccess: () => {
    const message = { event: 'create_account_success' };
    sendMessageToAppViaJavascript(message);
  },

  eventCreateAccountFailure: (userId: string, error: string) => {
    const message = { event: 'create_account_failure', error, user_id: userId };
    sendMessageToAppViaJavascript(message);
  },

  eventCreateAccountAlreadyAMember: (description: string) => {
    const message = { event: 'create_account_already_a_member', description };
    sendMessageToAppViaJavascript(message);
  },

  actionRouteToAddress: (address: string) => {
    const message = { action: 'routetoaddress', address };
    sendMessageToAppViaJavascript(message);
  },

  eventStoreDetailsPanelExpand: (storeId: string, panelId: string) => {
    const message = { event: 'store_details_expand', store_id: storeId, panel_id: panelId };
    sendMessageToAppViaJavascript(message);
  },

  actionSendMailWithoutRecepient: (subject: string, body: string) => {
    const message = { action: 'sendmailwithoutrecepient', subject, body };
    sendMessageToAppViaJavascript(message);
  },

  actionSendMailWithRecepient: (to: string, subject: string, body: string) => {
    const message = { action: 'sendmailwithrecepient', to, subject, body };
    sendMessageToAppViaJavascript(message);
  },

  // Tell app to open all outgoing links for payment
  paymentstarted: () => {
    const message = { event: 'paymentstarted' };
    sendMessageToAppViaJavascript(message);
  },

  // Tell app to close all outgoing links after payment
  paymentended: () => {
    const message = { event: 'paymentended' };
    sendMessageToAppViaJavascript(message);
  },

  actionRouteToSpecificList: (listId: string) => {
    const message = { action: 'route_to_specific_list', listId };
    sendMessageToAppViaJavascript(message);
  },

  actionShareWishlistUrl: (url: string) => {
    const message = { action: 'share_wishlist_url', url };
    sendMessageToAppViaJavascript(message);
  },

  eventCookieConsentSettings: () => {
    const message = { event: 'cookie_consent_settings' };
    sendMessageToAppViaJavascript(message);
  },

  klarnaOverlay: (visible: boolean) => {
    const message = { event: 'klarna_overlay', visible };
    sendMessageToAppViaJavascript(message);
  },
};

export default webToAppApi;
