import axios, { AxiosResponse } from 'axios';
import { AxfoodCartViewModel } from '@api/generated/storefront';

const startChangingOrder = (orderID?: string): Promise<AxiosResponse<AxfoodCartViewModel>> => {
  const API_URL = `/axfood/rest/cart/carts`;

  const data = new FormData();
  data.set('orderNumber', orderID!);

  return axios.post(API_URL, data);
};

export default startChangingOrder;
