import { getPage } from '@api/interfaces/pageApi';
import useSWR from 'swr';
import useCustomRouter from '@hooks/useCustomRouter';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';
import { PageApi } from '@api/generated/cms';
import useCustomer from '@hooks/useCustomer';

type KeyType = {
  label?: string;
  pageType?: string;
  code?: string;
  cmsTicketId?: string;
};

export const endpoint = 'cms/page';
const api = new PageApi(undefined, '/axfoodcommercewebservices/v2');

const fetcher = async ({ label, pageType, code, cmsTicketId }: KeyType) => {
  return await getPage({ label, pageType, code, cmsTicketId, api });
};

/**
 * Hook for fetching cms pages.
 *
 * Changes to customer, storeId, deliveryModeCode will trigger a revalidate
 */
const useCmsPage = (label?: string, pageType = 'ContentPage', code?: string) => {
  const router = useCustomRouter();
  const { store } = useStore();
  const { cart } = useCart();
  const { customer } = useCustomer();

  const storeId = store?.storeId;
  const slotCode = cart?.slot?.code;

  const cmsTicketId = typeof router?.query.cmsTicketId === 'string' ? router?.query.cmsTicketId : undefined;

  const key = {
    endpoint,
    label,
    pageType,
    code,
    cmsTicketId,
    customerId: customer?.uid,
    storeId,
    slotCode,
  };

  const { data, error, isLoading } = useSWR(key, fetcher, {
    keepPreviousData: true,
  });

  return {
    cmsPage: data,
    isLoading,
    error,
  };
};

export default useCmsPage;
