import { useImpressionTracker } from 'impression-tracker-react-hook';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import logger from '@logger';
import { ReactNode } from 'react';

export interface Props {
  index: number;
  product: AxfoodProductDetailsViewModel;
  children: ReactNode;
  callback: (product: AxfoodProductDetailsViewModel, index: number) => void;
}

const TrackedComponent = ({ index, children, product, callback }: Props) => {
  const logImpression = () => {
    callback(product, index);
  };

  const handleError = (error: Error) => {
    logger.error({ error: 'Product tracking impression failed', message: error.message });
  };

  const [ref] = useImpressionTracker({ logImpression, handleError, contentId: product.code });

  return <div ref={ref}>{children}</div>;
};

export default TrackedComponent;
