import axios, { AxiosRequestConfig } from 'axios';

type Props = {
  q: string;
  categoryPath: string;
  size?: number;
  page?: number;
  code?: string;
  sort?: string;
  config?: AxiosRequestConfig;
};

const getCategory = ({ q, categoryPath, size = 30, page = 0, sort, config = {} }: Props) => {
  const API_URL = `/c/${categoryPath}`;

  return axios.get(API_URL, {
    params: {
      size,
      page,
      q,
      sort,
    },
    ...config,
  });
};
export default getCategory;
