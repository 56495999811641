/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Promotion } from '../cms-dto';
// @ts-ignore
import { PromotionList } from '../cms-dto';
/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns details of a single promotion specified by a promotion code. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a promotion based on code
         * @param {string} code Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionByCodeUsingGET: async (code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getPromotionByCodeUsingGET', 'code', code)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPromotionByCodeUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/promotions/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns promotions defined for a current base site. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a list of promotions
         * @param {'all' | 'product' | 'order'} type Defines what type of promotions should be returned. Values supported for that parameter are: &lt;ul&gt;&lt;li&gt;all: All available promotions are returned&lt;/li&gt;&lt;li&gt;product: Only product promotions are returned&lt;/li&gt;&lt;li&gt;order: Only order promotions are returned&lt;/li&gt;&lt;/ul&gt;
         * @param {string} baseSiteId Base site identifier
         * @param {string} [promotionGroup] Only promotions from this group are returned
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET1: async (type: 'all' | 'product' | 'order', baseSiteId: string, promotionGroup?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getPromotionsUsingGET1', 'type', type)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPromotionsUsingGET1', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/promotions`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (promotionGroup !== undefined) {
                localVarQueryParameter['promotionGroup'] = promotionGroup;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns details of a single promotion specified by a promotion code. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a promotion based on code
         * @param {string} code Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionByCodeUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionByCodeUsingGET(code, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns promotions defined for a current base site. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a list of promotions
         * @param {'all' | 'product' | 'order'} type Defines what type of promotions should be returned. Values supported for that parameter are: &lt;ul&gt;&lt;li&gt;all: All available promotions are returned&lt;/li&gt;&lt;li&gt;product: Only product promotions are returned&lt;/li&gt;&lt;li&gt;order: Only order promotions are returned&lt;/li&gt;&lt;/ul&gt;
         * @param {string} baseSiteId Base site identifier
         * @param {string} [promotionGroup] Only promotions from this group are returned
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionsUsingGET1(type: 'all' | 'product' | 'order', baseSiteId: string, promotionGroup?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionsUsingGET1(type, baseSiteId, promotionGroup, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * Returns details of a single promotion specified by a promotion code. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a promotion based on code
         * @param {string} code Promotion identifier (code)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionByCodeUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Promotion> {
            return localVarFp.getPromotionByCodeUsingGET(code, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns promotions defined for a current base site. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
         * @summary Get a list of promotions
         * @param {'all' | 'product' | 'order'} type Defines what type of promotions should be returned. Values supported for that parameter are: &lt;ul&gt;&lt;li&gt;all: All available promotions are returned&lt;/li&gt;&lt;li&gt;product: Only product promotions are returned&lt;/li&gt;&lt;li&gt;order: Only order promotions are returned&lt;/li&gt;&lt;/ul&gt;
         * @param {string} baseSiteId Base site identifier
         * @param {string} [promotionGroup] Only promotions from this group are returned
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET1(type: 'all' | 'product' | 'order', baseSiteId: string, promotionGroup?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PromotionList> {
            return localVarFp.getPromotionsUsingGET1(type, baseSiteId, promotionGroup, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * Returns details of a single promotion specified by a promotion code. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
     * @summary Get a promotion based on code
     * @param {string} code Promotion identifier (code)
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getPromotionByCodeUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).getPromotionByCodeUsingGET(code, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns promotions defined for a current base site. Requests pertaining to promotions have been developed for the previous version of promotions and vouchers and therefore some of them are currently not compatible with the new promotion engine.
     * @summary Get a list of promotions
     * @param {'all' | 'product' | 'order'} type Defines what type of promotions should be returned. Values supported for that parameter are: &lt;ul&gt;&lt;li&gt;all: All available promotions are returned&lt;/li&gt;&lt;li&gt;product: Only product promotions are returned&lt;/li&gt;&lt;li&gt;order: Only order promotions are returned&lt;/li&gt;&lt;/ul&gt;
     * @param {string} baseSiteId Base site identifier
     * @param {string} [promotionGroup] Only promotions from this group are returned
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getPromotionsUsingGET1(type: 'all' | 'product' | 'order', baseSiteId: string, promotionGroup?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).getPromotionsUsingGET1(type, baseSiteId, promotionGroup, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
