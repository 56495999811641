import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useLayoutEffect from '@hooks/useIsomorphicLayoutEffect';
import Config from '@config';

// from https://github.com/contra/react-responsive/issues/162#issuecomment-592082035

function useResponsive() {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  const isTablet = useMediaQuery({
    query: Config.BREAKPOINTS.IS_TABLET,
  });

  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });

  const fromDesktopLarge = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_LARGE,
  });

  const fromDesktopXL = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_XLARGE,
  });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isMobile: isClient ? isMobile : false,
    fromDesktop: isClient ? fromDesktop : false,
    belowDesktop: isClient ? isMobile || isTablet : false,
    fromDesktopLarge: isClient ? fromDesktopLarge : false,
    fromDesktopXL: isClient ? fromDesktopXL : false,
    isClient,
    isTablet,
  };
}

export default useResponsive;
