import { ReactNode } from 'react';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import GridContainer from '@organisms/GridContainer/GridContainer';
import NavigationBreadcrumbComponent from '@molecules/NavigationBreadcrumbComponent/NavigationBreadcrumbComponent';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import {
  StyledContentWrapper,
  StyledRightContentWrapper,
  StyledSlot,
  StyledMainContentWrapper,
} from './WillysStoreTemplate.styles';
import type { PageWithSlots } from '@api/interfaces/pageApi';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  storeSlots: string[];
}

const WillysStoreTemplate = ({ cmsPage, children, storeSlots }: Props) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { TopWide, MainContent1, MainContent2, MainContent3, RightContent } = collectSlots(cmsPage.slots, storeSlots);
  const hasRightContent = RightContent?.component?.length > 0;
  const { isSmartEditEnabled } = useSmartEdit();
  const { breadcrumbs = [] } = cmsPage;

  return (
    <>
      {(isSmartEditEnabled || TopWide?.component?.length > 0) && (
        <StyledSlot slotData={TopWide} data-testid="topWide-slot">
          {TopWide?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </StyledSlot>
      )}

      {children && children}

      <GridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
        <StyledContentWrapper menuCartOpen={sideMenuIsOpen && isCartPreviewOpen}>
          <StyledMainContentWrapper
            hasRightContent={hasRightContent}
            hideSlot={sideMenuIsOpen && isCartPreviewOpen}
            cartOpen={isCartPreviewOpen}
          >
            {(isSmartEditEnabled || MainContent1?.component?.length > 0) && (
              <StyledSlot slotData={MainContent1} data-testid="mainContent-slot1">
                {breadcrumbs.length > 0 && <NavigationBreadcrumbComponent data={breadcrumbs} />}
                {MainContent1?.component?.map((child) => (
                  <CmsComponent key={child.uuid} component={child} />
                ))}
              </StyledSlot>
            )}

            {(isSmartEditEnabled || MainContent2?.component?.length > 0) && (
              <StyledSlot slotData={MainContent2} data-testid="mainContent-slot2">
                {MainContent2?.component?.map((child) => (
                  <CmsComponent key={child.uuid} component={child} />
                ))}
              </StyledSlot>
            )}

            {(isSmartEditEnabled || MainContent3?.component?.length > 0) && (
              <StyledSlot slotData={MainContent3} data-testid="mainContent-slot3">
                {MainContent3?.component?.map((child) => (
                  <CmsComponent key={child.uuid} component={child} />
                ))}
              </StyledSlot>
            )}
          </StyledMainContentWrapper>

          {(isSmartEditEnabled || RightContent?.component?.length > 0) && (
            <StyledRightContentWrapper menuCartOpen={isCartPreviewOpen && sideMenuIsOpen}>
              {(isSmartEditEnabled || RightContent.component.length > 0) && (
                <StyledSlot slotData={RightContent} data-testid="rightContent-slot">
                  {RightContent?.component?.map((child) => (
                    <CmsComponent key={child.uuid} component={child} />
                  ))}
                </StyledSlot>
              )}
            </StyledRightContentWrapper>
          )}
        </StyledContentWrapper>
      </GridContainer>
    </>
  );
};

export default WillysStoreTemplate;
