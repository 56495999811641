import {
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodOfflineProductViewModel,
  AxfoodProductDetailsViewModel,
  AxfoodWishListEntryViewModel,
  OrderDataV2Response,
} from '@api/generated/storefront';
import getPickUnit from '@helpers/getPickUnit';
import getQuantity from './productHelper';
import { isOfflinePromotion } from '@helpers/typeGuards/product';

type PickUnitType = ProductEntryType['pickUnit'];
const makeUnit = (
  product:
    | AxfoodProductDetailsViewModel
    | AxfoodOfflineProductViewModel
    | AxfoodBasicProductViewModel
    | AxfoodCartProductViewModel
) =>
  'pickUnit' in product
    ? product?.pickUnit?.code || 'pieces'
    : getPickUnit(product?.productBasketType?.code) || 'pieces';

export const makeSingleOnlineEntry = (
  product: AxfoodCartProductViewModel | AxfoodBasicProductViewModel,
  quantity: number | null
): ProductEntryType => {
  const unit = makeUnit(product);
  return {
    entryType: 'PRODUCT',
    pickUnit: unit as PickUnitType,
    productCode: product.code,
    productName: product.name,
    price: product.price || '',
    quantity: (quantity === 0 ? 1 : quantity) || (unit === 'kilogram' ? 0.1 : 1),
  };
};

export const makeSingleWishListOnlineEntry = (
  product: AxfoodBasicProductViewModel,
  quantity: number | null
): AxfoodWishListEntryViewModel => {
  const unit = makeUnit(product);

  return {
    entryType: 'PRODUCT',
    pickUnit: unit,
    quantity: quantity || (unit === 'kilogram' ? 0.1 : 1),
    product,
    checked: false,
    salableInStore: true,
  };
};

export const makeSingleOfflineEntry = (
  product: AxfoodOfflineProductViewModel | AxfoodBasicProductViewModel,
  quantity: number | null
): ProductEntryType => {
  const promotion = product?.potentialPromotions && product.potentialPromotions[0];
  const unit = makeUnit(product);

  if (promotion) {
    return {
      pickUnit: unit as PickUnitType,
      entryType: 'PROMOTION',
      promotionCode: promotion?.code,
      productName: isOfflinePromotion(promotion) && promotion.name ? promotion.name : '',
      productCode: promotion?.mainProductCode,
      price: promotion.price ? promotion.price.toString() : '',
      quantity: quantity && quantity > 1 ? quantity : 1,
    };
  }
  return {} as ProductEntryType;
};

/**
 * This function is only used in the cart and will not be used to create entries of offline products
 */
export const makeProductEntries = (products?: Array<AxfoodCartProductViewModel>): Array<ProductEntryType> => {
  return (products || [])?.map((product) => makeSingleOnlineEntry(product, getQuantity(product)));
};

export const makeProductList = (categories: OrderDataV2Response['categoryOrderedDeliveredProducts']) => {
  if (!categories) {
    return [];
  }
  return Object.entries(categories).flatMap(([category, products]) =>
    products.map((product) => makeSingleOnlineEntry(product, getQuantity(product)))
  );
};

export const makeFreeTextEntry = (freeText: string): AxfoodWishListEntryViewModel => {
  return {
    entryType: 'FREETEXT',
    freeTextProduct: freeText,
    quantity: 1,
    checked: false,
    salableInStore: false,
  };
};
