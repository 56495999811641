/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Catalog } from '../cms-dto';
// @ts-ignore
import { CatalogList } from '../cms-dto';
// @ts-ignore
import { CatalogVersion } from '../cms-dto';
// @ts-ignore
import { CategoryHierarchy } from '../cms-dto';
/**
 * CatalogsApi - axios parameter creator
 * @export
 */
export const CatalogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns information about a catalog based on its ID, along with the versions defined for the current base store.
         * @summary Get a catalog
         * @param {string} catalogId Catalog identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogUsingGET: async (catalogId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('getCatalogUsingGET', 'catalogId', catalogId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCatalogUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/catalogs/{catalogId}`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about the catalog version that exists for the current base store.
         * @summary Get information about catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogVersionUsingGET: async (catalogId: string, catalogVersionId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('getCatalogVersionUsingGET', 'catalogId', catalogId)
            // verify required parameter 'catalogVersionId' is not null or undefined
            assertParamExists('getCatalogVersionUsingGET', 'catalogVersionId', catalogVersionId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCatalogVersionUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/catalogs/{catalogId}/{catalogVersionId}`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)))
                .replace(`{${"catalogVersionId"}}`, encodeURIComponent(String(catalogVersionId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all catalogs with versions defined for the base store.
         * @summary Get a list of catalogs
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogsUsingGET: async (baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCatalogsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/catalogs`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about a specified category that exists in a catalog version available for the current base store.
         * @summary Get information about catagory in a catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} categoryId Category identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesUsingGET: async (catalogId: string, catalogVersionId: string, categoryId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('getCategoriesUsingGET', 'catalogId', catalogId)
            // verify required parameter 'catalogVersionId' is not null or undefined
            assertParamExists('getCategoriesUsingGET', 'catalogVersionId', catalogVersionId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoriesUsingGET', 'categoryId', categoryId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCategoriesUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/catalogs/{catalogId}/{catalogVersionId}/categories/{categoryId}`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)))
                .replace(`{${"catalogVersionId"}}`, encodeURIComponent(String(catalogVersionId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogsApi - functional programming interface
 * @export
 */
export const CatalogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns information about a catalog based on its ID, along with the versions defined for the current base store.
         * @summary Get a catalog
         * @param {string} catalogId Catalog identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogUsingGET(catalogId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogUsingGET(catalogId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about the catalog version that exists for the current base store.
         * @summary Get information about catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogVersionUsingGET(catalogId: string, catalogVersionId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogVersionUsingGET(catalogId, catalogVersionId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all catalogs with versions defined for the base store.
         * @summary Get a list of catalogs
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogsUsingGET(baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about a specified category that exists in a catalog version available for the current base store.
         * @summary Get information about catagory in a catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} categoryId Category identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoriesUsingGET(catalogId: string, catalogVersionId: string, categoryId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryHierarchy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoriesUsingGET(catalogId, catalogVersionId, categoryId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogsApi - factory interface
 * @export
 */
export const CatalogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogsApiFp(configuration)
    return {
        /**
         * Returns information about a catalog based on its ID, along with the versions defined for the current base store.
         * @summary Get a catalog
         * @param {string} catalogId Catalog identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogUsingGET(catalogId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Catalog> {
            return localVarFp.getCatalogUsingGET(catalogId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about the catalog version that exists for the current base store.
         * @summary Get information about catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogVersionUsingGET(catalogId: string, catalogVersionId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CatalogVersion> {
            return localVarFp.getCatalogVersionUsingGET(catalogId, catalogVersionId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all catalogs with versions defined for the base store.
         * @summary Get a list of catalogs
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CatalogList> {
            return localVarFp.getCatalogsUsingGET(baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about a specified category that exists in a catalog version available for the current base store.
         * @summary Get information about catagory in a catalog version
         * @param {string} catalogId Catalog identifier
         * @param {string} catalogVersionId Catalog version identifier
         * @param {string} categoryId Category identifier
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesUsingGET(catalogId: string, catalogVersionId: string, categoryId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<CategoryHierarchy> {
            return localVarFp.getCategoriesUsingGET(catalogId, catalogVersionId, categoryId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogsApi - object-oriented interface
 * @export
 * @class CatalogsApi
 * @extends {BaseAPI}
 */
export class CatalogsApi extends BaseAPI {
    /**
     * Returns information about a catalog based on its ID, along with the versions defined for the current base store.
     * @summary Get a catalog
     * @param {string} catalogId Catalog identifier
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public getCatalogUsingGET(catalogId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).getCatalogUsingGET(catalogId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about the catalog version that exists for the current base store.
     * @summary Get information about catalog version
     * @param {string} catalogId Catalog identifier
     * @param {string} catalogVersionId Catalog version identifier
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public getCatalogVersionUsingGET(catalogId: string, catalogVersionId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).getCatalogVersionUsingGET(catalogId, catalogVersionId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all catalogs with versions defined for the base store.
     * @summary Get a list of catalogs
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public getCatalogsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).getCatalogsUsingGET(baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about a specified category that exists in a catalog version available for the current base store.
     * @summary Get information about catagory in a catalog version
     * @param {string} catalogId Catalog identifier
     * @param {string} catalogVersionId Catalog version identifier
     * @param {string} categoryId Category identifier
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public getCategoriesUsingGET(catalogId: string, catalogVersionId: string, categoryId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).getCategoriesUsingGET(catalogId, catalogVersionId, categoryId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
