import { NextRouter } from 'next/router';
import paths from '@constants/paths';

const getBeamListName = (beamName: string, router: NextRouter) => {
  switch (router.asPath) {
    case paths.HOME:
      return `start_page | ${beamName}`;
    default:
      return beamName;
  }
};

export default getBeamListName;
