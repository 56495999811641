import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import Backdrop from '@atoms/Backdrop/Backdrop';
import KEYS from '@helpers/keys';
import {
  StyledButton,
  StyledCallToAction,
  StyledContent,
  StyledModal,
  StyledModalBackButton,
  StyledModalNavigationWrapper,
} from '@organisms/Modal/Modal.styles';
import ModalCloseButton from '@atoms/ModalCloseButton/ModalCloseButton';
import ArrowLeft from '@icons/arrow-left.svg';
import Icon from '@atoms/Icon/Icon';

export type ModalSize = 'x-small' | 'small' | 'medium' | 'large';
export type InternalNavigationType = {
  currentStep: number;
  totalSteps: number;
};

export interface ModalProps {
  children: ReactNode;
  size: ModalSize;
  hasCloseIcon?: boolean;
  confirmButtonText?: string;
  confirmDisabled?: boolean;
  cancelButtonText?: string;
  closeOnEscape?: boolean;
  onClose?: (event?: MouseEvent) => void;
  onConfirm?: (event?: MouseEvent) => void;
  onCustomEvent?: () => void;
  id?: string;
  noPadding?: boolean;
  buttonWidth?: number;
  mobileButtonWidth?: number;
  overflowHidden?: boolean;
  isProductModal?: boolean;
  verticallyCentered?: boolean;
  isSubmitting?: boolean;
  equalButtonWidth?: boolean;
  internalNavigation?: InternalNavigationType;
  navigationCallback?: (step: number) => void;
  closeIconColor?: 'black' | 'white';
  youTubeModal?: boolean;
  backdropZIndex?: number;
  hasMinHeight?: boolean;
}

const Modal = ({
  children,
  size,
  hasCloseIcon,
  confirmButtonText,
  confirmDisabled = false,
  cancelButtonText,
  closeOnEscape,
  onClose,
  onConfirm,
  id,
  buttonWidth,
  mobileButtonWidth,
  verticallyCentered = false,
  overflowHidden = false,
  noPadding = false,
  isProductModal = false,
  isSubmitting,
  equalButtonWidth,
  onCustomEvent,
  internalNavigation,
  navigationCallback,
  closeIconColor,
  youTubeModal = false,
  backdropZIndex,
  hasMinHeight = false,
}: ModalProps) => {
  const [backdropVisibility, setBackdropVisibility] = useState(true);
  const showCloseIcon = size !== 'small' && hasCloseIcon;

  const closeHandler = (event?: MouseEvent) => {
    if (onClose) {
      setBackdropVisibility(false);
      onClose(event);
    }
  };

  const confirmHandler = (event?: MouseEvent) => {
    if (onConfirm) {
      onConfirm(event);
    }
  };

  const keyPressHandler = (e: KeyboardEvent) => {
    if (closeOnEscape) {
      if (e.key === KEYS.ESCAPE) {
        closeHandler();
      }
    }
  };

  useEffect(() => {
    let modalAutoCloseTimeout: ReturnType<typeof setTimeout>;

    if (size === 'x-small') {
      modalAutoCloseTimeout = setTimeout(() => {
        closeHandler();
      }, 10000);
    }

    window.addEventListener('keyup', keyPressHandler);

    return () => {
      window.removeEventListener('keyup', keyPressHandler);
      clearTimeout(modalAutoCloseTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {backdropVisibility && (
        <Backdrop
          display={backdropVisibility}
          callback={closeHandler}
          transparent={size === 'x-small'}
          preventLockPage={size === 'x-small'}
          zIndex={backdropZIndex}
        >
          <StyledModal
            id={id}
            role="dialog"
            size={size}
            overflowHidden={overflowHidden}
            isProductModal={isProductModal}
            verticallyCentered={verticallyCentered}
            youTubeModal={youTubeModal}
            hasMinHeight={hasMinHeight}
          >
            {(!!internalNavigation || showCloseIcon) && (
              <StyledModalNavigationWrapper>
                {internalNavigation &&
                  internalNavigation.currentStep > 0 &&
                  internalNavigation.currentStep < internalNavigation.totalSteps && (
                    <StyledModalBackButton
                      onClick={() => navigationCallback && navigationCallback(internalNavigation.currentStep - 1)}
                      theme="transparent"
                      data-testid="modal-back-button"
                    >
                      <Icon svg={ArrowLeft} size={16} />
                    </StyledModalBackButton>
                  )}
                {/* if we change icon size we need to adjust negative margin in css */}
                {showCloseIcon && (
                  <ModalCloseButton onClick={closeHandler} color={closeIconColor} size={size === 'x-small' ? 12 : 16} />
                )}
              </StyledModalNavigationWrapper>
            )}
            <StyledContent
              noPadding={noPadding}
              size={size}
              aria-modal="true"
              verticallyCentered={verticallyCentered}
              hasNavigation={!!internalNavigation || !!showCloseIcon}
            >
              {children}

              {(cancelButtonText || confirmButtonText) && (
                <StyledCallToAction equalButtonWidth={equalButtonWidth}>
                  {cancelButtonText && (
                    <StyledButton
                      theme="secondary"
                      onClick={onCustomEvent || closeHandler}
                      title={cancelButtonText}
                      width={buttonWidth}
                      mobileWidth={mobileButtonWidth}
                      data-testid="modal-cancel-button"
                      disabled={isSubmitting}
                    >
                      {cancelButtonText}
                    </StyledButton>
                  )}

                  {confirmButtonText && (
                    <StyledButton
                      theme="primary"
                      onClick={confirmHandler}
                      disabled={confirmDisabled}
                      title={confirmButtonText}
                      width={buttonWidth}
                      mobileWidth={mobileButtonWidth}
                      isSubmitting={isSubmitting}
                      data-testid="modal-confirm-button"
                    >
                      {confirmButtonText}
                    </StyledButton>
                  )}
                </StyledCallToAction>
              )}
            </StyledContent>
          </StyledModal>
        </Backdrop>
      )}
    </>
  );
};

export default Modal;
