import { rem } from 'polished';
import styled from 'styled-components';
import Config from '@config';
import Heading from '@atoms/Heading/Heading';

export const StyledClosedStoreAlert = styled.div`
  margin-top: ${rem(52)};
`;

export const StyledPromotionPageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPromotionPageHeading = styled(Heading)`
  margin-top: ${rem(12)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(40)};
  }
`;
