import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import ProductTableOfInformation from '@molecules/ProductTableOfInformation/ProductTableOfInformation';
import ProductTableOfContents from '@molecules/ProductTableOfContents/ProductTableOfContents';
import ProductTableOfOther from '@molecules/ProductTableOfOther/ProductTableOfOther';
import Accordion from '@molecules/Accordion/Accordion';
import AccordionGroup from '@molecules/Accordion/AccordionGroup';
import { useRouter } from 'next/router';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';

interface Props {
  data: AxfoodProductDetailsViewModel;
}

const ProductDetailsAccordion = ({ data }: Props) => {
  const { query } = useRouter();
  const queryTab = typeof query.tab === 'string' ? query.tab : undefined;
  const { t } = useTranslation('product');
  const [currentOpenedAccordion, setCurrentOpenedAccordion] = useState<string | null>(data.isDrugProduct ? '1' : null);

  const accordionClickHandler = (accordionKey: string) => {
    setCurrentOpenedAccordion(accordionKey === currentOpenedAccordion ? null : accordionKey);
  };
  const renderAccordionGroupTwo = (data: AxfoodProductDetailsViewModel) =>
    data.animalData || data.ingredients ? (
      <Accordion enableScroll={queryTab === '2'} accordionKey="2" title={t('product->tabs->table_of_contents')} key={2}>
        <ProductTableOfContents contents={data.ingredients} animalData={data.animalData} />
      </Accordion>
    ) : null;

  useEffect(() => {
    if (data.isDrugProduct) {
      setCurrentOpenedAccordion('1');
    } else if (queryTab && ['1', '2', '3'].includes(queryTab)) {
      setCurrentOpenedAccordion(queryTab);
    } else {
      setCurrentOpenedAccordion(null);
    }
  }, [data, queryTab]);

  return (
    <AccordionGroup currentOpened={currentOpenedAccordion} customClickHandler={accordionClickHandler}>
      <Accordion
        enableScroll={queryTab === '1'}
        accordionKey="1"
        title={t('product->tabs->product_information')}
        key={1}
      >
        <ProductTableOfInformation data={data} />
      </Accordion>
      {renderAccordionGroupTwo(data)}
      <Accordion enableScroll={queryTab === '3'} accordionKey="3" title={t('product->tabs->other')} key={3}>
        <ProductTableOfOther data={data} />
      </Accordion>
    </AccordionGroup>
  );
};

export default ProductDetailsAccordion;
