import Icon from '@atoms/Icon/Icon';
import InputField from '@atoms/InputField/InputField';
import Autocomplete from '@components/molecules/Autocomplete/Autocomplete';
import Config from '@config';
import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const StyledSearch = styled.div`
  position: relative;
  border-radius: 0 ${rem(22)};
  background-color: ${variables.colors.white};
  width: calc(100vw - ${rem(40)});

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(420)};
  }
`;

export const StyledSearchIcon = styled(Icon)`
  position: absolute;
  left: ${rem(14)};
  top: 50%;
  transform: translateY(-50%);
  z-index: ${variables.zIndex[1]};
`;

export const StyledSearchField = styled(InputField)`
  padding-left: ${rem(40)} !important;
  background-color: transparent;
`;

export const StyledAutoComplete = styled(Autocomplete)<{ errorMessage: string }>`
  position: absolute;
  overflow-y: auto;
  scrollbar-width: none;
  max-height: calc(100vh - ${rem(60)});
  margin-top: ${rem(20)};

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.errorMessage &&
    css`
      top: ${rem(-17)};
    `}
`;

export const StyledAutoCompleteContainer = styled.div<{ takeover: boolean }>`
  position: relative;
  z-index: ${variables.zIndex[1] + 1};
  width: 100%;

  ${StyledAutoComplete} {
    padding: 0;
    box-shadow: none;
  }

  ${(props) =>
    props.takeover &&
    css`
      z-index: 81;
      position: fixed;
      left: 0;
      height: calc(100vh - ${rem(60)});
      max-height: calc(100vh - ${rem(60)});
      top: ${rem(60)};
      background: ${variables.colors.white};

      ${StyledAutoComplete} {
        padding: 0 ${rem(20)};
      }
    `}
`;

export const StyledEmptySlot = styled.div`
  text-align: center;
  color: ${variables.colors.darkerGray};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
`;
export const StyledEmptySlotHead = styled.div`
  padding: ${rem(20)};
  margin: ${rem(20)};
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.black};
  background: ${variables.colors.highlightBg};
`;
export const StyledEmptySlotBody = styled.div`
  margin: ${rem(13)};
`;
