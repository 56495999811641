import axios, { AxiosResponse, CancelToken } from 'axios';
import { AxfoodCartViewModel } from '@api/generated/storefront';

const setSlotInCart = (
  slot: string,
  isTmsSlot: boolean,
  tmsDeliveryWindowReference: any, // TODO fix after Organism SlotSelector.jsx is fixed
  cancelToken: CancelToken
): Promise<AxiosResponse<AxfoodCartViewModel>> => {
  const API_URL = `/axfood/rest/slot/slotInCart/${slot}`;
  const data = tmsDeliveryWindowReference || {};

  return axios.post(API_URL, data, {
    params: { isTmsSlot },
    cancelToken,
  });
};
export default setSlotInCart;
