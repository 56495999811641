import { useRef, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { format, isSameYear, isThisYear } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import sv from 'date-fns/locale/sv';
import Icon from '@atoms/Icon/Icon';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';
import {
  StyledButton,
  StyledContainer,
  StyledDateRangeChooseButton,
  StyledInnerContainer,
  StyledOuterContainer,
  StyledSelectedDateText,
  StyledSelectedDateTextItem,
  StyledSelectedDateWrapper,
} from './DateRangePicker.styles';

import '../../../node_modules/react-day-picker/src/style.css';
import Text from '@atoms/Text/Text';
import useResponsive from '@hooks/useResponsive';
import Button from '@atoms/Button/Button';
import useFreezeBodyScroll from '@hooks/useFreezeBodyScroll';
import AccordionDownSmall from '@icons/accordion-down_small.svg';

const dateRangeToString = (range: DateRange | undefined, isMobile: boolean) => {
  if (range?.from) {
    let { from, to } = range;
    if (!to) to = from;

    const sameYear = isSameYear(from, to) && isThisYear(from);
    const dateFormat = sameYear && !isMobile ? 'd MMM' : 'd MMM yyyy';
    const formattedFrom = format(from, dateFormat, { locale: sv });
    const formattedTo = format(to, dateFormat, { locale: sv });
    return `${formattedFrom.replace('.', '')} - ${formattedTo.replace('.', '')}`;
  }

  return '';
};

const getFormattedDate = (date: Date) => {
  let dateStr = '';
  if (date) {
    const dateFormat = 'd MMM yyyy';
    dateStr = format(date, dateFormat, { locale: sv });
  }

  return dateStr;
};

export type DateRangePickerProps = {
  onChange: (range: DateRange) => void;
  defaultRange: Required<DateRange>;
  align?: 'left' | 'right';
  testId?: string;
  fromDate?: Date;
  toDate?: Date;
  className?: string;
};

const DateRangePicker = ({
  onChange,
  defaultRange,
  align = 'right',
  testId,
  fromDate,
  toDate,
  className,
}: DateRangePickerProps) => {
  const { t } = useTranslation('order');
  const btnRef = useRef<HTMLDivElement | null>(null);
  const [range, setRange] = useState<DateRange | undefined>(defaultRange);
  const { isMobile } = useResponsive();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  useFreezeBodyScroll(isMobile && showDateRangePicker);

  const onClickOutside = (e: MouseEvent) => {
    // Handle conflict if user clicks the button to open date picker
    if (btnRef?.current && !btnRef.current.contains(e.target as Node)) {
      setShowDateRangePicker(false);
    }
  };

  const onButtonClick = () => {
    if (range) {
      onChange(range);
    }
    setShowDateRangePicker(false);
  };

  const footer = (
    <StyledSelectedDateWrapper>
      <StyledSelectedDateText>
        <StyledSelectedDateTextItem>
          <Text type="body" size="small">
            {t('common:from')}
          </Text>
          <Text type="subtitle">{getFormattedDate(range?.from as Date)}</Text>
        </StyledSelectedDateTextItem>
        <StyledSelectedDateTextItem>
          <Text type="body" size="small">
            {t('common:to')}
          </Text>
          <Text type="subtitle">{getFormattedDate(range?.to as Date)}</Text>
        </StyledSelectedDateTextItem>
      </StyledSelectedDateText>
      <StyledDateRangeChooseButton theme="primary" onClick={onButtonClick}>
        {t('common:button->choose')}
      </StyledDateRangeChooseButton>
    </StyledSelectedDateWrapper>
  );

  return (
    <StyledOuterContainer data-testid={testId} className={className}>
      <div ref={btnRef}>
        <StyledButton
          theme="secondary"
          data-testid="date-range-picker-toggle"
          onClick={() => {
            setShowDateRangePicker(!showDateRangePicker);
          }}
        >
          <Text type="subtitle" size="medium">
            {dateRangeToString(range, isMobile)}
          </Text>
          <Icon svg={AccordionDownSmall} size={12} />
        </StyledButton>
      </div>
      {showDateRangePicker && (
        <StyledContainer align={align} data-testid="date-range-picker-container">
          <OutsideClickWatcher clickHandler={onClickOutside}>
            <StyledInnerContainer>
              <DayPicker
                mode="range"
                showOutsideDays
                numberOfMonths={2}
                defaultMonth={range?.from || defaultRange.from}
                fromDate={fromDate}
                toDate={toDate}
                locale={sv}
                selected={range}
                onSelect={(newRange: DateRange | undefined) => {
                  if (newRange) {
                    setRange(newRange);
                  } else {
                    setRange(defaultRange);
                  }
                }}
              />
              {isMobile ? (
                footer
              ) : (
                <Button theme="primary" onClick={onButtonClick}>
                  {t('common:button->choose')}
                </Button>
              )}
            </StyledInnerContainer>
          </OutsideClickWatcher>
        </StyledContainer>
      )}
    </StyledOuterContainer>
  );
};

export default DateRangePicker;
