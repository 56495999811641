import type { AxfoodExternalVoucher } from '@api/generated/storefront';

export enum voucherTypes {
  OMNI = 'omni',
  ONLINE = 'online',
  SELFCHECKOUT = 'selfcheckout',
  SELFSCAN = 'selfscan',
}

const getDisplayVoucher = (
  vouchers: AxfoodExternalVoucher[],
  setVoucherType: (type: any) => void,
  setDisplayVoucher: (voucher: AxfoodExternalVoucher) => void
) => {
  const filteredVoucher = vouchers
    .filter((a) => !!a)
    .reduce((a, b) => {
      return new Date(a?.expirationDate as string) > new Date(b?.expirationDate as string) ? a : b;
    });
  const { voucherChannelData } = filteredVoucher;
  const localType = voucherChannelData?.omni
    ? voucherTypes.OMNI
    : voucherChannelData?.online
    ? voucherTypes.ONLINE
    : voucherChannelData?.selfcheckout
    ? voucherTypes.SELFCHECKOUT
    : voucherTypes.SELFSCAN;
  setVoucherType(localType);
  setDisplayVoucher(filteredVoucher);
};

export default getDisplayVoucher;
