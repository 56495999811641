import axios, { AxiosResponse, CancelToken } from 'axios';
import { AxfoodPaginationViewModelOfAxfoodBasicProductViewModel } from '@api/generated/storefront';

interface GetPromotionProductsParams {
  promotion: string;
  excludeProducts: string;
  page: number;
  pageSize: number;
}

const getPromotionProducts = (
  data: GetPromotionProductsParams,
  cancelToken: CancelToken
): Promise<AxiosResponse<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel>> => {
  const { promotion, excludeProducts, page, pageSize } = data;

  const API_URL = `/axfood/rest/promotions/${promotion}/products`;

  return axios.get(API_URL, {
    params: {
      excludeProducts,
      page,
      pageSize,
    },
    cancelToken,
  });
};

export default getPromotionProducts;
