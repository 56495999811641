import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import { SkeletonRow } from '@atoms/Skeleton/Skeleton.styles';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(2)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
  }

  ${SkeletonRow} {
    margin: ${rem(2)} 0 0 0;
  }
`;
