import { StyledKlarnaContainer } from '@molecules/CheckoutPaymentOption/CheckoutPaymentOption.styles';
import useCart from '@hooks/useCart';

const KlarnaOption = () => {
  const { cart } = useCart();

  return <StyledKlarnaContainer hideBorder={!!cart?.orderReference} data-testid="klarna-pay-option" />;
};

export default KlarnaOption;
