import { StoreApi } from '@api/generated/storefront/storefront/store-api';
import type { AxiosRequestConfig } from 'axios';

const storeApi = new StoreApi(undefined, '');

export const getAvailableStores = async (
  online = false,
  clickAndCollect?: boolean,
  b2bClickAndCollect?: boolean,
  options?: AxiosRequestConfig
) => {
  const response = await storeApi.getStoresUsingGET(online, clickAndCollect, b2bClickAndCollect, options);
  return response.data;
};

export const getActiveStore = async (options?: AxiosRequestConfig) => {
  const response = await storeApi.getCurrentBaseStoreUsingGET(options);
  return response.data;
};

export const activateStore = (
  storeId: string,
  activelySelected: boolean,
  forceAsPickingStore?: boolean,
  options?: AxiosRequestConfig
) => storeApi.activateStoreInSessionUsingPOST(storeId, activelySelected, forceAsPickingStore, options);

export const getSuggestedPickupStore = (defaultToBaseStore?: boolean, options?: AxiosRequestConfig) =>
  storeApi.getSuggestedPickUpStoreUsingGET(defaultToBaseStore, options);

export const checkIfStoreIsNotPermanentlyClosed = (storeId: string, options?: AxiosRequestConfig) =>
  storeApi.checkIfStoreIsNotPermanentlyClosedUsingGET(storeId, options);

export const searchStores = async (
  q: string,
  sort: string,
  size?: number,
  externalPickupLocation?: boolean,
  searchHasClickAndCollect?: boolean
) => {
  const result = await storeApi.getStoreUsingGET(
    q,
    sort,
    undefined,
    size,
    undefined,
    undefined,
    searchHasClickAndCollect,
    externalPickupLocation
  );
  return result.data;
};

export const getStoreInformation = async (storeId: string) => {
  const result = await storeApi.getStoreInformationUsingGET1(storeId);
  return result.data;
};

export const getStoreInformationSSR = async (storeId: string, options: AxiosRequestConfig, api: StoreApi) => {
  const result = await api.getStoreInformationUsingGET1(storeId, options);
  return result.data;
};
