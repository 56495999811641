import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface MenuState {
  isOpen: boolean;
}

const initialState: MenuState = {
  isOpen: false,
};

export const menuSlice = createAppSlice({
  name: 'menu',
  initialState,
  reducers: (create) => ({
    toggleMenu: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }),
  }),
  selectors: {
    selectMenu: (menu) => menu,
    selectSideNavMenuIsOpen: (menu) => menu.isOpen,
  },
});

// Actions
export const { toggleMenu } = menuSlice.actions;

// Selectors
export const { selectSideNavMenuIsOpen } = menuSlice.selectors;
