import Config from '@config';
import Icon from '@atoms/Icon/Icon';
import Text from '@atoms/Text/Text';
import webToAppApi from '@api/web-to-app';
import React, { Key, useCallback, useState } from 'react';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import { useAppDispatch } from '@hooks/useAppDispatch';
import SlideInSection from '@molecules/SlideInSection/SlideInSection';
import StoreSearch from '@molecules/StoreSearch/StoreSearch';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { setPromotionPageStoreId } from '@slices/promotionPageSlice';
import { getStoreInformation } from '@api/interfaces/storeApi';
import { AxfoodStoreInfoViewModel } from '@api/generated/storefront';
import Link from '@atoms/Link/Link';
import useStoreAndLocaleState from '@molecules/PromotionTabs/useStoreAndLocaleState';
import {
  StyledButton,
  StyledOfflineTextWrapper,
  StyledPromotionTabsWrapper,
  StyledStoreSearchContainer,
  StyledStoreSearchText,
  StyledStoreSearchTitle,
  StyledTab,
} from './PromotionTabs.styles';
import useUserAgent from '@hooks/useUserAgent';
import useSnackBar from '@hooks/useSnackbar';
import Store from '@icons/store.svg';
import Cart from '@icons/cart.svg';
import DeliveryBox from '@molecules/DeliveryBox/DeliveryBox';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';

interface Props {
  activeTab?: string | null;
  ecommerceSelected: boolean;
  setPage: (page: number) => void;
  openDeliveryPicker: () => void;
  setSelectedOfflineStore: (s: AxfoodStoreInfoViewModel) => void;
  selectedOfflineStore: AxfoodStoreInfoViewModel;
}

const PromotionTabs = ({
  activeTab,
  ecommerceSelected,
  setPage,
  openDeliveryPicker,
  setSelectedOfflineStore,
  selectedOfflineStore,
}: Props) => {
  const router = useCustomRouter();
  const { t } = useTranslation('promotionPage');
  const { store } = useStore();
  const [isSlideInOpen, setIsSlideInOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { cart } = useCart();
  const DELIVERY_COPY = { [Config.HOME_DELIVERY]: t('deliverTo'), [Config.PICKUP_IN_STORE]: t('pickUpFrom') };
  const deliveryMode = cart?.deliveryModeCode || '';
  const deliveryCopy = DELIVERY_COPY[deliveryMode];
  const { isNativeApp } = useUserAgent();
  const setSnack = useSnackBar();
  const showDeliveryInformation =
    store?.activelySelected &&
    ((deliveryMode === Config.PICKUP_IN_STORE && store.name) ||
      (deliveryMode === Config.HOME_DELIVERY && cart?.deliveryAddress?.formattedAddress));

  const onTabChange = useCallback(
    (key: Key) => {
      if (key !== activeTab) {
        setPage(0);
        webToAppApi.eventOffersSwitchedTab(key as string);
        router.push(
          // @ts-ignore
          { pathname: `/erbjudanden/[mode]`, query: { mode: key } },
          { pathname: `/erbjudanden/${key}` },
          { shallow: true }
        );
      }
    },
    [activeTab]
  );

  const getStoreAndSetLocalState = async (id: string) => {
    const store = await getStoreInformation(id);
    if (!store.external) {
      setSelectedOfflineStore(store);
    }
  };

  useStoreAndLocaleState(getStoreAndSetLocalState);

  const searchResultClickHandler = async (id: string) => {
    try {
      await getStoreAndSetLocalState(id);
      pushGTMEvent({ event: 'Track', category: 'offers_page_store', action: 'store_confirmed', label: id, value: 0 });
      webToAppApi.eventOffersSwitchedStore(id);
      dispatch(setPromotionPageStoreId(id));
      setIsSlideInOpen(false);
      setSnack({ text: t('snackbar->storeChosen'), icon: 'valid' });
    } catch (error) {
      setSnack({ text: t('snackbar->storeSelectionFailed'), icon: null });
    }
  };

  return (
    <>
      {isSlideInOpen && (
        <SlideInSection onClose={() => setIsSlideInOpen(false)} closeOnEscape={true} hasCloseIcon>
          <StyledStoreSearchContainer>
            <StyledStoreSearchTitle variant="h3">{t('chooseStoreAction')}</StyledStoreSearchTitle>
            <StyledStoreSearchText type="body" size="responsive">
              {t('chooseStoreForCorrectAssortment')}
            </StyledStoreSearchText>
            <StoreSearch onResultClick={searchResultClickHandler} onCloseHandler={() => setIsSlideInOpen(false)} />
          </StyledStoreSearchContainer>
        </SlideInSection>
      )}
      <StyledPromotionTabsWrapper>
        <StyledTab
          data-testid="promotiontab-store"
          onClick={() => onTabChange(Config.ROUTES.promotionpage.store)}
          tabIndex={0}
        >
          <DeliveryBox active={activeTab === Config.ROUTES.promotionpage.store} icon={<Icon size={24} svg={Store} />}>
            <>
              <Text type="subhead">{t('promotionPage:store')}</Text>
              {activeTab === Config.ROUTES.promotionpage.store && (
                <>
                  <Text type="body">
                    {selectedOfflineStore.storeId ? selectedOfflineStore.name : t('chooseStoreInfo')}
                  </Text>

                  <StyledOfflineTextWrapper type="body">
                    <StyledButton theme="link" onClick={() => setIsSlideInOpen(true)}>
                      {selectedOfflineStore.storeId ? t('changeStore') : t('chooseStoreAction')}
                    </StyledButton>
                    {selectedOfflineStore.storeId && selectedOfflineStore?.flyerURL && ' | '}
                    {selectedOfflineStore.storeId && selectedOfflineStore?.flyerURL && (
                      <Link
                        href={selectedOfflineStore.flyerURL}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          webToAppApi.eventStoreFlyerOpened(selectedOfflineStore.storeId, selectedOfflineStore.flyerURL)
                        }
                      >
                        {t('storeFlyer')}
                      </Link>
                    )}
                  </StyledOfflineTextWrapper>
                </>
              )}
            </>
          </DeliveryBox>
        </StyledTab>
        <StyledTab
          data-testid="promotiontab-ecommerce"
          onClick={() => onTabChange(Config.ROUTES.promotionpage.ecommerce)}
          tabIndex={0}
        >
          <DeliveryBox icon={<Icon size={24} svg={Cart} />} active={activeTab === Config.ROUTES.promotionpage.ecommerce}>
            <>
              <Text type="subhead">{t('promotionPage:ecommerce')}</Text>
              {activeTab === Config.ROUTES.promotionpage.ecommerce && !isNativeApp && (
                <>
                  {ecommerceSelected && showDeliveryInformation ? (
                    <Text type="body" data-testid="addressChanger-line1">
                      {`${deliveryCopy} `}
                      <Text type="body" element="span">
                        {deliveryMode === Config.PICKUP_IN_STORE ? (
                          store.name
                        ) : (
                          <span data-hj-suppress="true">{cart?.deliveryAddress?.formattedAddress}</span>
                        )}
                      </Text>
                    </Text>
                  ) : (
                    <Text type="body">{t('chooseDeliveryInfo')}</Text>
                  )}
                  <StyledButton theme="link" onClick={openDeliveryPicker}>
                    {ecommerceSelected && showDeliveryInformation ? t('changeStore') : t('chooseDeliveryAction')}
                  </StyledButton>
                </>
              )}
            </>
          </DeliveryBox>
        </StyledTab>
      </StyledPromotionTabsWrapper>
    </>
  );
};

export default PromotionTabs;
