import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledDeliveryBox = styled.div<{ active: boolean }>`
  display: flex;
  padding: ${rem(20)};
  width: 100%;
  gap: ${rem(20)};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(4)};
  cursor: pointer;
  align-items: center;

  &:hover {
    border: ${rem(2)} solid ${variables.colors.highlight};
    margin: ${rem(-1)};
  }

  ${(props) =>
    props.active &&
    css`
      border: ${rem(2)} solid ${variables.colors.highlight};
      margin: ${rem(-1)};
    `}
`;
export const StyledDeliveryBoxLeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledDeliveryBoxRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(2)};
`;
