import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { StyledAnimationWrapper, StyledCloseButton, StyledSection, StyledSectionWrapper } from './SlideInSection.styles';
import Backdrop from '@atoms/Backdrop/Backdrop';
import KEYS from '@helpers/keys';
import Close from '@icons/close_small.svg';

interface Props {
  children?: ReactNode;
  onClose?: (event?: MouseEvent) => void;
  closeOnEscape?: boolean;
  backdropZIndex?: number;
  hasCloseIcon?: boolean;
}

const SlideInSection = ({ children, onClose, closeOnEscape, backdropZIndex, hasCloseIcon }: Props) => {
  const [backdropVisibility, setBackdropVisibility] = useState(true);
  const closeHandler = (event?: MouseEvent) => {
    if (onClose) {
      setBackdropVisibility(false);
      onClose(event);
    }
  };

  const keyPressHandler = (e: KeyboardEvent) => {
    if (closeOnEscape) {
      if (e.key === KEYS.ESCAPE) {
        closeHandler();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyPressHandler);

    return () => {
      window.removeEventListener('keyup', keyPressHandler);
    };
  }, []);

  return (
    <>
      {backdropVisibility && (
        <Backdrop display={backdropVisibility} callback={closeHandler} zIndex={backdropZIndex}>
          <StyledSectionWrapper>
            <StyledAnimationWrapper show={backdropVisibility}>
              <StyledSection>
                {hasCloseIcon && <StyledCloseButton svg={Close} onClick={closeHandler} size={16} />}
                {children}
              </StyledSection>
            </StyledAnimationWrapper>
          </StyledSectionWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default SlideInSection;
