import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';

import { StyledAnimalInfo } from './ProductTableOfContents.styles';
import { AnimalData } from '@api/generated/storefront';

interface Props {
  contents: string | undefined;
  animalData: AnimalData | undefined;
}

const ProductTableOfContents = ({ contents, animalData }: Props) => {
  const { t } = useTranslation('product');

  return (
    <>
      {!animalData && contents && (
        <>
          <Heading variant="h3" label>
            {t('product->contents')}
          </Heading>
          <Text type="body">{contents}</Text>
        </>
      )}
      {animalData && (
        <>
          {animalData?.compositionStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->compositionStatement')}
              </Heading>
              <Text type="body">{animalData.compositionStatement}</Text>
            </StyledAnimalInfo>
          )}
          {animalData?.constituentStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->constituentStatement')}
              </Heading>
              <Text type="body">{animalData.constituentStatement}</Text>
            </StyledAnimalInfo>
          )}
          {animalData?.additiveStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->additiveStatement')}
              </Heading>
              <Text type="body">{animalData.additiveStatement}</Text>
            </StyledAnimalInfo>
          )}
        </>
      )}
    </>
  );
};

export default ProductTableOfContents;
