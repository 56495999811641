/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrderStatusUpdateElementList } from '../cms-dto';
/**
 * FeedsApi - axios parameter creator
 * @export
 */
export const FeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the orders that have changed status. Returns only the elements from the current baseSite that have been updated after the provided timestamp.
         * @summary Get a list of orders with status updates
         * @param {string} timestamp Only items newer than the given parameter are retrieved. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStatusFeedUsingGET: async (timestamp: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('orderStatusFeedUsingGET', 'timestamp', timestamp)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('orderStatusFeedUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/feeds/orders/statusfeed`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedsApi - functional programming interface
 * @export
 */
export const FeedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the orders that have changed status. Returns only the elements from the current baseSite that have been updated after the provided timestamp.
         * @summary Get a list of orders with status updates
         * @param {string} timestamp Only items newer than the given parameter are retrieved. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStatusFeedUsingGET(timestamp: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatusUpdateElementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStatusFeedUsingGET(timestamp, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedsApi - factory interface
 * @export
 */
export const FeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedsApiFp(configuration)
    return {
        /**
         * Returns the orders that have changed status. Returns only the elements from the current baseSite that have been updated after the provided timestamp.
         * @summary Get a list of orders with status updates
         * @param {string} timestamp Only items newer than the given parameter are retrieved. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStatusFeedUsingGET(timestamp: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<OrderStatusUpdateElementList> {
            return localVarFp.orderStatusFeedUsingGET(timestamp, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedsApi - object-oriented interface
 * @export
 * @class FeedsApi
 * @extends {BaseAPI}
 */
export class FeedsApi extends BaseAPI {
    /**
     * Returns the orders that have changed status. Returns only the elements from the current baseSite that have been updated after the provided timestamp.
     * @summary Get a list of orders with status updates
     * @param {string} timestamp Only items newer than the given parameter are retrieved. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public orderStatusFeedUsingGET(timestamp: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).orderStatusFeedUsingGET(timestamp, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
