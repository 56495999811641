import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackLoadMore = (storeType: onlineOfflineType) => {
  pushGTMEvent({
    event: 'Track',
    category: storeType === 'offline' ? 'offers_page_store' : 'offers_page_ecommerce',
    action: 'show_all_products',
    label: '',
    value: 0,
  });
};
