import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Icon from '@atoms/Icon/Icon';
import Config from '@config';
import Spinner from '@molecules/Spinner/Spinner';

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  margin-top: ${rem(24)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(28)};
  }
`;

export const StyledPipe = styled.span`
  color: ${variables.colors.mediumGray};
  margin: auto ${rem(12)};
`;

export const StyledPaymentModesBody = styled.div<{ disabled?: boolean; noCards?: boolean }>`
  margin-top: ${rem(28)};
  padding: ${rem(28)} 0 0;
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  margin-left: ${rem(48)};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  ${(props) =>
    props.noCards &&
    css`
      padding: 0;
    `}
`;

export const StyledPaymentModeOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(24)} 0;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(28)} 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const StyledPaymentModeOptionLabel = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`;

export const StyledIcon = styled(Icon)`
  margin: 0 ${rem(12)} 0 ${rem(6)};
`;

export const StyledKlarnaContainer = styled.div<{ hideBorder: boolean }>`
  width: 100%;
  margin-top: ${rem(28)};
  margin-bottom: ${rem(28)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};

  ${(props) =>
    props.hideBorder &&
    css`
      border: 0;
    `}
`;

export const StyledSpinner = styled(Spinner)`
  margin: ${rem(40)} auto;
`;
