import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useDidUpdateEffect = (callback: EffectCallback, deps: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return callback();
    }
    didMountRef.current = true;
  }, deps);
};

export default useDidUpdateEffect;
