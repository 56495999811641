import Button from '@atoms/Button/Button';
import Config from '@config';
import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const StyledOuterContainer = styled.div`
  position: relative;
`;

export const StyledContainer = styled.div<{
  align: 'left' | 'right';
}>`
  position: fixed;
  width: 100%;
  height: var(--doc-height);
  top: 0;
  left: 0;
  z-index: 90;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    position: absolute;
    z-index: 3;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    height: auto;

    .rdp-months {
      gap: ${rem(41)};
    }

    .rdp {
      --rdp-cell-size: ${rem(32.218)};
    }
  }

  @media screen and (min-width: 415px) {
    left: 0;
    transform: none;
  }

  ${(props) =>
    props.align === 'right' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        left: auto;
        right: 0;
      }
    `}
  ${(props) =>
    props.align === 'left' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        left: 0;
        right: auto;
      }
    `}
  .rdp {
    margin: 0;

    --rdp-cell-size: ${rem(50)};
    --rdp-accent-color: rgba(0, 129, 199, 0.1);
    --rdp-background-color: transparent;
    --rdp-outline: none;
    --rdp-outline-selected: none;

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      --rdp-cell-size: ${rem(33)};
    }
  }

  th {
    font-family: ${variables.fonts.fontGothamMedium};
    text-transform: none;
    font-size: ${rem(13)};
  }

  .rdp-day_selected {
    color: ${variables.colors.black};
  }

  .rdp-day_range_start,
  .rdp-day_range_end {
    font-family: ${variables.fonts.fontGothamMedium};
    color: ${variables.colors.white} !important;
    background-color: ${variables.colors.highlightHover};
  }

  .rdp-day_selected:hover:not([disabled]) {
    color: ${variables.colors.highlight};
  }

  .rdp-day_range_start:not([disabled]),
  .rdp-day_range_start:focus:not([disabled]),
  .rdp-day_range_start:active:not([disabled]),
  .rdp-day_range_start:hover:not([disabled]),
  .rdp-day_range_end:not([disabled]),
  .rdp-day_range_end:focus:not([disabled]),
  .rdp-day_range_end:active:not([disabled]),
  .rdp-day_range_end:hover:not([disabled]) {
    color: ${variables.colors.highlight};
  }

  .rdp-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      flex-direction: row;
      flex-wrap: initial;
    }
  }

  .rdp-month {
    margin: 0 0 ${rem(24)};
  }

  .rdp-day_outside {
    color: ${variables.colors.darkGray};
  }

  .rdp-caption_label {
    font-size: ${rem(15)};
    font-family: ${variables.fonts.fontGothamMedium};
  }

  .rdp-caption_label {
    text-transform: capitalize;
  }

  .rdp-multiple_months {
    ${Config.MEDIA.IS_MOBILE} {
      max-height: calc(var(--doc-height) - ${rem(180)});
      overflow-y: auto;
    }
  }

  .rdp-multiple_months .rdp-caption {
    margin-bottom: ${rem(6)};
  }

  .rdp-row {
    border-bottom: ${rem(3)} solid white;
  }
`;

export const StyledInnerContainer = styled.div`
  padding: ${rem(28)} ${rem(20)} ${rem(32)};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${rem(13)};
  border-radius: ${rem(2)};
  background: ${variables.colors.white};
  box-shadow: ${rem(2)} ${rem(5)} ${rem(15)} 0 rgba(0, 0, 0, 0.1);
  border: solid ${rem(1)} ${variables.colors.mediumGray};
  height: var(--doc-height);

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(20)};
    height: auto;
  }
`;

export const StyledButton = styled(Button)`
  justify-content: space-between;
  gap: ${rem(16)};
  font-family: ${variables.fonts.fontGothamBook};
  background: ${variables.colors.white};
  border-radius: ${rem(26)};
  border: ${rem(1)} solid ${variables.colors.darkGray};
  width: 100%;

  &:hover {
    background-color: ${variables.colors.white} !important;
  }
`;

export const StyledSelectedDateWrapper = styled.div`
  position: fixed;
  width: 100%;
  background: ${variables.colors.white};
  bottom: 0;
  left: 0;
  padding: ${rem(20)};
  box-shadow: 0 ${rem(-4)} ${rem(30)} 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  z-index: 1;
`;

export const StyledSelectedDateText = styled.div`
  display: flex;
  gap: ${rem(25)};
`;

export const StyledSelectedDateTextItem = styled.div`
  min-width: 0;
  flex: 1;

  &:first-child {
    border-right: ${rem(1)} solid ${variables.colors.mediumGray};
  }
`;

export const StyledDateRangeChooseButton = styled(Button)`
  min-width: 100%;
`;
