import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { useMediaQuery } from 'react-responsive';
import { searchStores } from '@api/interfaces/storeApi';
import paths from '@constants/paths';
import Config from '@config';
import KEYS from '@helpers/keys';
import debounce from '@helpers/debounce';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import useFreezeBodyScroll from '@hooks/useFreezeBodyScroll';
import Text from '@atoms/Text/Text';
import Link from '@atoms/Link/Link';
import PickupLocationListItem from '@molecules/Autocomplete/ListItems/PickupLocationListItem';
import {
  StyledAutoComplete,
  StyledAutoCompleteContainer,
  StyledEmptySlot,
  StyledEmptySlotBody,
  StyledEmptySlotHead,
  StyledSearch,
  StyledSearchField,
  StyledSearchIcon,
} from './StoreSearch.styles';
import { FacetSearchPageData, SortData } from '@api/generated/storefront';
import Search from '@icons/search.svg';

interface Props {
  onResultClick: (res: string) => void;
  onCloseHandler?: () => void;
}

const getLabel = (results: Array<SortData> | undefined) =>
  results?.length === undefined ? '0_results' : results.length > 1 ? 'multiple_results' : '1_results';

const StoreSearch = ({ onResultClick, onCloseHandler }: Props) => {
  const MIN_SEARCH_LENGTH = 2;
  const [storeValue, setStoreValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState<FacetSearchPageData[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [mayShowEmpty, setMayShowEmpty] = useState(false);
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  const getStoreSuggestions = (searchString: string) => {
    searchStores(searchString.toLowerCase(), 'score-desc', undefined, false)
      .then((res) => {
        const label = getLabel(res?.results);
        pushGTMEvent({
          event: 'Track',
          category: 'offers_page_store',
          action: 'store_location_searched',
          label,
          value: 0,
        });
        setSearchSuggestions(res?.results ?? []);
        setShowSuggestions(true);
        setMayShowEmpty(true);
      })
      .catch((e) => {
        console.error('Could not fetch stores.', e);
        setMayShowEmpty(true);
      });
  };

  // TODO fix not "passing parameter" to getStoreSuggestions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetPickupLocationSuggestions = useCallback(
    debounce(getStoreSuggestions, Config.TIMEOUT.addressSearchGetSuggestionsMs),
    []
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setMayShowEmpty(false);

    const { value } = e.currentTarget;
    let shouldFetchSuggestions = false;

    if (value.length >= MIN_SEARCH_LENGTH) {
      shouldFetchSuggestions = true;
    } else {
      setSearchSuggestions([]);
    }

    setStoreValue(value);
    if (shouldFetchSuggestions) {
      debouncedGetPickupLocationSuggestions(value);
    } else {
      debouncedGetPickupLocationSuggestions.cancel();
    }
  };

  const onClearHandler = () => {
    setStoreValue('');
    setSearchSuggestions([]);
  };

  const onBlurHandler = () => {
    setShowSuggestions(false);
    if (onCloseHandler && isMobile) onCloseHandler();
  };

  const onKeyDownInTakeover = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ESCAPE) {
      onBlurHandler();
    }
  };

  const onListItemClick = (name: string, storeId: string) => {
    setStoreValue(name);
    onResultClick(storeId);
    onBlurHandler();
  };

  const emptyAutocompleteContent =
    mayShowEmpty && showSuggestions && storeValue.length >= MIN_SEARCH_LENGTH ? (
      <StyledEmptySlot>
        <StyledEmptySlotHead>
          <Trans i18nKey="storeSearch:autoComplete->emptySlot->head" values={{ search: storeValue }} />
        </StyledEmptySlotHead>
        <StyledEmptySlotBody>
          <Text>
            <Trans i18nKey="storeSearch:autoComplete->emptySlot->text" />
            <Trans i18nKey="storeSearch:autoComplete->emptySlot->notfound" />
          </Text>
          <div>
            <Link href={paths.ALL_STORES}>
              <Trans i18nKey="storeSearch:autoComplete->emptySlot->link" />
            </Link>
          </div>
        </StyledEmptySlotBody>
      </StyledEmptySlot>
    ) : (
      ''
    );

  useFreezeBodyScroll(false);

  const autocompleteFragment = (
    <StyledAutoComplete
      errorMessage={errorMessage}
      searchTerm={storeValue}
      suggestions={searchSuggestions}
      ListComponent={PickupLocationListItem}
      listComponentOnClick={onListItemClick}
      emptySlot={emptyAutocompleteContent}
      showAsCards
    />
  );

  const SearchFieldWithTranslation = () => {
    const { t } = useTranslation('storeSearch');
    return (
      <StyledSearchField
        name="search"
        variant="rounded"
        type="search"
        aria-label={t('search')}
        placeholder={t('search')}
        onChange={onChange}
        value={storeValue}
        customValidity={storeValue && errorMessage}
        onKeyDown={onKeyDownInTakeover}
        onCloseTakeover={onBlurHandler}
        clearable
        onClear={onClearHandler}
        autoComplete="off"
        autoFocus
      />
    );
  };

  return (
    <DynamicNamespaces namespaces={['storeSearch']}>
      <StyledSearch>
        <StyledSearchIcon svg={Search} size={16} color="primary" />
        <SearchFieldWithTranslation />
        <StyledAutoCompleteContainer takeover={false}>
          {(searchSuggestions || emptyAutocompleteContent) && showSuggestions && autocompleteFragment}
        </StyledAutoCompleteContainer>
      </StyledSearch>
    </DynamicNamespaces>
  );
};

export default StoreSearch;
