/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Order } from '../cms-dto';
// @ts-ignore
import { OrderHistoryList } from '../cms-dto';
/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of orders placed by a specified user for a specified base store.
         * @summary Get total number of orders
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountOrdersForUserUsingHEAD: async (baseSiteId: string, userId: string, statuses?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCountOrdersForUserUsingHEAD', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCountOrdersForUserUsingHEAD', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/orders`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns specific order details based on a specific order code. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderForUserByCodeUsingGET: async (code: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getOrderForUserByCodeUsingGET', 'code', code)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getOrderForUserByCodeUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOrderForUserByCodeUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/orders/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns details of a specific order based on the order GUID (Globally Unique Identifier) or the order CODE. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET: async (code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getOrderUsingGET', 'code', code)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getOrderUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/orders/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns order history data for all orders placed by a specified user for a specified base store. The response can display the results across multiple pages, if required.
         * @summary Get order history for user
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForUserUsingGET: async (baseSiteId: string, userId: string, statuses?: string, currentPage?: number, pageSize?: number, sort?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getOrdersForUserUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOrdersForUserUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/orders`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorizes the cart and places the order. The response contains the new order data.
         * @summary Post a order
         * @param {string} cartId Cart code for logged in user, cart GUID for guest checkout
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [securityCode] CCV security code.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeOrderUsingPOST: async (cartId: string, baseSiteId: string, userId: string, securityCode?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('placeOrderUsingPOST', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('placeOrderUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('placeOrderUsingPOST', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/orders`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (cartId !== undefined) {
                localVarQueryParameter['cartId'] = cartId;
            }

            if (securityCode !== undefined) {
                localVarQueryParameter['securityCode'] = securityCode;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of orders placed by a specified user for a specified base store.
         * @summary Get total number of orders
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountOrdersForUserUsingHEAD(baseSiteId: string, userId: string, statuses?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountOrdersForUserUsingHEAD(baseSiteId, userId, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns specific order details based on a specific order code. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderForUserByCodeUsingGET(code: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderForUserByCodeUsingGET(code, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns details of a specific order based on the order GUID (Globally Unique Identifier) or the order CODE. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderUsingGET(code, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns order history data for all orders placed by a specified user for a specified base store. The response can display the results across multiple pages, if required.
         * @summary Get order history for user
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForUserUsingGET(baseSiteId: string, userId: string, statuses?: string, currentPage?: number, pageSize?: number, sort?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForUserUsingGET(baseSiteId, userId, statuses, currentPage, pageSize, sort, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authorizes the cart and places the order. The response contains the new order data.
         * @summary Post a order
         * @param {string} cartId Cart code for logged in user, cart GUID for guest checkout
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [securityCode] CCV security code.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeOrderUsingPOST(cartId: string, baseSiteId: string, userId: string, securityCode?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeOrderUsingPOST(cartId, baseSiteId, userId, securityCode, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the total number of orders placed by a specified user for a specified base store.
         * @summary Get total number of orders
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountOrdersForUserUsingHEAD(baseSiteId: string, userId: string, statuses?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCountOrdersForUserUsingHEAD(baseSiteId, userId, statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns specific order details based on a specific order code. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderForUserByCodeUsingGET(code: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Order> {
            return localVarFp.getOrderForUserByCodeUsingGET(code, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns details of a specific order based on the order GUID (Globally Unique Identifier) or the order CODE. The response contains detailed order information.
         * @summary Get a order
         * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Order> {
            return localVarFp.getOrderUsingGET(code, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns order history data for all orders placed by a specified user for a specified base store. The response can display the results across multiple pages, if required.
         * @summary Get order history for user
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForUserUsingGET(baseSiteId: string, userId: string, statuses?: string, currentPage?: number, pageSize?: number, sort?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<OrderHistoryList> {
            return localVarFp.getOrdersForUserUsingGET(baseSiteId, userId, statuses, currentPage, pageSize, sort, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Authorizes the cart and places the order. The response contains the new order data.
         * @summary Post a order
         * @param {string} cartId Cart code for logged in user, cart GUID for guest checkout
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [securityCode] CCV security code.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeOrderUsingPOST(cartId: string, baseSiteId: string, userId: string, securityCode?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Order> {
            return localVarFp.placeOrderUsingPOST(cartId, baseSiteId, userId, securityCode, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * In the response header, the \"x-total-count\" indicates the total number of orders placed by a specified user for a specified base store.
     * @summary Get total number of orders
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getCountOrdersForUserUsingHEAD(baseSiteId: string, userId: string, statuses?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getCountOrdersForUserUsingHEAD(baseSiteId, userId, statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns specific order details based on a specific order code. The response contains detailed order information.
     * @summary Get a order
     * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrderForUserByCodeUsingGET(code: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrderForUserByCodeUsingGET(code, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns details of a specific order based on the order GUID (Globally Unique Identifier) or the order CODE. The response contains detailed order information.
     * @summary Get a order
     * @param {string} code Order GUID (Globally Unique Identifier) or order CODE
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrderUsingGET(code: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrderUsingGET(code, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns order history data for all orders placed by a specified user for a specified base store. The response can display the results across multiple pages, if required.
     * @summary Get order history for user
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [statuses] Filters only certain order statuses. For example, statuses&#x3D;CANCELLED,CHECKED_VALID would only return orders with status CANCELLED or CHECKED_VALID.
     * @param {number} [currentPage] The current result page requested.
     * @param {number} [pageSize] The number of results returned per page.
     * @param {string} [sort] Sorting method applied to the return results.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrdersForUserUsingGET(baseSiteId: string, userId: string, statuses?: string, currentPage?: number, pageSize?: number, sort?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrdersForUserUsingGET(baseSiteId, userId, statuses, currentPage, pageSize, sort, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorizes the cart and places the order. The response contains the new order data.
     * @summary Post a order
     * @param {string} cartId Cart code for logged in user, cart GUID for guest checkout
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [securityCode] CCV security code.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public placeOrderUsingPOST(cartId: string, baseSiteId: string, userId: string, securityCode?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).placeOrderUsingPOST(cartId, baseSiteId, userId, securityCode, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
