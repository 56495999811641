import axios, { AxiosResponse } from 'axios';
import { AxfoodCartViewModel } from '@api/generated/storefront';

const postReplaceAll = (replaceAll: boolean[]): Promise<AxiosResponse<AxfoodCartViewModel>> => {
  const API_URL = `/axfood/rest/cart/update-noreplacement`;
  const data = new FormData();

  [...replaceAll].forEach((replace, i) => {
    data.append(`entries[${i}]`, (!replace).toString());
  });

  return axios.post(API_URL, data);
};

export default postReplaceAll;
