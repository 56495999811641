import { ReactNode } from 'react';
import { StyledRoundButton } from './RoundButton.styles';

type ColorType = 'white' | 'black' | 'red' | 'grey' | 'highlight';
type SizeType = 'x-small' | 'small' | 'medium' | 'large' | 'xlarge' | '';
interface Props {
  onClick?: () => void;
  tabIndex?: number;
  children: ReactNode;
  color: ColorType;
  disabled?: boolean;
  className?: string;
  size?: SizeType;
  'data-testid'?: string;
  'aria-label'?: string;
}

const RoundButton = ({
  onClick,
  tabIndex,
  disabled = false,
  children,
  size = '',
  color,
  className,
  ...props
}: Props) => {
  return (
    <StyledRoundButton
      onClick={onClick}
      size={size}
      color={color}
      tabIndex={tabIndex}
      type="button"
      disabled={disabled}
      className={className}
      aria-label={props['aria-label']}
      data-testid={props['data-testid']}
    >
      {children}
    </StyledRoundButton>
  );
};

export default RoundButton;
