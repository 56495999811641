import styled, { css } from 'styled-components';
import Config from '@config';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import { ProductProps } from '@components/molecules/Product/Product';
import { offlineGridElementHeight, onlineGridElementHeight } from '@styles/mixins/gridElementHeight';

type StyledProductProps = Pick<ProductProps, 'variant' | 'offline'>;
export const StyledProduct = styled.div<StyledProductProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${variables.colors.white};
  max-width: calc(100% - ${rem(1)});
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  margin: ${rem(-1)} 0 0 ${rem(-1)};
  box-sizing: content-box;

  ${(props) => !props.offline && onlineGridElementHeight}

  ${(props) => props.offline && offlineGridElementHeight};

  ${(props) =>
    (props.variant === 'mixmatch' || props.variant === 'multisearch') &&
    css`
      margin-top: 0;
    `}

  ${(props) =>
    props.variant === 'multisearch' &&
    css`
      &:first-child {
        margin-left: 0;
      }
    `}
`;

export const StyledProductLinkWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledProductHeader = styled.div`
  height: ${rem(172)};
  position: relative;
  padding: ${rem(36)} ${rem(12)} ${rem(12)};

  ${Config.MEDIA.FROM_MOBILE} {
    height: ${rem(208)};
  }
`;

export const StyledLowestHistoricalPrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductFooter = styled.div`
  font-size: ${rem(13)};
  align-self: flex-end;
  width: 100%;
  padding: ${rem(10)} ${rem(12)} ${rem(12)} ${rem(12)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    font-size: ${rem(15)};
  }
`;

export const StyledProductFooterBottomSection = styled.div`
  color: ${variables.colors.darkerGray};
  font-size: ${rem(11)};
  line-height: ${rem(14)};
  margin-top: auto;
  margin-bottom: ${rem(4)};
`;

export const StyledProductName = styled.div`
  line-height: ${rem(20)};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledProductManufacturerVolumeWrapper = styled.div`
  display: table;
  width: 100%;
  line-height: ${rem(16)};
  font-size: ${rem(11)};
  text-transform: uppercase;
  color: ${variables.colors.darkerGray};

  &:before {
    display: table-column;
    width: 100%;
    content: '';
  }
`;

export const StyledProductManufacturerBrand = styled.span`
  display: table-cell;
  max-width: ${rem(1)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledProductDisplayVolume = styled.span`
  white-space: nowrap;
`;

export const StyledProductAddToList = styled.div`
  position: absolute;
  top: ${rem(36)};
  left: ${rem(12)};

  ${Config.MEDIA.FROM_MOBILE} {
    top: ${rem(12)};
  }
`;

export const StyledProductPriceWrapper = styled.div`
  display: block;
  position: absolute;
  right: ${rem(12)};
  top: ${rem(12)};
`;

export const StyledProductEnergyWrapper = styled.div`
  display: block;
  position: absolute;
  right: ${rem(12)};
  bottom: ${rem(20)};
`;

export const StyledProductSavePrice = styled.div`
  background: ${variables.colors.lightGray};
  border-radius: ${rem(2)};
  direction: ltr;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(10)};
  height: ${rem(20)};
  line-height: 2;
  min-width: 100%;
  padding: ${rem(0)} ${rem(8)};
  position: absolute;
  right: ${rem(0)};
  text-align: center;
  text-transform: uppercase;
  top: ${rem(0)};
  unicode-bidi: embed;
  white-space: nowrap;
`;

export const StyledProductPromotionPriceInfo = styled.div`
  color: ${variables.colors.primary};
`;

export const StyledProductOriginalPrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductPromotionsPreviewCode = styled.div`
  margin-top: ${rem(6)};
  font-size: ${rem(13)};
  line-height: ${rem(18)};
  font-weight: bold;
`;

export const StyledProductComparePrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductZCodes = styled.div`
  position: absolute;
  height: ${rem(67)};
  left: ${rem(12)};
  bottom: ${rem(30)};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Config.MEDIA.FROM_MOBILE} {
    top: ${rem(71)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    top: ${rem(77)};
  }
`;

export const StyledProductZCodesIcon = styled.img`
  height: ${rem(20)};
  width: ${rem(20)};
`;

export const StyledProductUsedPromotion = styled.div`
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - ${rem(1)});
  height: calc(100% - ${rem(1)});
  background: ${rgba(variables.colors.white, 0.7)};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
`;

export const StyledImageWrapper = styled.div`
  max-width: ${rem(92)};

  ${Config.MEDIA.FROM_MOBILE} {
    max-width: ${rem(120)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(132)};
  }

  img {
    width: 100%;
    height: auto;
  }
`;
