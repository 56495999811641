import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Grid from '@atoms/Grid/Grid';

export const SkeletonProductWrapper = styled(Grid)`
  border: ${rem(1)} solid ${variables.colors.lightGray};
  margin-bottom: ${rem(32)};
  height: ${rem(400)};
`;

export const SkeletonWrapper = styled.div<{ isMounted: boolean; pageBeam?: boolean }>`
  margin-bottom: ${rem(52)};
  margin-top: ${rem(52)};
  ${(props) =>
    !props.isMounted &&
    css`
      visibility: hidden;
    `}

  ${(props) =>
    props.pageBeam &&
    css`
      margin-top: ${rem(30)};
    `}
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - ${rem(14)});
  margin: ${rem(7)} ${rem(12)} ${rem(7)} ${rem(12)};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const ProductInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(3)};
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(5)};
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;

export const ProductContainer = styled.div`
  border-right: ${rem(1)} solid ${variables.colors.lightGray};

  &:last-child {
    border: none;
  }
`;

export const SkeletonPageBeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  margin-bottom: ${rem(20)};
`;

export const SkeletonPageBeamFilters = styled.div`
  display: flex;
  justify-content: space-between;
`;
