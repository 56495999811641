import { ComponentApi } from '@api/generated/storefront/storefront/component-api';
import { AxiosRequestConfig } from 'axios';
import type { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import { ComponentWsDTO } from '@api/generated/cms';

const componentApi = new ComponentApi(undefined, '');

export const getNavigationData = (componentId: string, pageLabel: string, options?: AxiosRequestConfig) =>
  componentApi.getNavigationDataUsingGET(componentId, pageLabel, options);

export const getItemsForYear = (yearUid: string, options?: AxiosRequestConfig) =>
  componentApi.getItemsForYearUsingGET(yearUid, options);

export interface ProductAndQuantity extends AxfoodProductDetailsViewModel {
  quantity: number;
}

// TODO: Requested type in https://axfood.atlassian.net/browse/B2C-29977
export interface BuyAllProductsWsDTO {
  title: string;
  products: ProductAndQuantity[];
  gridPackages?: {
    component?: ComponentWsDTO;
  };
}

export const getProductsForBuyAllBanner = async (componentId: string, options?: AxiosRequestConfig) => {
  const response = await componentApi.getProductsForBuyAllBannerUsingGET(componentId, options);
  return response.data as BuyAllProductsWsDTO;
};

export const getProductBannerPackages = async (componentId: string, options?: AxiosRequestConfig) => {
  const response = await componentApi.getPackagesForProductBannerUsingGET(componentId, options);
  return response.data;
};

export const getProductBannerComponent = async (
  componentId: string,
  page?: string,
  size?: string,
  promotionStoreUid?: string,
  q?: string,
  sort?: string,
  options?: AxiosRequestConfig
) => {
  const response = await componentApi.getProductsForProductBannerUsingGET(
    componentId,
    page,
    size,
    promotionStoreUid,
    q,
    sort,
    options
  );
  return response.data;
};

export type CategoryNavigationTreeDeviceType = 'ANDROID' | 'IOS' | 'OTHER';
export const getCategoryNavigationTree = async ({
  storeId,
  deviceType,
  options,
}: {
  storeId: string;
  deviceType: CategoryNavigationTreeDeviceType;
  options?: AxiosRequestConfig;
}) => {
  const { data } = await componentApi.getCategoryNavigationTreeUsingGET(storeId, deviceType, options);
  // TODO: We cannot use a generated type yet as BE has defined string as the response type
  return data as unknown as AccordionNavNodeType;
};
