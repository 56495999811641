import useTranslation from 'next-translate/useTranslation';
import Link from '@atoms/Link/Link';
import { StyledBreadCrumbText, StyledBreadCrumbWrapper, StyledIcon } from './NavigationBreadcrumbComponent.styles';
import { BreadcrumbWsDTO } from '@api/generated/cms';
import AccordionDown from '@icons/accordion-down_small.svg';

interface Props {
  data: BreadcrumbWsDTO[];
}

const NavigationBreadcrumbComponent = ({ data }: Props) => {
  const { t } = useTranslation('breadcrumbs');

  const breadCrumbContent = (content: BreadcrumbWsDTO, index: number) => {
    const lastItem = index === data.length - 1;
    return !lastItem ? (
      <Link internal href={content.url || ''} key={content.name}>
        <StyledBreadCrumbText data-testid="breadcrumb-link">{content.name}</StyledBreadCrumbText>
        <StyledIcon svg={AccordionDown} size={8} color="darkerGray" />
      </Link>
    ) : (
      <StyledBreadCrumbText lastItem data-testid="breadcrumb-nolink" key={content.name}>
        {content.name}
      </StyledBreadCrumbText>
    );
  };

  return (
    <StyledBreadCrumbWrapper>
      <Link href="/" key="home">
        <StyledBreadCrumbText>{t('home')}</StyledBreadCrumbText>
        {data.length > 0 && <StyledIcon svg={AccordionDown} size={8} color="darkerGray" />}
      </Link>
      {data?.map((child, i: number) => breadCrumbContent(child, i))}
    </StyledBreadCrumbWrapper>
  );
};

export default NavigationBreadcrumbComponent;
