import useCustomer from '@hooks/useCustomer';
import Logo from '@atoms/Logo/Logo';
import HeaderDropdownMenu from '@molecules/HeaderDropdownMenu/HeaderDropdownMenu';
import Nav from '@molecules/Nav/Nav';
import useTranslation from 'next-translate/useTranslation';
import { StyledHeader, StyledHeaderButtonsWrapper, StyledHeaderWrapper, StyledSharedListLabel } from './Header.styles';
import useResponsive from '@hooks/useResponsive';
import { useMemo } from 'react';
import MiniCart from '@molecules/MiniCart/MiniCart';
import useUserAgent from '@hooks/useUserAgent';
import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import dynamic from 'next/dynamic';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import { BackLinkText } from '@pages/kassa/CheckoutPage.styles';

interface Props {
  slots: ContentSlotWsDTOWithComponent[];
  isCheckout?: boolean;
  isSharedWishList?: boolean;
}

const DynamicDeliveryPicker = dynamic(
  () => import('@organisms/DeliveryPicker/DeliveryPicker' /* webpackChunkName 'DeliveryPicker' */)
);
const DynamicLogin = dynamic(() => import('@organisms/Login/Login' /* webpackChunkName 'Login' */));

const Header = ({ slots, isCheckout = false, isSharedWishList = false }: Props) => {
  const { t } = useTranslation('wishlist');
  const { customer } = useCustomer();
  const { isMobile, isClient, belowDesktop, fromDesktop } = useResponsive();
  const { isNativeApp } = useUserAgent();

  const HeaderLeftSlot = slots?.find((slot) => slot?.position === 'HeaderLeft');
  const HeaderCenterSlot = slots?.find((slot) => slot?.position === 'HeaderCenter');

  const showHeaderDropdownMenu = useMemo(
    () => !isSharedWishList && customer?.isAnonymous === false,
    [isSharedWishList, customer?.isAnonymous]
  );

  return (
    <StyledHeader showBorder={isCheckout || isSharedWishList}>
      {isCheckout && isMobile && (
        <BackLinkText type="body">
          <Link href={paths.CART} internal>
            {t('common:back')}
          </Link>
        </BackLinkText>
      )}
      {HeaderLeftSlot && !isNativeApp && <Logo slot={HeaderLeftSlot} />}

      <StyledHeaderWrapper isNativeApp={isNativeApp}>
        {fromDesktop && HeaderCenterSlot && !isSharedWishList && <Nav slot={HeaderCenterSlot} />}

        {belowDesktop && (
          <StyledHeaderButtonsWrapper isNativeApp={isNativeApp}>
            {isClient && !isCheckout && !isSharedWishList && <DynamicDeliveryPicker />}
            {!isSharedWishList && !isCheckout && <MiniCart />}
          </StyledHeaderButtonsWrapper>
        )}

        {!isSharedWishList && !isNativeApp && <DynamicLogin isCheckout={isCheckout} />}

        {!isNativeApp && showHeaderDropdownMenu && <HeaderDropdownMenu />}
      </StyledHeaderWrapper>

      {isSharedWishList && isMobile && <StyledSharedListLabel type="label">{t('shared->label')}</StyledSharedListLabel>}
    </StyledHeader>
  );
};

export default Header;
