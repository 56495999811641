/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Representation of an Image
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * Tooltip content which is visible while image mouse hovering
     * @type {string}
     * @memberof Image
     */
    'altText'?: string;
    /**
     * Format of the image, can be zoom, product, thumbnail, store, cartIcon, etc.
     * @type {string}
     * @memberof Image
     */
    'format'?: string;
    /**
     * Index of the image while displayed in gallery
     * @type {number}
     * @memberof Image
     */
    'galleryIndex'?: number;
    /**
     * Type of the image, can be PRIMARY or GALLERY
     * @type {string}
     * @memberof Image
     */
    'imageType'?: ImageImageTypeEnum;
    /**
     * URL address of the image
     * @type {string}
     * @memberof Image
     */
    'url'?: string;
}

export const ImageImageTypeEnum = {
    Primary: 'PRIMARY',
    Gallery: 'GALLERY'
} as const;

export type ImageImageTypeEnum = typeof ImageImageTypeEnum[keyof typeof ImageImageTypeEnum];


