
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { AppProps } from 'next/app';
import GlobalStyles from '@styles/global.styles';
import '@config/axios-config';
import AppContextProvider from '@root/context';
import { SWRConfig } from 'swr';
import Config from '@config';
import Layout from '@components/Layout/Layout';
import ErrorBoundary from '@utility/ErrorBoundary/ErrorBoundary';
import ErrorComponent from '@molecules/ErrorComponent/ErrorComponent';
import fallbackCmsPage from './fallbackpage.json';
import { PageAdaptedData } from '@api/generated/cms/cms-dto/page-adapted-data';
import { ErrorInfo } from 'react';
type AppPropsWithLayout = AppProps & {
    fallback: PageAdaptedData;
};
const errorBoundaryHandler = (error: Error, info: ErrorInfo) => {
    if (typeof window.newrelic !== 'undefined') {
        const { newrelic } = window;
        newrelic.noticeError(error, { stackTrace: info.componentStack });
    }
};
/**
 * Initialize pages with a SWR Provider and a shared layout
 */
function MyApp({ Component, pageProps }: AppPropsWithLayout & {
    fallback: PageAdaptedData;
}) {
    return (<>
      <GlobalStyles />
      <SWRConfig value={{
            fallback: { ...pageProps.fallback, fallbackCmsPage },
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            refreshInterval: Config.TIMEOUT.defaultCacheInvalidateMs,
        }}>
        <AppContextProvider>
          <ErrorBoundary errorHandler={errorBoundaryHandler} fallback={<ErrorComponent errorCode={500}/>}>
            <Layout label={pageProps.label}>
              <Component {...pageProps}/>
            </Layout>
          </ErrorBoundary>
        </AppContextProvider>
      </SWRConfig>
    </>);
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  