import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import RoundButton from '@atoms/RoundButton/RoundButton';
import Icon from '@atoms/Icon/Icon';
import Config from '@config';
import { InputFieldProps } from '@atoms/InputField/InputField';
import Button from '@atoms/Button/Button';

const inputType = ['text', 'number', 'tel', 'email', 'search', 'password'];

export const StyledInputField = styled.input<{
  variant: 'box' | 'line' | 'rounded';
  takeover: boolean;
  showPrependIcon: boolean;
  hasClearIcon: boolean;
  isQuantityField: boolean;
  hasItems: boolean;
  isDirty: boolean;
  type: InputFieldProps['type'];
  roundedCorners: boolean;
}>`
  ${(props) =>
    inputType.includes(props.type || '') &&
    css`
      appearance: none;
    `}

  ${(props) =>
    props.type === 'search' &&
    css`
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    `}

  background-color: ${variables.colors.white};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.lighterBlack};

  &:disabled {
    color: ${variables.colors.lighterBlack};
    -webkit-text-fill-color: ${variables.colors.lighterBlack};
    opacity: 0.6;
  }

  &:read-only {
    background-color: ${variables.colors.lighterGray};
    color: ${variables.colors.darkerGray};
    -webkit-text-fill-color: ${variables.colors.darkerGray};
    opacity: 1;
  }

  width: 100%;
  font-size: ${rem(15)};
  height: ${rem(52)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${variables.colors.darkerGray};
  }

  ${(props) =>
    props.isDirty &&
    css`
      &:invalid {
        border-color: ${variables.colors.primaryDark} !important;
      }
    `}

  ${(props) =>
    props.variant === 'box' &&
    css`
      border: ${rem(1)} solid ${variables.colors.darkGray};
      border-radius: ${rem(2)};
      padding: ${rem(10)} ${rem(15)};

      &:focus {
        border: ${rem(1)} solid ${variables.colors.highlight};
      }
    `}

  ${(props) =>
    props.variant === 'rounded' &&
    css`
      border: ${rem(1)} solid ${variables.colors.darkGray};
      border-radius: ${rem(26)};

      &:focus {
        border-color: ${variables.colors.highlight};
      }
    `}

  ${(props) =>
    props.variant === 'line' &&
    css`
      height: ${rem(44)};
      border: 0 none;
      border-radius: unset;
      border-bottom: ${rem(1)} solid ${variables.colors.darkGray};

      &:focus {
        border: none;
        border-bottom: ${rem(1)} solid ${variables.colors.darkGray};
      }
    `}

  ${(props) =>
    props.takeover &&
    css`
      padding-left: ${rem(50)};
      border: ${rem(1)} solid ${variables.colors.mediumGray};

      ${Config.MEDIA.FROM_DESKTOP} {
        &::placeholder {
          font-size: ${rem(15)};
        }

        @supports (-webkit-touch-callout: none) {
          // IOS
          &::placeholder {
            line-height: 2;
          }
        }
      }
    `}

  ${(props) =>
    props.showPrependIcon &&
    css`
      padding-left: ${rem(45)};
    `}

  ${(props) =>
    props.hasClearIcon &&
    css`
      padding-right: ${rem(38)};
    `}

  ${(props) =>
    props.isQuantityField &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: ${rem(28)};
      width: ${rem(28)};
      padding: 0;
      border-radius: 0;
      border: ${rem(1)} solid ${variables.colors.darkGray};
      color: ${variables.colors.darkerGray};
      font-size: ${rem(11)};
      -moz-appearance: textfield;

      &:disabled {
        opacity: 0.6;
      }
    `}

  ${(props) =>
    props.hasItems &&
    css`
      background-color: ${variables.colors.primary};
      margin: 0 ${rem(1)};
      border-color: ${variables.colors.primary};
      color: ${variables.colors.white};
    `}

  ${(props) =>
    props.roundedCorners &&
    css`
      border-radius: ${rem(26)};
    `}
`;

export const StyledContainer = styled.div<{ takeover: boolean; invalid: boolean }>`
  width: 100%;
  position: relative;

  ${(props) =>
    props.takeover === true &&
    css`
      top: 0;
      left: 0;
      position: fixed;
      padding: ${rem(14)} ${rem(20)} 0 ${rem(20)};
      background: ${variables.colors.white};
      z-index: ${variables.zIndex.modal + 1};
    `}
`;

export const StyledPrependIcon = styled(Icon)<{ isTakeover: boolean }>`
  position: absolute;
  left: ${rem(16)};
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledClearButton = styled(Button)<{ isTakeover: boolean }>`
  position: absolute;
  right: ${rem(8)};
  top: 50%;
  transform: translateY(-50%);
  width: ${rem(32)};
  height: ${rem(32)};

  ${(props) =>
    props.isTakeover &&
    css`
      right: ${rem(16)};
    `}
`;

export const StyledAppendButton = styled(RoundButton)`
  position: absolute;
  right: ${rem(16)};
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledBackButton = styled(RoundButton)`
  position: fixed;
  bottom: ${rem(20)};
  left: ${rem(20)};
`;

export const StyledInputLabelWrapper = styled.div`
  display: flex;
`;

export const StyledButtonsAndInputFieldWrapper = styled.div`
  position: relative;
`;
export const StyledInputLabel = styled.label`
  height: ${rem(16)};
  margin: 0 0 ${rem(8)} 0;
  font-family: ${variables.fonts.fontGothamMedium};
  color: ${variables.colors.black};
  font-size: ${rem(13)};
  font-weight: 500;
  text-transform: uppercase;
  display: block;
`;
