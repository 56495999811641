import { ReactNode, useMemo } from 'react';
import useSmartEdit from '@hooks/useSmartEdit';
import { ContentSlotWsDTO } from '@api/generated/cms';

export type CmsContentSlotProps = {
  'data-testid'?: string;
  id?: string;
  slotData?: ContentSlotWsDTO;
  children?: ReactNode;
  className?: string;
};

const CmsContentSlot = ({ 'data-testid': testId, id, slotData, children, className }: CmsContentSlotProps) => {
  const { isSmartEditEnabled, catalogVersionUuid } = useSmartEdit();

  const smartEditProps = useMemo(() => {
    if (!isSmartEditEnabled) return {};

    return {
      'data-smartedit-component-type': 'ContentSlot',
      'data-smartedit-component-id': slotData?.slotId,
      'data-smartedit-component-uuid': slotData?.slotUuid,
      'data-smartedit-catalog-version-uuid': catalogVersionUuid,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmartEditEnabled, slotData]);

  const smartEditClassName = useMemo(() => {
    if (!isSmartEditEnabled) return className;

    return `${className} smartEditComponent`;
  }, [isSmartEditEnabled, className]);

  return (
    <div id={id} data-testid={testId} className={smartEditClassName} {...smartEditProps}>
      {children}
    </div>
  );
};

export default CmsContentSlot;
