import { useEffect, useState } from 'react';
import axios, { AxiosResponse, Canceler } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import getMarketingProducts from '@api/getMarketingProducts';
import getPromotionProducts from '@api/getPromotionProducts';
import RelatedProductsBeam from '@molecules/RelatedProductsBeam/RelatedProductsBeam';
import Spinner from '@molecules/Spinner/Spinner';
import { StyledLoader } from './ProductDetailsRelated.styles';
import {
  AxfoodPaginationViewModelOfAxfoodBasicProductViewModel,
  AxfoodProductDetailsViewModel,
} from '@api/generated/storefront';

interface Props {
  product: AxfoodProductDetailsViewModel;
  isPage?: boolean;
}

type MarketingVariant = 'same-promotion' | 'others-bought' | 'other-variants' | 'similar-products';

type MarketingVariantGetter =
  | {
      type: 'same-promotion';
      title: string;
      get: () => Promise<AxiosResponse<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel>> | null;
      cancel: Canceler;
    }
  | {
      type: 'others-bought' | 'other-variants' | 'similar-products';
      title: string;
      get: () => Promise<AxiosResponse<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel>>;
      cancel: Canceler;
    };

const ProductDetailsRelated = ({ product, isPage }: Props) => {
  const [related, setRelated] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation('productDetailsRelated');
  const titles = {
    'other-variants': t('productDetailsRelated:title->otherVariants'),
    'similar-products': t('productDetailsRelated:title->similarProducts'),
    'others-bought': t('productDetailsRelated:title->othersBought'),
    'same-promotion': t('productDetailsRelated:title->samePromotion'),
  };

  const createGetter = (variant: MarketingVariant): MarketingVariantGetter => {
    const source = axios.CancelToken.source();

    const params = { page: 0, pageSize: 999 };

    if (variant === 'same-promotion') {
      const data = {
        ...params,
        promotion: product.potentialPromotions?.[0]?.code || '',
        excludeProducts: product.code,
      };
      return {
        type: variant,
        title: titles[variant],
        get: () => (data.promotion ? getPromotionProducts(data, source.token) : null),
        cancel: source.cancel,
      };
    } else {
      const data = {
        ...params,
        productCode: product.code,
        variant,
      };

      return {
        type: variant,
        title: titles[variant],
        get: () => getMarketingProducts(data, source.token),
        cancel: source.cancel,
      };
    }
  };

  // The order here is important, since we only want to show the first two beams that have data
  const variants = (['same-promotion', 'others-bought', 'other-variants', 'similar-products'] as MarketingVariant[]).map(
    (variant: MarketingVariant) => createGetter(variant)
  );

  const handleGet = async (getter: MarketingVariantGetter['get']) => {
    try {
      return await getter();
    } catch (e) {
      return { data: { items: [] } };
    }
  };

  const fetch = async () => {
    const relatedData = [];

    try {
      const relatedProductsResponse0 = await handleGet(variants[0].get);
      if (relatedProductsResponse0?.data?.items?.length) {
        relatedData.push({ ...variants[0], data: relatedProductsResponse0?.data });
      }

      const relatedProductsResponse1 = await handleGet(variants[1].get);
      if (relatedProductsResponse1?.data?.items?.length) {
        relatedData.push({ ...variants[1], data: relatedProductsResponse1?.data });
      }

      // we only want to show max 2 beams
      if (relatedData.length < 2) {
        const relatedProductsResponse2 = await handleGet(variants[2].get);
        if (relatedProductsResponse2?.data?.items?.length) {
          relatedData.push({ ...variants[2], data: relatedProductsResponse2?.data });
        }
      }

      if (relatedData.length < 2) {
        const relatedProductsResponse3 = await handleGet(variants[3].get);
        if (relatedProductsResponse3?.data?.items?.length) {
          relatedData.push({ ...variants[3], data: relatedProductsResponse3?.data });
        }
      }

      setRelated(relatedData);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setRelated(null);

    fetch();

    return () => {
      variants.map((variant) => variant.cancel('Cancel RelatedProductsBeam fetch request'));
    };
    // Adding variant to useEffect deps is incorrect in this use case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (loading) {
    return (
      <StyledLoader>
        <Spinner size={32} />
      </StyledLoader>
    );
  }

  if (!related?.length) return null;

  return related?.map((variant: any) => (
    <RelatedProductsBeam key={variant.type} title={variant.title} gridTheme="pdp" data={variant.data} isPage={isPage} />
  ));
};

export default ProductDetailsRelated;
