import { getPromotionStoreId } from '@helpers/localStorageFacade';
import { checkIfStoreIsNotPermanentlyClosed } from '@api/interfaces/storeApi';
import { setPromotionPageStoreId } from '@slices/promotionPageSlice';
import axios from 'axios';
import logger from '@logger';
import { useEffect } from 'react';
import useCustomer from '@hooks/useCustomer';
import { useAppDispatch } from '@hooks/useAppDispatch';

const useStoreAndLocaleState = (getStoreAndSetLocalState: (id: string) => void) => {
  const { customer } = useCustomer();
  const dispatch = useAppDispatch();

  const getStoreAndSetLocalStateFromLocalStorage = async () => {
    const offlineStoreId = getPromotionStoreId() || customer?.homeStoreId;

    if (offlineStoreId) {
      try {
        const { data: closed } = await checkIfStoreIsNotPermanentlyClosed(offlineStoreId);

        if (closed) {
          dispatch(setPromotionPageStoreId(''));
        } else {
          dispatch(setPromotionPageStoreId(offlineStoreId));
          getStoreAndSetLocalState(offlineStoreId);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          logger.error({ error: 'Could not get closed store.' });
        }
      }
    }
  };

  useEffect(() => {
    getStoreAndSetLocalStateFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.isAnonymous]);
};

export default useStoreAndLocaleState;
