import styled from 'styled-components';
import Heading from '@atoms/Heading/Heading';
import { rem } from 'polished';
import Config from '@config';
import GridCol from '@molecules/GridCol/GridCol';
import variables from '@styles/variables';
import Spinner from '@molecules/Spinner/Spinner';

export const StyledPromtionPreviewHeading = styled(Heading)`
  margin: ${rem(20)} 0 ${rem(30)} 0;
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(60)};
  }
`;

export const StyledPromtionPreviewGridCol = styled(GridCol)`
  display: flex;
`;

export const StyledEmptySearchMessageBox = styled.div`
  padding: ${rem(30)};
  background-color ${variables.colors.lighterGray};
`;

export const StyledPreviewSpinner = styled(Spinner)`
  margin-left: ${rem(20)};
`;

export const StyledOffersPreviewWrapper = styled.div`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(20)};
`;
