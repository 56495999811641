import { createAppSlice } from '@slices/createAppSlice';
import type { AppThunk } from '@store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AxfoodCartViewModel } from '@api/generated/storefront';
import { trackViewCart } from '@helpers/analyticsHelpers/trackCheckoutStep';

interface MiniCartState {
  preview: { isOpen: boolean };
  tooltip: {
    discount: {
      value: number;
      show: boolean;
    };
    info: {
      text: string;
      show: boolean;
    };
  };
}

const initialState: MiniCartState = {
  preview: {
    isOpen: false,
  },
  tooltip: {
    discount: {
      value: 0,
      show: false,
    },
    info: {
      text: '',
      show: false,
    },
  },
};

export const miniCartSlice = createAppSlice({
  name: 'minicart',
  initialState,
  reducers: (create) => ({
    setIsCartPreviewOpen: create.reducer((state, action: PayloadAction<boolean>) => {
      state.preview.isOpen = action.payload;
    }),
    setInfoTooltip: create.reducer((state, action: PayloadAction<{ text: string; show: boolean }>) => {
      state.tooltip.info = action.payload;
    }),
    setDiscountTooltip: create.reducer((state, action: PayloadAction<{ value: number; show: boolean }>) => {
      state.tooltip.discount = action.payload;
    }),
  }),
  selectors: {
    selectMiniCart: (miniCart) => miniCart,
    selectMiniCartPreviewIsOpen: (miniCart) => miniCart.preview.isOpen,
  },
});

// Actions
export const { setInfoTooltip, setDiscountTooltip } = miniCartSlice.actions;

// Synchronous Thunks
export const setIsCartPreviewOpen =
  (isCartPreviewOpen: boolean, cart?: AxfoodCartViewModel): AppThunk =>
  (dispatch, getState) => {
    const { minicart } = getState();
    if (isCartPreviewOpen && cart?.products && !minicart.preview.isOpen) {
      trackViewCart(cart, { category: 'minicart' });
    }
    dispatch(miniCartSlice.actions.setIsCartPreviewOpen(isCartPreviewOpen));
  };

// Selectors
export const { selectMiniCart, selectMiniCartPreviewIsOpen } = miniCartSlice.selectors;

export default miniCartSlice.reducer;
