import useTranslation from 'next-translate/useTranslation';
import FilterAndSort from '@organisms/FilterAndSort/FilterAndSort';
import ProductContainer from '@molecules/ProductContainer/ProductContainer';
import LoadMore from '@molecules/LoadMore/LoadMore';
import { BreadcrumbDataOfSearchStateData, FacetSearchPageData } from '@api/generated/storefront';
import useVouchers from '@hooks/useVouchers';
import React from 'react';

/**
 * Render promotionOffers for given type
 * @param type - online | offline
 * @returns {*}
 */
type Props = {
  storeType: 'online' | 'offline';
  generalCampaigns?: FacetSearchPageData;
  isLoading: boolean;
  loadMore: (storeType: onlineOfflineType, track: boolean) => void;
  hideAddToList?: boolean;
  mode?: 'default' | 'preview';
  showProgress?: boolean;
  personalCampaigns?: FacetSearchPageData | null;
  isPreviewMode?: boolean;
};

const PromotionOffersFragment = ({
  storeType,
  generalCampaigns,
  isLoading,
  loadMore,
  hideAddToList = false,
  mode = 'default',
  showProgress = false,
  personalCampaigns,
  isPreviewMode = false,
}: Props) => {
  const { pagination, results: products, breadcrumbs, facets } = generalCampaigns || {};
  const { t } = useTranslation('promotionPage');
  const { onlineVouchers, offlineVouchers } = useVouchers(isPreviewMode);
  const promotionVouchers = storeType === 'online' ? onlineVouchers : offlineVouchers;

  return (
    <div>
      {facets && (
        <FilterAndSort
          filterOptions={facets}
          selectedFilters={breadcrumbs as Array<BreadcrumbDataOfSearchStateData>}
          totalResults={pagination?.totalNumberOfResults}
        />
      )}

      {products && products?.length > 0 && (
        <>
          <ProductContainer
            title={''}
            products={[...((personalCampaigns?.results as any) ?? []), ...(products ?? [])]}
            productType={storeType}
            vouchers={promotionVouchers}
            category={`promotion_page | ${storeType === 'offline' ? 'store' : 'ecommerce'} | generic`}
            offline={storeType === 'offline'}
            hideAddToList={hideAddToList}
          />

          <LoadMore
            pageSize={pagination?.pageSize}
            total={pagination?.totalNumberOfResults as number}
            limit={products.length}
            onClick={() => loadMore(storeType, true)}
            showProgress={showProgress}
            loading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default PromotionOffersFragment;
