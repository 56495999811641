import { findComponentsByIds } from '@api/interfaces/cmsComponentApi';
import useSWRImmutable from 'swr/immutable';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';
import useCustomer from '@hooks/useCustomer';

/**
 * SWR hook for cms components
 *
 * changes to customerId, storeId, slotCode will cause SWR to
 * re-fetch the components
 *
 * @param componentIds
 * @param queryStoreId
 */
const useCmsComponents = (componentIds: string, queryStoreId?: string) => {
  const { store } = useStore();
  const { cart } = useCart();
  const { customer } = useCustomer();

  const slotCode = cart?.slot?.code;
  const storeId = store?.storeId;

  const shouldFetch = Boolean(store) && Boolean(componentIds) && Boolean(customer) && Boolean(cart?.isocode);

  // useSWRImmutable flags the resource as immutable. Only key changes will trigger re-fetch
  const { data, isLoading, error } = useSWRImmutable(
    shouldFetch
      ? {
          componentIds,
          queryStoreId,
          customerId: customer?.uid,
          storeId,
          slotCode,
        }
      : null,
    ({ componentIds, queryStoreId }) => findComponentsByIds(JSON.parse(componentIds), queryStoreId)
  );

  return {
    data,
    isLoading,
    error,
  };
};

export default useCmsComponents;
