import React, { ReactNode, useEffect, useRef, useState } from 'react';
import packageComponentHelper from '@helpers/packageComponentHelper';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import Icon from '@atoms/Icon/Icon';
import Modal from '@organisms/Modal/Modal';
import useTranslation from 'next-translate/useTranslation';
import useSnackBar from '@hooks/useSnackbar';
import { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import ProductContainer from '@molecules/ProductContainer/ProductContainer';
import Cart from '@icons/cart.svg';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';
import useCartActions from '@hooks/useCartActions';
import { useAmountOfItemsPerSlide } from '@hooks/useAmountOfItemsPerSlide';
import useCmsComponents from '@hooks/useCmsComponents';
import {
  trackChangeQuantity,
  trackCloseModal,
  trackOpenModal,
  trackPercentageOfProductsAdded,
} from '@helpers/analyticsHelpers/trackBuyAllProductsComponent';
import {
  StyledButton,
  StyledButtonWrapper,
  StyledBuyButton,
  StyledCarousel,
  StyledHeading,
  StyledModalContent,
  StyledProductDummy,
} from './BuyAllProductsComponent.styles';
import type { ComponentWsDTO, ListAdaptedComponents } from '@api/generated/cms';
import type { BuyAllProductsWsDTO, ProductAndQuantity } from '@api/interfaces/componentApi';
import useBuyAllProducts from '@organisms/BuyAllProductsComponent/useBuyAllProducts';
import useResponsive from '@hooks/useResponsive';
import TrackedComponent from '@organisms/TrackedComponent/TrackedComponent';
import BuyAllProductsListItem from '@organisms/BuyAllProductsComponent/BuyAllProductsListItem';
import Product from '@molecules/Product/Product';
import useProductImpressionTracking from '@hooks/useProductImpressionsTracking';

export interface GridPackagesComponentWsDTO extends ComponentWsDTO {
  displayType: string;
  gridPackages: string;
}

interface Props {
  data: GridPackagesComponentWsDTO;
}

const packageHelper = packageComponentHelper();

const productListWithPositions = (
  mediaQueryKey: 's' | 'm' | 'l',
  amountOfItemsPerSlide: number,
  cmsComponents?: ListAdaptedComponents,
  componentContent?: BuyAllProductsWsDTO
) => {
  if (cmsComponents?.component && componentContent?.products) {
    return packageHelper.positionPackagesInList(
      componentContent.products,
      cmsComponents.component as ImageGridComponentType[],
      mediaQueryKey,
      amountOfItemsPerSlide
    );
  }
  return componentContent?.products;
};

const BuyAllProductsComponent = ({ data }: Props) => {
  const [showBuyAllProductsModal, setShowBuyAllProductsModal] = useState(false);
  const { data: componentContent } = useBuyAllProducts(data?.uid);
  const { data: cmsComponents } = useCmsComponents(JSON.stringify(data?.gridPackages?.replace(/[[\]"]+/g, '')));

  const [modalProducts, setModalProducts] = useState<Array<ProductAndQuantity>>([]);
  const [modalAmountOfProducts, setModalAmountOfProducts] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const { cart } = useCart();
  const { postToCart } = useCartActions();
  const { t } = useTranslation('buyAllProductsComponent');
  const setSnack = useSnackBar();
  const { store } = useStore();
  const amountOfItemsPerSlide = useAmountOfItemsPerSlide();
  const { isMobile, isTablet } = useResponsive();
  const getCurrentMediaQueryKey = () => (isMobile ? 's' : isTablet ? 'm' : 'l');
  const productsOrImages = productListWithPositions(
    getCurrentMediaQueryKey(),
    amountOfItemsPerSlide,
    cmsComponents,
    componentContent
  );

  const { onProductShown } = useProductImpressionTracking('buy-all-products-component');
  const { onProductShown: onProductShownInModal } = useProductImpressionTracking('buy-all-products-component-modal');

  const getProductElements = (items: (AxfoodProductDetailsViewModel | ImageGridComponentType)[]) => {
    const elementsArr: Array<ReactNode> = [];
    items?.forEach((child, i) => {
      if (Object.prototype.hasOwnProperty.call(child, 'typeCode')) {
        elementsArr.push(<CmsComponent component={{ ...child, gridCols: amountOfItemsPerSlide }} />);
      } else {
        elementsArr.push(
          <TrackedComponent
            index={i}
            product={child as AxfoodProductDetailsViewModel}
            key={`buy-all-${child.code}`}
            callback={() => onProductShown(child as AxfoodProductDetailsViewModel, i)}
          >
            <Product
              product={child as AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel}
              variant="multisearch"
              category={`buy-all-products-component`}
            />
          </TrackedComponent>
        );
      }
    });
    return elementsArr;
  };

  const populateModalProducts = (products: Array<AxfoodProductDetailsViewModel>) => {
    if (products) {
      setModalProducts(structuredClone(products) as Array<ProductAndQuantity>);
    } else {
      setModalProducts([]);
    }
  };

  useEffect(() => {
    if (componentContent?.products) {
      populateModalProducts(componentContent.products);
      setModalAmountOfProducts(componentContent.products.length);
    }
  }, [componentContent?.products]);

  const getAmountAlreadyInCart = (productCode: string) => {
    const product = cart?.products.find((item) => item.code === productCode);
    return product?.pickQuantity || 0;
  };

  const onCloseHandler = (purchase?: boolean) => {
    setShowBuyAllProductsModal(false);
    if (componentContent) {
      populateModalProducts(componentContent.products);
    }

    if (purchase) setSnack({ text: t('snackMessage'), icon: 'valid' });
    else trackCloseModal();
  };
  const onClickHandler = async () => {
    const cartProducts = modalProducts
      .filter((item: any) => item.quantity > 0)
      .map((item: any) => {
        const amountInCart = getAmountAlreadyInCart(item.code);
        return {
          product: item,
          newQuantity: amountInCart + item.quantity,
          prevQuantity: amountInCart,
          listName: `buy_all_beam | ${componentContent?.title}`,
        };
      });
    await postToCart(cartProducts, store);
    onCloseHandler(true);
  };

  return (
    <>
      {componentContent && productsOrImages && (
        <div ref={ref}>
          {data.displayType === 'SINGLE_ROW' && (
            <StyledCarousel
              elements={getProductElements(productsOrImages)}
              elementsPerSlide={amountOfItemsPerSlide}
              elementDummy={<StyledProductDummy />}
              variant="buyAllProducts"
              title={componentContent.title}
              key={`${componentContent.title}`}
            />
          )}

          {data.displayType === 'MULTIPLE_ROWS' && (
            <>
              <StyledHeading variant="h2" size="small">
                {componentContent.title}
              </StyledHeading>

              <ProductContainer
                title={componentContent.title}
                products={productsOrImages}
                productType="online"
                offline={false}
                numberOfGridCols={amountOfItemsPerSlide}
              />
            </>
          )}
          <StyledBuyButton
            onClick={() => {
              setShowBuyAllProductsModal(true);
              trackOpenModal();
            }}
          >
            <Icon svg={Cart} size={20} color="white" />
            {t('buyAllProductsComponent:buyAllProducts', { amount: componentContent?.products.length || 0 })}
          </StyledBuyButton>
          {showBuyAllProductsModal && (
            <Modal
              size="medium"
              closeOnEscape
              noPadding
              hasCloseIcon
              onClose={() => {
                onCloseHandler();
              }}
              buttonWidth={210}
            >
              <StyledModalContent>
                <StyledHeading size="small" variant="h2">
                  {componentContent?.title}
                </StyledHeading>
                <div>
                  {modalProducts.map((product, i) => (
                    <TrackedComponent
                      index={i}
                      product={product}
                      key={`buyAll-${product.code}`}
                      callback={() => onProductShownInModal(product, i)}
                    >
                      <BuyAllProductsListItem
                        product={product}
                        quantity={product.quantity}
                        quantityCallback={(quantity: number) => {
                          product.quantity = quantity;
                          setModalAmountOfProducts(modalProducts.filter((item) => item.quantity > 0).length);
                          trackChangeQuantity(product.quantity < quantity);
                        }}
                      />
                    </TrackedComponent>
                  ))}
                </div>
              </StyledModalContent>
              <StyledButtonWrapper>
                <StyledButton theme="secondary" onClick={() => onCloseHandler()}>
                  {t('cancel')}
                </StyledButton>
                <StyledButton
                  onClick={async () => {
                    await onClickHandler();
                    trackPercentageOfProductsAdded(
                      (modalAmountOfProducts / componentContent?.products.length).toFixed(1)
                    );
                  }}
                  disabled={modalAmountOfProducts === 0}
                  theme="primary"
                >
                  {t('buyAllProductsComponent:buyAmountOfProducts', {
                    amount: modalAmountOfProducts,
                    total: componentContent?.products.length,
                  })}
                </StyledButton>
              </StyledButtonWrapper>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default BuyAllProductsComponent;
