import { StyledDeliveryBox, StyledDeliveryBoxLeftContent, StyledDeliveryBoxRightContent } from './DeliveryBox.styles';
import React, { MouseEvent, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  children: ReactNode;
  active?: boolean;
  onClick?: (a?: MouseEvent<HTMLDivElement>) => void;
}

const DeliveryBox = ({ icon, children, active = false, onClick, ...props }: Props) => {
  return (
    <StyledDeliveryBox onClick={onClick} active={active} role="button" {...props}>
      {icon && <StyledDeliveryBoxLeftContent>{icon}</StyledDeliveryBoxLeftContent>}
      {children && <StyledDeliveryBoxRightContent>{children}</StyledDeliveryBoxRightContent>}
    </StyledDeliveryBox>
  );
};

export default DeliveryBox;
