import { useAppSelector } from '@hooks/useAppDispatch';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { getProduct, getPromotionProduct } from '@api/interfaces/productApi';
import useCustomRouter from '@hooks/useCustomRouter';
import { selectPromotionPage } from '@slices/promotionPageSlice';
import useSWR, { Fetcher } from 'swr';
import trackProductDetail from '@helpers/analyticsHelpers/trackProductDetail';
import getProductLabelSplashType from '@helpers/getProductLabelSplashType';
import { AxfoodOfflineProductViewModel, AxfoodProductDetailsViewModel } from '@api/generated/storefront';

const getProductCodeFromName = (name: string) => {
  const listOfName = name?.split('-');
  return listOfName[listOfName.length - 1].toUpperCase();
};

type keyType = {
  productCode: string;
  promotionStoreId: string;
  name: string;
};
const fetcher: Fetcher<AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel, keyType> = ({
  productCode,
  promotionStoreId,
  name,
}) => {
  const splitQuery = name.split('-');
  const isOfflinePromotionDetail = splitQuery[0] === 'offline';
  if (isOfflinePromotionDetail) {
    const promotionCode = splitQuery[splitQuery.length - 1];
    const promotionStoreIdOrDefault = promotionStoreId || '2110';
    return getPromotionProduct(promotionStoreIdOrDefault, promotionCode);
  } else {
    const parsedProductCode = productCode || getProductCodeFromName(name);
    return getProduct(parsedProductCode);
  }
};

/**
 * Hook for fetching products using router query. It only fetches products
 * if there is a router.query.name present
 *
 * SWR is a strategy to first return the data from cache (stale),
 * then send the fetch request (revalidate), and finally come with the up-to-date data.
 */
const useProduct = () => {
  const router = useCustomRouter();
  const { promotionStoreId } = useAppSelector(selectPromotionPage);

  const name = router.query.name;
  const productCode = router.query.productCode;

  // conditionally fetch if name is router.query.name is available otherwise return null
  const key = name
    ? {
        endpoint: '/axfood/rest/c/{storeId}/{campaignCode} /axfood/rest/p/{productCode}',
        productCode,
        promotionStoreId,
        name,
      }
    : null;
  const { data: product, isLoading, error } = useSWR(key, fetcher);

  if (error) {
    pushGTMEvent({
      event: 'Track',
      category: 'error_page',
      action: 'product_not_found',
      label: window.location.pathname,
      value: 0,
    });
  }

  const productLabelSplashType = product && getProductLabelSplashType(product);

  return {
    product,
    isLoading,
    error,
    productLabelSplashType,
  };
};

export default useProduct;
