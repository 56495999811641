import { CustomerApi } from '@api/generated/storefront/storefront/customer-api';
import {
  CustomerData,
  RegisterCustomerForm,
  RegisterB2bCustomerForm,
  SearchCompanyForm,
  VerifyMembersEmailFlowForm,
  VerifyMembersPhoneFlowForm,
} from '@api/generated/storefront/dto';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const customerApi = new CustomerApi(undefined, '');

// Override optional properties from API
export interface OverriddenCustomerData extends CustomerData {
  isB2BCustomer: boolean;
  uid: string;
  isAnonymous: boolean;
  sapId: string;
  socialSecurityNumer: string;
  suppressAllEmails: boolean;
}

export const getCustomer = async (options?: AxiosRequestConfig): Promise<OverriddenCustomerData> => {
  const response = await customerApi.getSessionInformationUsingGET(options);
  const customer = response.data;

  // Override and default properties from API
  return {
    ...customer,
    uid: customer.uid || 'anonymous',
    isB2BCustomer: customer.isB2BCustomer || false,
    isAnonymous: customer.uid === 'anonymous' || false,
    sapId: customer.uid !== 'anonymous' ? customer.sapId ?? '' : '',
    socialSecurityNumer: customer.uid !== 'anonymous' ? customer.socialSecurityNumer ?? '' : '',
    suppressAllEmails: customer?.suppressAllEmails ?? false,
  };
};

export const lookupB2bCustomer = (form: SearchCompanyForm, options?: AxiosRequestConfig) =>
  customerApi.lookupB2BCustomerUsingPOST(form, options);

export const registerB2cCustomer = (form: RegisterCustomerForm, autoLogin?: boolean, options?: AxiosRequestConfig) =>
  customerApi.registerCustomerUsingPOST(form, autoLogin, options);

// This does not work because wrong spec
// export const deleteAccountCard = new CustomerApi(config).deleteAgreementUsingDELETE;
export const deleteSavedCard = (cardId: string) => {
  return axios.delete(`/axfood/rest/customer/savedcard/${cardId}`);
};

export const registerB2BCustomer = async (form: RegisterB2bCustomerForm) => {
  const res = await customerApi.registerB2BCustomerUsingPOST(form);
  return res.data;
};

export const verifyAccountWithEmail = (form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig) =>
  customerApi.verifyEmailUsingPOST(form, options);

export const verifyAccountWithPhone = (form: VerifyMembersPhoneFlowForm, options?: AxiosRequestConfig) =>
  customerApi.verifyPhoneUsingPOST(form, options);
