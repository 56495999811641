import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import PAGE_SIZES from '@constants/pageSizes';
import getCategory from '@api/getCategory';
import getListingPageListName from '@helpers/analyticsHelpers/getListingPageListName';
import Container from '@atoms/Container/Container';
import Text from '@atoms/Text/Text';
import ProductContainer from '@molecules/ProductContainer/ProductContainer';
import Alert from '@molecules/Alert/Alert';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import FilterAndSort from '@organisms/FilterAndSort/FilterAndSort';
import useResponsiveProductLimit from '@hooks/useResponsiveProductLimit';
import useStockChange from '@hooks/useStockChange';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import useGridTemplateColumns from '@hooks/useGridTemplateColumns';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '@molecules/Spinner/Spinner';
import { trackShownMoreProducts, trackSortedProducts } from '@helpers/analyticsHelpers/trackCategoryProductBanner';
import packageComponentHelper from '@helpers/packageComponentHelper';
import SubCategoryLinks from '@molecules/SubCategoryLinks/SubCategoryLinks';
import useResponsive from '@hooks/useResponsive';
import CategoryProductBannerComponentHead from '@organisms/CategoryProductBannerComponent/CategoryProductBannerComponentHead';
import type {
  AxfoodProductDetailsViewModel,
  BreadcrumbDataOfSearchStateData,
  CategoryData,
  FacetDataOfobject,
} from '@api/generated/storefront';
import {
  BreadcrumbsWrapper,
  HeadingTitle,
  HeadingWrapper,
  SpinnerWrapper,
  StyledAmountText,
} from './CategoryProductBannerComponent.styles';
import useCmsComponents from '@hooks/useCmsComponents';

export type CategoryInfoType = { parentCategoryName: string; name: string };

//TODO: Replace with ProductCategorySearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModelAndCategoryData from API
export type CategoryType = {
  categoryInfo: CategoryInfoType;
  results: AxfoodProductDetailsViewModel[];
  pagination: PaginationType;
  sorts: SortType[];
  breadcrumbs?: Array<BreadcrumbDataOfSearchStateData>;
  facets: FacetDataOfobject[];
  superCategories: Array<BreadcrumbType>;
  subCategories?: Array<CategoryData>;
};

interface Props {
  data: CMSProductBannerComponentType;
}

const CategoryProductBannerComponent = ({ data }: Props) => {
  const packageHelper = packageComponentHelper();
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const router = useRouter();
  const { query } = router;
  const categoryPath = Array.isArray(query.categories) ? query.categories.join('/') : (query.categories as string);
  const { fromDesktop, isMobile, isTablet } = useResponsive();
  const { t } = useTranslation('categorypage');
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const handleRef = useCallback((node: HTMLDivElement) => {
    setRef(node);
  }, []);
  const [category, setCategory] = useState<CategoryType>({} as CategoryType);
  const [items, setItems] = useState<Array<GridPackageComponentType | AxfoodProductDetailsViewModel>>([]);
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const [categoryString, setCategoryString] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [sortOptions, setSortOptions] = useState<OptionType[] | null>(null);
  const [totalVisibleItems, setTotalVisibleItems, getPageLimit] = useResponsiveProductLimit('CATEGORY') as any;
  const { data: gridPackages } = useCmsComponents(JSON.stringify(data?.gridPackages?.replace(/[[\]"]+/g, '')));

  useGridTemplateColumns(setItemsPerRow, ref, isFetching);
  useStockChange(async () => fetchData(query?.sort as string));

  const {
    categoryInfo = {} as CategoryInfoType,
    results: products,
    pagination,
    sorts,
    breadcrumbs,
    facets,
    superCategories,
    subCategories,
  } = category || {
    results: null,
  };

  const getCurrentMediaQueryKey = () => {
    return isMobile ? 's' : isTablet ? 'm' : 'l';
  };

  const fetchData = async (sort: string | undefined, page?: number) => {
    setIsFetching(true);
    const res = await getCategory({
      categoryPath,
      size: PAGE_SIZES.PAGE.CATEGORY.SIZE.DESKTOP,
      q: query?.q as string,
      page,
      sort,
      config: {},
    });

    if (page) {
      setCategory({
        ...category,
        pagination: res.data.pagination,
        results: [...category.results, ...res.data.results],
      });
    } else {
      setCategory(res.data as CategoryType);
    }
    setIsFetching(false);
    setCategoryString(categoryPath);

    if (!res.data) {
      return false;
    }
  };

  const getSortOptions = (): OptionType[] => {
    return sorts?.map((sort) => {
      const { code: value, name, selected } = sort;
      return { value, label: name, selected };
    });
  };

  const getSelectedSort = () => sortOptions?.find((s) => s.selected);

  const onSort = async (option: OptionType) => {
    const selectedSort = getSelectedSort();

    if (selectedSort && option.value !== selectedSort.value) {
      await fetchData(option.value);
      trackSortedProducts(option.label);
    }
  };

  const loadMore = async () => {
    if (totalVisibleItems + getPageLimit() > products.length && pagination.currentPage + 1 < pagination.numberOfPages) {
      const selectedSort = getSelectedSort();
      await fetchData(selectedSort ? selectedSort.value : '', pagination.currentPage + 1);
    }
    trackShownMoreProducts(query);
    setTotalVisibleItems(totalVisibleItems + getPageLimit());
  };

  const fetchComponentData = async () => {
    if (data?.gridPackages && gridPackages?.component) {
      return packageHelper.positionPackagesInList(
        products,
        gridPackages?.component as ImageGridComponentType[],
        getCurrentMediaQueryKey(),
        itemsPerRow
      );
    }
    return products;
  };

  const getItemsData = async () => {
    const itemsData = await fetchComponentData();
    setItems(itemsData);
  };

  useEffect(() => {
    // Reset when change category page
    setTotalVisibleItems(getPageLimit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryInfo.name, sorts]);

  useEffect(() => {
    // Reset when change category page
    setSortOptions(getSortOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorts]);

  useEffect(() => {
    if (category?.results?.length) {
      getItemsData();
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, category]);

  useEffect(() => {
    // Fetch/re-fetch data when query changes
    const sortingOption = categoryString !== categoryPath ? undefined : getSelectedSort()?.value || query?.sort;
    fetchData(sortingOption as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, query?.q, categoryPath]);

  return (
    <>
      {items && pagination && (
        <>
          <CategoryProductBannerComponentHead categoryInfo={categoryInfo} pagination={pagination} />

          <div ref={handleRef}>
            <Container addPaddingIfCartOrMenuOpen={sideMenuIsOpen || isCartPreviewOpen}>
              {superCategories && superCategories.length > 0 && (
                <BreadcrumbsWrapper data-testid="category-breadcrumbs" aria-label="Breadcrumb" role="navigation">
                  <Breadcrumbs breadcrumbs={[...superCategories.slice(1), { name: categoryInfo.name }]} />
                </BreadcrumbsWrapper>
              )}
              {!isMobile && (
                <HeadingWrapper>
                  <HeadingTitle data-testid="category-heading" variant="h2" size="small">
                    {categoryInfo.name}
                  </HeadingTitle>
                  <Text type="body" size="small" data-testid="category-total-amount" role="status">
                    {t('totalResults', { totalResult: pagination.totalNumberOfResults || 0 })}
                  </Text>
                </HeadingWrapper>
              )}

              {subCategories && !fromDesktop && <SubCategoryLinks categories={subCategories} />}

              <FilterAndSort
                filterOptions={facets}
                sortOptions={sortOptions}
                selectedFilters={breadcrumbs}
                onSort={onSort}
                totalResults={pagination.totalNumberOfResults}
              />

              {isMobile && (
                <StyledAmountText type="body" size="small" data-testid="category-total-amount" role="status">
                  {t('totalResults', { totalResult: pagination.totalNumberOfResults || 0 })}
                </StyledAmountText>
              )}

              <InfiniteScroll
                dataLength={totalVisibleItems || 0}
                next={loadMore}
                hasMore={category?.pagination?.totalNumberOfResults > totalVisibleItems}
                loader={
                  <SpinnerWrapper>
                    <Spinner size={32} />
                  </SpinnerWrapper>
                }
                style={{ overflow: undefined }}
              >
                <ProductContainer
                  title={getListingPageListName('category_page', router.query.categories as string, breadcrumbs)}
                  products={items}
                  productType="online"
                  offline={false}
                  limit={totalVisibleItems}
                  numberOfGridCols={itemsPerRow}
                />
              </InfiniteScroll>

              {!pagination?.totalNumberOfResults && (
                <Alert hasCloseButton={false} theme="info" id="noProductsInCategory">
                  <Text type="body" size="small" color="black">
                    {t('noProductsToShow')}
                  </Text>
                </Alert>
              )}
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default CategoryProductBannerComponent;
