import { computeAnalyticsEcommerceItemsTotalValue, convertProductsToAnalyticsEcommerceItems } from './analyticsProduct';
import pushGTMEvent, {
  EcommerceAddPaymentInfoDataLayerEvent,
  EcommerceAddShippingInfoDataLayerEvent,
  EcommerceBeginCheckoutDataLayerEvent,
  EcommerceEvent,
  EcommerceViewCartDataLayerEvent,
  pushGTMEcommerceEvent,
} from '@helpers/analyticsHelpers/pushGTMEvent';
import DeliveryModeCode from '@enums/DeliveryModeCode';
import { PaymentMode } from '@molecules/PaymentModes/PaymentModes';
import { CheckoutState } from '@slices/checkoutSlice';
import { AxfoodCartViewModel, AxfoodProductDetailsViewModel } from '@api/generated/storefront';

export const trackViewCart = (
  cart?: AxfoodCartViewModel,
  parameters?: Omit<EcommerceViewCartDataLayerEvent['ecommerce'], 'event' | 'source' | 'currency' | 'items' | 'value'>
) => {
  if (!cart || cart.products.length === 0) {
    pushGTMEvent({
      event: 'Track',
      category: 'error_page',
      action: 'cart_empty',
      label: '',
      value: 0,
    });
  } else {
    const items = convertProductsToAnalyticsEcommerceItems(cart.products);
    pushGTMEcommerceEvent({
      event: EcommerceEvent.VIEW_CART,
      ecommerce: {
        ...parameters,
        items,
        value: computeAnalyticsEcommerceItemsTotalValue(items),
      },
    });
  }
};

export const trackBeginCheckout = (
  cart: AxfoodCartViewModel,
  parameters?: Omit<
    EcommerceBeginCheckoutDataLayerEvent['ecommerce'],
    'event' | 'source' | 'currency' | 'items' | 'value'
  >
) => {
  const items = convertProductsToAnalyticsEcommerceItems(cart.products);
  pushGTMEcommerceEvent({
    event: EcommerceEvent.BEGIN_CHECKOUT,
    ecommerce: {
      ...parameters,
      items,
      value: computeAnalyticsEcommerceItemsTotalValue(items),
    },
  });
};

export const trackAddShippingInfo = (
  cart: AxfoodCartViewModel,
  deliveryModeCode: DeliveryModeCode,
  parameters?: Omit<
    EcommerceAddShippingInfoDataLayerEvent['ecommerce'],
    'event' | 'source' | 'currency' | 'items' | 'value' | 'shipping_tier'
  >
) => {
  const items = convertProductsToAnalyticsEcommerceItems(cart.products);
  pushGTMEcommerceEvent({
    event: EcommerceEvent.ADD_SHIPPING_INFO,
    ecommerce: {
      ...parameters,
      items,
      value: computeAnalyticsEcommerceItemsTotalValue(items),
      shipping_tier: deliveryModeCode === DeliveryModeCode.HOME_DELIVERY ? 'home_delivery' : 'click_and_collect',
    },
  });
};

export const trackAddPaymentInfo = (
  cart: AxfoodCartViewModel,
  payment: CheckoutState['payment'],
  parameters?: Omit<
    EcommerceAddPaymentInfoDataLayerEvent['ecommerce'],
    'event' | 'source' | 'currency' | 'items' | 'value' | 'payment_mode'
  >
) => {
  let payment_method: EcommerceAddPaymentInfoDataLayerEvent['ecommerce']['payment_method'] | undefined = undefined;
  switch (payment.selectedMode) {
    case PaymentMode.INVOICE:
      payment_method = 'invoice';
      break;
    case PaymentMode.PAYEX:
    case PaymentMode.PSPPAYEXALL:
    case PaymentMode.PAYEXALL:
      if (payment.selectedCard?.id) {
        payment_method = payment.selectedCard.id === 'new' ? 'new_card' : 'existing_card';
      }
      break;
    case PaymentMode.KLARNA:
      payment_method = 'Klarna';
      break;
    default:
      break;
  }
  if (payment_method) {
    const items = convertProductsToAnalyticsEcommerceItems(cart.products);
    pushGTMEcommerceEvent({
      event: EcommerceEvent.ADD_PAYMENT_INFO,
      ecommerce: {
        ...parameters,
        items,
        value: computeAnalyticsEcommerceItemsTotalValue(items),
        payment_method,
      },
    });
  }
};
