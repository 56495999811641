import { BreadcrumbDataOfSearchStateData } from '@api/generated/storefront';

const getListingPageListName = (
  pageName: string,
  query: string | Array<string>,
  breadcrumbs?: Array<BreadcrumbDataOfSearchStateData>
) => {
  const listName = `${pageName} | ${Array.isArray(query) ? query.join('/') : query}`;

  if (breadcrumbs?.length) {
    return `${listName} | Filters: ${breadcrumbs.map((bc) => bc.facetValueName).join(',')}`;
  }

  return listName;
};

export default getListingPageListName;
