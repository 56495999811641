import { useEffect, useRef } from 'react';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';

const useStockChange = (callback: () => void) => {
  const { store } = useStore();
  const { cart } = useCart();

  const prevStoreIdRef = useRef(store?.storeId);
  const prevSlotCodeRef = useRef(cart?.slot?.code);

  // If changing logic below, make sure to update useCmsPage.ts and useCmsComponents.ts accordingly

  useEffect(() => {
    if (prevStoreIdRef.current && prevStoreIdRef.current !== store?.storeId) {
      // Store changed
      if (callback) callback();
    } else if (cart?.slot && prevSlotCodeRef.current && prevSlotCodeRef.current !== cart?.slot?.code) {
      // Slot changed
      if (callback) callback();
    }

    prevSlotCodeRef.current = cart?.slot?.code;
    prevStoreIdRef.current = store?.storeId;
  }, [store?.storeId, cart?.slot?.code, callback, cart?.slot]);
};

export default useStockChange;
