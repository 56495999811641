import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Button from '@atoms/Button/Button';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';

export const StyledPromotionTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  width: 100%;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(12)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
    margin-top: ${rem(24)};
    margin-bottom: ${rem(8)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    gap: ${rem(24)};
  }
`;

export const StyledTab = styled.div`
  width: 100%;
  display: flex;
  -webkit-tap-highlight-color: transparent;
`;

export const StyledButton = styled(Button)`
  height: ${rem(24)};
`;

export const StyledStoreSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
`;

export const StyledStoreSearchTitle = styled(Heading)`
  margin: ${rem(28)} auto;
`;

export const StyledStoreSearchText = styled(Text)`
  margin-bottom: ${rem(12)};
`;

export const StyledOfflineTextWrapper = styled(Text)`
  height: ${rem(24)};
`;
