import { ComponentApi } from '@api/generated/cms';
import Config from '@config';

const componentApi = new ComponentApi(undefined, '/axfoodcommercewebservices/v2');

export const findComponentsByIds = async (componentIds: Array<string>, storeId?: string) => {
  const res = await componentApi.findComponentsByIds(
    Config.SITE_NAME,
    componentIds,
    undefined,
    undefined,
    undefined,
    storeId
  );
  return res.data;
};
