import React, { MouseEvent, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import paths, { LoginQueryParams } from '@constants/paths';
import Text from '@atoms/Text/Text';
import Modal from '@organisms/Modal/Modal';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import variables from '@styles/variables';
import { trackShowModal, trackCloseModal, trackButtonClick } from '@helpers/analyticsHelpers/trackLoginPrompt';

interface Props {
  isCheckout?: boolean;
}

const LoginPrompt = ({ isCheckout = false }: Props) => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  let timeout: NodeJS.Timeout;
  const { t } = useTranslation('login');
  const router = useCustomRouter();

  const setCookie = () => {
    Cookies.set('ignoreLoginCookie', 'true', { expires: 7 });
  };

  useEffect(() => {
    const hasIgnoreLoginCookie = Cookies.get('ignoreLoginCookie');
    if (!hasIgnoreLoginCookie) {
      timeout = setTimeout(() => {
        setShowLoginPrompt(true);
        trackShowModal();
        setCookie();
      }, 10000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      {showLoginPrompt && (
        <Modal
          backdropZIndex={variables.zIndex.backdrop - 1}
          hasCloseIcon
          size="x-small"
          closeOnEscape
          onClose={(event?: MouseEvent) => {
            setShowLoginPrompt(false);
            if (event !== undefined) trackCloseModal();
          }}
          onConfirm={() => {
            trackButtonClick();
            router.push(
              {
                pathname: router.pathname,
                query: {
                  ...router.query,
                  ...(isCheckout ? { from: LoginQueryParams.CHECKOUT_PAGE } : {}),
                },
              },
              paths.USER_LOGIN,
              { shallow: true }
            );
            setShowLoginPrompt(false);
          }}
          confirmButtonText={t('login->submit')}
        >
          <Text type="body" size="small">
            {t('login->loginPrompt')}
          </Text>
        </Modal>
      )}
    </div>
  );
};

export default LoginPrompt;
