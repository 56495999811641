import Icon from '@atoms/Icon/Icon';
import {
  AxfoodBasicProductViewModel,
  AxfoodProductDetailsViewModel,
  WishlistEntryForm,
} from '@api/generated/storefront';
import paths, { LoginQueryParams } from '@constants/paths';
import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button/Button';
import Modal from '@organisms/Modal/Modal';
import Heading from '@atoms/Heading/Heading';
import useWishlists from '@hooks/useWishlists';

import {
  StyledButton,
  StyledButtonWrapper,
  StyledIcon,
  StyledModalContentWrapper,
  StyledProductListItem,
  StyledText,
  StyledWishlistRow,
  StyledWishlistRowText,
  StyledWishlistWrapper,
  StyledWishlistWrapperTopSection,
} from './AddToListComponent.styles';
import Text from '@atoms/Text/Text';
import {
  addToUserWishlist,
  AxfoodBasicWishListViewModelWithIdAndName,
  createNewWishList,
} from '@api/interfaces/wishlistApi';
import webToAppApi from '@api/web-to-app';
import useUserAgent from '@hooks/useUserAgent';
import useCustomRouter from '@hooks/useCustomRouter';
import Spinner from '@molecules/Spinner/Spinner';
import { FormMode } from '@organisms/AddToListDropdown/types';
import useTranslation from 'next-translate/useTranslation';
import useSnackBar from '@hooks/useSnackbar';
import CreateNewListForm from '@organisms/AddToListDropdown/CreateNewListForm';
import {
  trackRedirectToLoginFromAddToList,
  trackShowSaveToWishListModal,
} from '@helpers/analyticsHelpers/trackWishList';
import useCustomer from '@hooks/useCustomer';
import Validation from '@icons/validation.svg';
import AddToList from '@icons/add-to-list.svg';
import List from '@icons/list.svg';
import useCart from '@hooks/useCart';

interface AddToListCallback {
  option: AxfoodBasicWishListViewModelWithIdAndName;
  productEntries: Array<WishlistEntryForm>;
  tracking?: {
    parent: string;
  };
}

interface CreateNewListCallback {
  inputValue: string;
}

interface Props {
  product: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel;
  forceJustify?: 'center' | 'left' | 'right';
  btnIconSize: number;
  productEntries: Array<WishlistEntryForm>;
  addToListCallback?: ({ option, productEntries, tracking }: AddToListCallback) => void;
  createNewListCallback?: ({ inputValue }: CreateNewListCallback) => void;
  btnText?: string;
  disabled?: boolean;
  queryParamsIfNotLoggedIn?: {
    from: LoginQueryParams;
  };
  tracking?: {
    parent: string;
  };
  className?: string;
  forceIconBaseColor?: boolean;
}

const AddToListComponent = ({
  product,
  btnIconSize,
  queryParamsIfNotLoggedIn = { from: LoginQueryParams.MY_LISTS },
  productEntries,
  tracking,
  addToListCallback,
  createNewListCallback,
}: Props) => {
  const { t } = useTranslation('addToList');
  const setSnack = useSnackBar();
  const [showSaveToListModal, setShowSaveToListModal] = useState(false);
  const { customer } = useCustomer();
  const { wishlists, error: wishlistError, isLoading, refreshWishlists } = useWishlists(customer?.uid);
  const { cart } = useCart();
  const [formMode, setFormMode] = useState<FormMode>('ADD');
  const { isNativeApp } = useUserAgent();
  const router = useCustomRouter();
  const [selectedWishListRow, setSelectedWishListRow] = useState<AxfoodBasicWishListViewModelWithIdAndName>(
    {} as AxfoodBasicWishListViewModelWithIdAndName
  );
  const [quantity, setQuantity] = useState(productEntries[0].quantity);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreatingNewList, setIsCreatingNewList] = useState(false);
  const saveToList = async () => {
    setIsFetching(true);
    await addToUserWishlist(selectedWishListRow.id, productEntries);

    if (addToListCallback) {
      addToListCallback({ option: selectedWishListRow, productEntries });
    }

    setIsFetching(false);
    setFormMode('COMPLETE');
    setShowSaveToListModal(false);
    setSnack({
      text: t('addToList->snackBar->text'),
      icon: 'valid',
      link: `${paths.MY_LISTS}/${selectedWishListRow.id}`,
      linkText: t('addToList->snackBar->linkText'),
      onClick: isNativeApp
        ? () => {
            webToAppApi.actionRouteToSpecificList(selectedWishListRow.id);
          }
        : undefined,
    });
  };

  const handleNotLoggedIn = () => {
    if (isNativeApp) {
      webToAppApi.actionRenewAccessToken();
    } else {
      router.push(
        { pathname: router.pathname, query: { ...router.query, ...queryParamsIfNotLoggedIn } },
        {
          pathname: paths.USER_LOGIN,
        },
        { shallow: true }
      );
    }

    if (tracking?.parent) {
      trackRedirectToLoginFromAddToList(tracking.parent);
    }

    setShowSaveToListModal(false);
  };

  const onSaveToListModalOpen = async () => {
    await refreshWishlists();

    if (wishlistError) {
      if (wishlistError.response?.data.customerLoggedIn === 'false') {
        handleNotLoggedIn();
      }
    }

    if (tracking?.parent) {
      trackShowSaveToWishListModal(tracking.parent);
    }
  };

  const onCreateNewListHandler = async (inputValue: string) => {
    setIsCreatingNewList(true);
    try {
      await createNewWishList({
        name: inputValue,
        entries: [],
      });

      await refreshWishlists();
      if (createNewListCallback) {
        createNewListCallback({ inputValue });
      }

      setFormMode('ADD');
    } catch (err) {
      setSnack({
        text: t('addToList->error->onCreate'),
        icon: null,
      });
    } finally {
      setIsCreatingNewList(false);
    }
  };

  const openModal = () => {
    setShowSaveToListModal(true);
    setSnack(null);
  };

  useEffect(() => {
    if (!showSaveToListModal) {
      setSelectedWishListRow({} as AxfoodBasicWishListViewModelWithIdAndName);
      setFormMode('ADD');
    }
    if (showSaveToListModal) {
      onSaveToListModalOpen();
    }
  }, [showSaveToListModal, cart?.products]);

  useEffect(() => {
    productEntries[0].quantity = quantity;
  }, [quantity]);

  useEffect(() => {
    if (wishlists && wishlists.length > 0) setSelectedWishListRow(wishlists[0]);
  }, [wishlists]);

  return (
    <>
      <Button data-testid="open-add-to-list-button" onClick={openModal} theme="transparent">
        <Icon svg={AddToList} size={btnIconSize} color="lighterBlack" />
      </Button>
      {showSaveToListModal && !wishlistError && (
        <Modal
          noPadding
          size="medium"
          hasCloseIcon
          closeOnEscape
          onClose={() => setShowSaveToListModal(false)}
          confirmDisabled={!selectedWishListRow.id}
          isSubmitting={isLoading}
          hasMinHeight
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <StyledModalContentWrapper>
                <Heading variant="h2" size="small">
                  {t('addToList->heading->unsaved')}
                </Heading>
                <StyledProductListItem
                  clickable={false}
                  product={product}
                  quantity={quantity}
                  quantityCallback={(quantity) => {
                    setQuantity(quantity);
                  }}
                  variant="addToList"
                />

                <StyledWishlistWrapper>
                  <StyledWishlistWrapperTopSection>
                    <StyledText type={'subtitle'} size="small">
                      {formMode === 'NEW' ? t('addToList->heading->new') : t('addToList->heading->choose')}
                    </StyledText>
                    {formMode !== 'NEW' && (
                      <Button theme="link" data-testid="create-new-list" onClick={() => setFormMode('NEW')}>
                        {t('addToList->heading->new')}
                      </Button>
                    )}
                  </StyledWishlistWrapperTopSection>
                  {formMode === 'NEW' && (
                    <CreateNewListForm onCreateNewList={onCreateNewListHandler} isSaving={isCreatingNewList} />
                  )}

                  {wishlists &&
                    wishlists.length > 0 &&
                    wishlists?.map((wishlist) => (
                      <StyledWishlistRow
                        data-testid="wishlist-option"
                        theme="transparent"
                        onClick={() => {
                          setSelectedWishListRow(wishlist);
                        }}
                        isSelected={selectedWishListRow.id === wishlist.id}
                        key={wishlist.id}
                      >
                        <StyledIcon
                          color={selectedWishListRow.id === wishlist.id ? 'highlight' : 'black'}
                          svg={selectedWishListRow.id === wishlist.id ? Validation : List}
                          size={24}
                        />
                        <StyledWishlistRowText type="subtitle">
                          {wishlist.name} ({wishlist.numberOfProducts})
                        </StyledWishlistRowText>
                      </StyledWishlistRow>
                    ))}
                  {wishlists && wishlists.length <= 0 && (
                    <Text type="body" size="medium">
                      {t('addToList->empty')}
                    </Text>
                  )}
                </StyledWishlistWrapper>
              </StyledModalContentWrapper>
              <StyledButtonWrapper>
                <StyledButton theme="secondary" onClick={() => setShowSaveToListModal(false)}>
                  {t('addToList->cancel')}
                </StyledButton>
                <StyledButton
                  theme="primary"
                  onClick={saveToList}
                  disabled={!selectedWishListRow.id || !quantity}
                  isSubmitting={isFetching}
                >
                  {t('addToList->saveToList')}
                </StyledButton>
              </StyledButtonWrapper>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddToListComponent;
