export const setDeliveryModeReminderDone = (value: boolean) => {
  localStorage.setItem('willys.deliveryModeReminderDone', `${value}`);
};
export const isDeliveryModeReminderDone = (): boolean => {
  return localStorage.getItem('willys.deliveryModeReminderDone') === 'true';
};

export const setPromotionStoreId = (value: string) => {
  localStorage.setItem('willys.promotionStoreId', value);
};
export const getPromotionStoreId = (): string => {
  return localStorage.getItem('willys.promotionStoreId') || '';
};

export const setBankIdTabLastSelected = (value: boolean) => {
  localStorage.setItem('willys.bankIdTabLastSelected', `${value}`);
};

export const wasBankIdTabLastSelected = (): boolean => {
  return localStorage.getItem('willys.bankIdTabLastSelected') === 'true';
};

export const setPromotionTab = (value: string) => {
  localStorage.setItem('promotion.tab', value);
};

export const getPromotionTab = () => {
  return localStorage.getItem('promotion.tab');
};

// Local storage facade for global messages
const getLocalStorageKey = (id: string): string => `willys.GlobalMessage-${id}`;
export const getGlobalMessageDisplayState = (uid: string) => {
  return localStorage.getItem(getLocalStorageKey(uid));
};
export const setGlobalMessageDisplayState = (uid: string) => {
  localStorage.setItem(getLocalStorageKey(uid), 'true');
};
