import Button from '@atoms/Button/Button';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Icon from '@atoms/Icon/Icon';
import { StyledLink, StyledText, StyledTextWrapper } from './ArticleNavigationMenu.styles';
import MinusSmall from '@icons/minus_small.svg';
import PlusSmall from '@icons/plus_small.svg';

const padding = 12;

interface Props {
  parentNodes: Array<NavigationNode>;
  setExpandedNodes: (nodes: Array<NavigationNode>) => void;
  expandedNodes: Array<NavigationNode>;
  setActiveNode: (node: ActiveNodeType) => void;
  activeNode: ActiveNodeType;
  node: ArticleCMSNode;
  highLightNode?: boolean;
  navigationNodes: Array<NavigationNode>;
  setNavigationNodes: (navigationNodes: Array<NavigationNode>) => void;
}

const ArticleNavigationNode = ({
  parentNodes,
  setExpandedNodes,
  expandedNodes,
  node,
  setActiveNode,
  activeNode,
  highLightNode,
  navigationNodes,
  setNavigationNodes,
}: Props) => {
  const router = useRouter();
  const hasChildren: boolean = node?.children?.length > 0;
  const currentNodeExpanded = expandedNodes.map((n: NavigationNode) => n?.id).includes(node.title);
  const navigationNodeExpanded = navigationNodes.map((n: NavigationNode) => n?.id).includes(node.title);
  const currentNodeHierarchy: Array<NavigationNode> = parentNodes.concat({ id: node.title });
  const isHighlightedNodes = activeNode.parents.map((n: NavigationNode) => n?.id).includes(node.title);
  const isTopLevel: boolean = parentNodes.length === 0;
  const activeNodeHasNoParents: boolean = activeNode.parents.length === 0;
  const isCookieLink = node.links[0]?.id === 'CookieSettings';

  const onClickHandler = () => {
    if (currentNodeExpanded) {
      setExpandedNodes(parentNodes);
      setNavigationNodes(parentNodes);
    } else if (
      (activeNodeHasNoParents ? activeNode.id : activeNode.parents[0]?.id) ===
      (isTopLevel ? node.title : parentNodes[0]?.id)
    ) {
      setExpandedNodes(currentNodeHierarchy);
    } else {
      navigationNodeExpanded ? setNavigationNodes(parentNodes) : setNavigationNodes(currentNodeHierarchy);
    }
  };

  const wrapWithLink = (content: ReactNode) => {
    if (node.links.length > 0) {
      return (
        <StyledLink
          internal
          href={{ pathname: node.links[0].url, query: { ...router.query } }}
          asProp={node.links[0].url}
          target={node?.links[0]?.target}
        >
          {content}
        </StyledLink>
      );
    }
    return content;
  };

  return (
    <>
      <StyledTextWrapper
        paddingLeft={20 + parentNodes.length * padding}
        hasActiveBorder={currentNodeExpanded || isHighlightedNodes || highLightNode}
        data-testid="article-navigation-node"
      >
        {!isCookieLink &&
          wrapWithLink(
            <StyledText
              type={activeNode.id === node.title ? 'subhead' : 'body'}
              size="small"
              color={currentNodeExpanded || navigationNodeExpanded || activeNode.id === node.title ? 'red' : 'black'}
            >
              {node.title}
            </StyledText>
          )}
        {isCookieLink && (
          <StyledText
            type={activeNode.id === node.title ? 'subhead' : 'body'}
            size="small"
            color={currentNodeExpanded || navigationNodeExpanded || activeNode.id === node.title ? 'red' : 'black'}
            className="optanon-toggle-display"
          >
            {node.title}
          </StyledText>
        )}
        {hasChildren && (
          <Button theme="transparent" onClick={onClickHandler}>
            <Icon
              svg={currentNodeExpanded || navigationNodeExpanded ? MinusSmall : PlusSmall}
              size={12}
              color={currentNodeExpanded || navigationNodeExpanded ? 'primary' : 'lighterBlack'}
            />
          </Button>
        )}
      </StyledTextWrapper>
      {hasChildren &&
        (currentNodeExpanded || navigationNodeExpanded) &&
        node.children.map((child, index) => {
          return (
            <ArticleNavigationNode
              parentNodes={currentNodeHierarchy}
              setExpandedNodes={setExpandedNodes}
              expandedNodes={expandedNodes}
              setActiveNode={setActiveNode}
              navigationNodes={navigationNodes}
              setNavigationNodes={setNavigationNodes}
              activeNode={activeNode}
              node={child}
              highLightNode={currentNodeExpanded || isHighlightedNodes}
              key={`${node.title}-${index}`}
            />
          );
        })}
    </>
  );
};

export default ArticleNavigationNode;
