import { AutocompleteResultData, AutocompleteSuggestionData } from '@api/generated/storefront';
import Button from '@atoms/Button/Button';
import {
  StyledScrollWrapper,
  StyledSuggestions,
} from '@organisms/SearchAsYouType/SearchAsYouTypeResults/SearchTermSuggestions/SearchTermSuggestions.styles';
import { trackSearchTermSuggestionClick } from '@helpers/analyticsHelpers/trackSearchAsYouType';

interface SearchTermSuggestionsProps {
  suggestions: AutocompleteResultData;
  searchTerm: string;
  onSuggestionClick: (suggestion: AutocompleteSuggestionData) => void;
}
const SearchTermSuggestions = ({ suggestions, searchTerm, onSuggestionClick }: SearchTermSuggestionsProps) => {
  const cleanedSuggestions = suggestions.suggestions?.filter(
    (suggestion) => suggestion.term?.toLowerCase() !== searchTerm.toLowerCase()
  );

  const handleSuggestionClick = (suggestion: AutocompleteSuggestionData) => {
    trackSearchTermSuggestionClick(suggestion.term || '');
    onSuggestionClick(suggestion);
  };

  return cleanedSuggestions && cleanedSuggestions.length > 0 ? (
    <StyledSuggestions>
      <StyledScrollWrapper>
        {cleanedSuggestions.map((suggestion) => (
          <Button theme="tile" onClick={() => handleSuggestionClick(suggestion)} key={suggestion.term}>
            {suggestion.term}
          </Button>
        ))}
      </StyledScrollWrapper>
    </StyledSuggestions>
  ) : null;
};

export default SearchTermSuggestions;
