/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Address } from '../cms-dto';
// @ts-ignore
import { AddressList } from '../cms-dto';
// @ts-ignore
import { AddressValidation } from '../cms-dto';
// @ts-ignore
import { PaymentDetails } from '../cms-dto';
// @ts-ignore
import { PaymentDetailsList } from '../cms-dto';
// @ts-ignore
import { User } from '../cms-dto';
// @ts-ignore
import { UserGroupList } from '../cms-dto';
// @ts-ignore
import { UserSignUp } from '../cms-dto';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes a customer\'s login name. Requires the customer\'s current password.
         * @summary Changes customer\'s login name.
         * @param {string} newLogin Customer\&#39;s new login name. Customer login is case insensitive.
         * @param {string} password Customer\&#39;s current password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoginUsingPUT: async (newLogin: string, password: string, baseSiteId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newLogin' is not null or undefined
            assertParamExists('changeLoginUsingPUT', 'newLogin', newLogin)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changeLoginUsingPUT', 'password', password)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('changeLoginUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeLoginUsingPUT', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/login`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (newLogin !== undefined) {
                localVarQueryParameter['newLogin'] = newLogin;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes customer\'s password.
         * @summary Changes customer\'s password
         * @param {string} _new New password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [old] Old password. Required only for ROLE_CUSTOMERGROUP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPUT: async (_new: string, baseSiteId: string, userId: string, old?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter '_new' is not null or undefined
            assertParamExists('changePasswordUsingPUT', '_new', _new)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('changePasswordUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changePasswordUsingPUT', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/password`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (old !== undefined) {
                localVarQueryParameter['old'] = old;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new address.
         * @summary Creates a new address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressUsingPOST: async (baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('createAddressUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createAddressUsingPOST', 'userId', userId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('createAddressUsingPOST', 'address', address)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes customer profile.
         * @summary Delete customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserUsingDELETE: async (baseSiteId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('deactivateUserUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deactivateUserUsingDELETE', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes customer\'s address.
         * @summary Delete customer\'s address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE: async (addressId: string, baseSiteId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('deleteAddressUsingDELETE', 'addressId', addressId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('deleteAddressUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteAddressUsingDELETE', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a customer\'s credit card payment details based on a specified paymentDetailsId.
         * @summary Delete customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentInfoUsingDELETE: async (paymentDetailsId: string, baseSiteId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetailsId' is not null or undefined
            assertParamExists('deletePaymentInfoUsingDELETE', 'paymentDetailsId', paymentDetailsId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('deletePaymentInfoUsingDELETE', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deletePaymentInfoUsingDELETE', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}`
                .replace(`{${"paymentDetailsId"}}`, encodeURIComponent(String(paymentDetailsId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns detailed information about address with a given id.
         * @summary Get info about address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressUsingGET: async (addressId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('getAddressUsingGET', 'addressId', addressId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getAddressUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAddressUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns customer\'s addresses.
         * @summary Get customer\'s addresses
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressesUsingGET: async (baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getAddressesUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAddressesUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all customer groups of a customer.
         * @summary Get all customer groups of a customer.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerGroupsForCustomerUsingGET: async (baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getAllCustomerGroupsForCustomerUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllCustomerGroupsForCustomerUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/customergroups`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a customer\'s credit card payment details for the specified paymentDetailsId.
         * @summary Get customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDetailsUsingGET: async (paymentDetailsId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetailsId' is not null or undefined
            assertParamExists('getPaymentDetailsUsingGET', 'paymentDetailsId', paymentDetailsId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPaymentDetailsUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPaymentDetailsUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}`
                .replace(`{${"paymentDetailsId"}}`, encodeURIComponent(String(paymentDetailsId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return customer\'s credit card payment details list.
         * @summary Get customer\'s credit card payment details list.
         * @param {boolean} saved Type of payment details.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfosUsingGET: async (saved: boolean, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saved' is not null or undefined
            assertParamExists('getPaymentInfosUsingGET', 'saved', saved)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPaymentInfosUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPaymentInfosUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/paymentdetails`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (saved !== undefined) {
                localVarQueryParameter['saved'] = saved;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns customer profile.
         * @summary Get customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET: async (baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getUserUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the address. Only attributes provided in the request body will be changed.
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAddressUsingPATCH: async (addressId: string, baseSiteId: string, userId: string, address: Address, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('patchAddressUsingPATCH', 'addressId', addressId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('patchAddressUsingPATCH', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchAddressUsingPATCH', 'userId', userId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('patchAddressUsingPATCH', 'address', address)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the address. Attributes not provided in the request will be defined again (set to null or default).
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAddressUsingPUT: async (addressId: string, baseSiteId: string, userId: string, address: Address, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('putAddressUsingPUT', 'addressId', addressId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('putAddressUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putAddressUsingPUT', 'userId', userId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('putAddressUsingPUT', 'address', address)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates existing customer\'s credit card payment info based on the payment info ID. Attributes not given in request will be defined again (set to null or default).
         * @summary Updates existing customer\'s credit card payment info.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPaymentInfoUsingPUT1: async (paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetailsId' is not null or undefined
            assertParamExists('putPaymentInfoUsingPUT1', 'paymentDetailsId', paymentDetailsId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('putPaymentInfoUsingPUT1', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putPaymentInfoUsingPUT1', 'userId', userId)
            // verify required parameter 'paymentDetails' is not null or undefined
            assertParamExists('putPaymentInfoUsingPUT1', 'paymentDetails', paymentDetails)
            const localVarPath = `/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}`
                .replace(`{${"paymentDetailsId"}}`, encodeURIComponent(String(paymentDetailsId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates customer profile. Attributes not provided in the request body will be defined again (set to null or default).
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsingPUT: async (baseSiteId: string, userId: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('putUserUsingPUT', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUserUsingPUT', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('putUserUsingPUT', 'user', user)
            const localVarPath = `/{baseSiteId}/users/{userId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a customer. There are two options for registering a customer. The first option requires the following parameters: login, password, firstName, lastName, titleCode. The second option converts a guest to a customer. In this case, the required parameters are: guid, password.
         * @summary  Registers a customer
         * @param {string} baseSiteId Base site identifier
         * @param {UserSignUp} user User\&#39;s object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST1: async (baseSiteId: string, user: UserSignUp, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('registerUserUsingPOST1', 'baseSiteId', baseSiteId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('registerUserUsingPOST1', 'user', user)
            const localVarPath = `/{baseSiteId}/users`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing customer\'s credit card payment details based on the specified paymentDetailsId. Only those attributes provided in the request will be updated.
         * @summary Updates existing customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentInfoUsingPATCH: async (paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetailsId' is not null or undefined
            assertParamExists('updatePaymentInfoUsingPATCH', 'paymentDetailsId', paymentDetailsId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('updatePaymentInfoUsingPATCH', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePaymentInfoUsingPATCH', 'userId', userId)
            // verify required parameter 'paymentDetails' is not null or undefined
            assertParamExists('updatePaymentInfoUsingPATCH', 'paymentDetails', paymentDetails)
            const localVarPath = `/{baseSiteId}/users/{userId}/paymentdetails/{paymentDetailsId}`
                .replace(`{${"paymentDetailsId"}}`, encodeURIComponent(String(paymentDetailsId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates customer profile. Only attributes provided in the request body will be changed.
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPATCH1: async (baseSiteId: string, userId: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('updateUserUsingPATCH1', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserUsingPATCH1', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUserUsingPATCH1', 'user', user)
            const localVarPath = `/{baseSiteId}/users/{userId}`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies address.
         * @summary Verifies address
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAddressUsingPOST1: async (baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('verifyAddressUsingPOST1', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('verifyAddressUsingPOST1', 'userId', userId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('verifyAddressUsingPOST1', 'address', address)
            const localVarPath = `/{baseSiteId}/users/{userId}/addresses/verification`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Changes a customer\'s login name. Requires the customer\'s current password.
         * @summary Changes customer\'s login name.
         * @param {string} newLogin Customer\&#39;s new login name. Customer login is case insensitive.
         * @param {string} password Customer\&#39;s current password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLoginUsingPUT(newLogin: string, password: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLoginUsingPUT(newLogin, password, baseSiteId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes customer\'s password.
         * @summary Changes customer\'s password
         * @param {string} _new New password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [old] Old password. Required only for ROLE_CUSTOMERGROUP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPUT(_new: string, baseSiteId: string, userId: string, old?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPUT(_new, baseSiteId, userId, old, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new address.
         * @summary Creates a new address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddressUsingPOST(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAddressUsingPOST(baseSiteId, userId, address, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes customer profile.
         * @summary Delete customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUserUsingDELETE(baseSiteId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateUserUsingDELETE(baseSiteId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes customer\'s address.
         * @summary Delete customer\'s address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressUsingDELETE(addressId: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddressUsingDELETE(addressId, baseSiteId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a customer\'s credit card payment details based on a specified paymentDetailsId.
         * @summary Delete customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentInfoUsingDELETE(paymentDetailsId: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentInfoUsingDELETE(paymentDetailsId, baseSiteId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns detailed information about address with a given id.
         * @summary Get info about address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressUsingGET(addressId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressUsingGET(addressId, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns customer\'s addresses.
         * @summary Get customer\'s addresses
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressesUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressesUsingGET(baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all customer groups of a customer.
         * @summary Get all customer groups of a customer.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomerGroupsForCustomerUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomerGroupsForCustomerUsingGET(baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a customer\'s credit card payment details for the specified paymentDetailsId.
         * @summary Get customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentDetailsUsingGET(paymentDetailsId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentDetailsUsingGET(paymentDetailsId, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return customer\'s credit card payment details list.
         * @summary Get customer\'s credit card payment details list.
         * @param {boolean} saved Type of payment details.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentInfosUsingGET(saved: boolean, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetailsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentInfosUsingGET(saved, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns customer profile.
         * @summary Get customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUsingGET(baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the address. Only attributes provided in the request body will be changed.
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAddressUsingPATCH(addressId: string, baseSiteId: string, userId: string, address: Address, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAddressUsingPATCH(addressId, baseSiteId, userId, address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the address. Attributes not provided in the request will be defined again (set to null or default).
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, address: Address, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAddressUsingPUT(addressId, baseSiteId, userId, address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates existing customer\'s credit card payment info based on the payment info ID. Attributes not given in request will be defined again (set to null or default).
         * @summary Updates existing customer\'s credit card payment info.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPaymentInfoUsingPUT1(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPaymentInfoUsingPUT1(paymentDetailsId, baseSiteId, userId, paymentDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates customer profile. Attributes not provided in the request body will be defined again (set to null or default).
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserUsingPUT(baseSiteId: string, userId: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserUsingPUT(baseSiteId, userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a customer. There are two options for registering a customer. The first option requires the following parameters: login, password, firstName, lastName, titleCode. The second option converts a guest to a customer. In this case, the required parameters are: guid, password.
         * @summary  Registers a customer
         * @param {string} baseSiteId Base site identifier
         * @param {UserSignUp} user User\&#39;s object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserUsingPOST1(baseSiteId: string, user: UserSignUp, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserUsingPOST1(baseSiteId, user, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing customer\'s credit card payment details based on the specified paymentDetailsId. Only those attributes provided in the request will be updated.
         * @summary Updates existing customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentInfoUsingPATCH(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentInfoUsingPATCH(paymentDetailsId, baseSiteId, userId, paymentDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates customer profile. Only attributes provided in the request body will be changed.
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUsingPATCH1(baseSiteId: string, userId: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUsingPATCH1(baseSiteId, userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifies address.
         * @summary Verifies address
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAddressUsingPOST1(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAddressUsingPOST1(baseSiteId, userId, address, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Changes a customer\'s login name. Requires the customer\'s current password.
         * @summary Changes customer\'s login name.
         * @param {string} newLogin Customer\&#39;s new login name. Customer login is case insensitive.
         * @param {string} password Customer\&#39;s current password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoginUsingPUT(newLogin: string, password: string, baseSiteId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeLoginUsingPUT(newLogin, password, baseSiteId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes customer\'s password.
         * @summary Changes customer\'s password
         * @param {string} _new New password.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [old] Old password. Required only for ROLE_CUSTOMERGROUP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPUT(_new: string, baseSiteId: string, userId: string, old?: string, options?: any): AxiosPromise<void> {
            return localVarFp.changePasswordUsingPUT(_new, baseSiteId, userId, old, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new address.
         * @summary Creates a new address.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressUsingPOST(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Address> {
            return localVarFp.createAddressUsingPOST(baseSiteId, userId, address, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes customer profile.
         * @summary Delete customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserUsingDELETE(baseSiteId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateUserUsingDELETE(baseSiteId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes customer\'s address.
         * @summary Delete customer\'s address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE(addressId: string, baseSiteId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAddressUsingDELETE(addressId, baseSiteId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a customer\'s credit card payment details based on a specified paymentDetailsId.
         * @summary Delete customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentInfoUsingDELETE(paymentDetailsId: string, baseSiteId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePaymentInfoUsingDELETE(paymentDetailsId, baseSiteId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns detailed information about address with a given id.
         * @summary Get info about address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressUsingGET(addressId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<Address> {
            return localVarFp.getAddressUsingGET(addressId, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns customer\'s addresses.
         * @summary Get customer\'s addresses
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressesUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<AddressList> {
            return localVarFp.getAddressesUsingGET(baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all customer groups of a customer.
         * @summary Get all customer groups of a customer.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerGroupsForCustomerUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<UserGroupList> {
            return localVarFp.getAllCustomerGroupsForCustomerUsingGET(baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a customer\'s credit card payment details for the specified paymentDetailsId.
         * @summary Get customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDetailsUsingGET(paymentDetailsId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PaymentDetails> {
            return localVarFp.getPaymentDetailsUsingGET(paymentDetailsId, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return customer\'s credit card payment details list.
         * @summary Get customer\'s credit card payment details list.
         * @param {boolean} saved Type of payment details.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfosUsingGET(saved: boolean, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PaymentDetailsList> {
            return localVarFp.getPaymentInfosUsingGET(saved, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns customer profile.
         * @summary Get customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<User> {
            return localVarFp.getUserUsingGET(baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the address. Only attributes provided in the request body will be changed.
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAddressUsingPATCH(addressId: string, baseSiteId: string, userId: string, address: Address, options?: any): AxiosPromise<void> {
            return localVarFp.patchAddressUsingPATCH(addressId, baseSiteId, userId, address, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the address. Attributes not provided in the request will be defined again (set to null or default).
         * @summary Updates the address
         * @param {string} addressId Address identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, address: Address, options?: any): AxiosPromise<void> {
            return localVarFp.putAddressUsingPUT(addressId, baseSiteId, userId, address, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates existing customer\'s credit card payment info based on the payment info ID. Attributes not given in request will be defined again (set to null or default).
         * @summary Updates existing customer\'s credit card payment info.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPaymentInfoUsingPUT1(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: any): AxiosPromise<void> {
            return localVarFp.putPaymentInfoUsingPUT1(paymentDetailsId, baseSiteId, userId, paymentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates customer profile. Attributes not provided in the request body will be defined again (set to null or default).
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsingPUT(baseSiteId: string, userId: string, user: User, options?: any): AxiosPromise<void> {
            return localVarFp.putUserUsingPUT(baseSiteId, userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a customer. There are two options for registering a customer. The first option requires the following parameters: login, password, firstName, lastName, titleCode. The second option converts a guest to a customer. In this case, the required parameters are: guid, password.
         * @summary  Registers a customer
         * @param {string} baseSiteId Base site identifier
         * @param {UserSignUp} user User\&#39;s object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST1(baseSiteId: string, user: UserSignUp, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<User> {
            return localVarFp.registerUserUsingPOST1(baseSiteId, user, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing customer\'s credit card payment details based on the specified paymentDetailsId. Only those attributes provided in the request will be updated.
         * @summary Updates existing customer\'s credit card payment details.
         * @param {string} paymentDetailsId Payment details identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {PaymentDetails} paymentDetails Payment details object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentInfoUsingPATCH(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: any): AxiosPromise<void> {
            return localVarFp.updatePaymentInfoUsingPATCH(paymentDetailsId, baseSiteId, userId, paymentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates customer profile. Only attributes provided in the request body will be changed.
         * @summary Updates customer profile
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {User} user User\&#39;s object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPATCH1(baseSiteId: string, userId: string, user: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserUsingPATCH1(baseSiteId, userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies address.
         * @summary Verifies address
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {Address} address Address object.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAddressUsingPOST1(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<AddressValidation> {
            return localVarFp.verifyAddressUsingPOST1(baseSiteId, userId, address, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Changes a customer\'s login name. Requires the customer\'s current password.
     * @summary Changes customer\'s login name.
     * @param {string} newLogin Customer\&#39;s new login name. Customer login is case insensitive.
     * @param {string} password Customer\&#39;s current password.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeLoginUsingPUT(newLogin: string, password: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeLoginUsingPUT(newLogin, password, baseSiteId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes customer\'s password.
     * @summary Changes customer\'s password
     * @param {string} _new New password.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [old] Old password. Required only for ROLE_CUSTOMERGROUP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePasswordUsingPUT(_new: string, baseSiteId: string, userId: string, old?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changePasswordUsingPUT(_new, baseSiteId, userId, old, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new address.
     * @summary Creates a new address.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {Address} address Address object.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createAddressUsingPOST(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createAddressUsingPOST(baseSiteId, userId, address, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes customer profile.
     * @summary Delete customer profile
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deactivateUserUsingDELETE(baseSiteId: string, userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deactivateUserUsingDELETE(baseSiteId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes customer\'s address.
     * @summary Delete customer\'s address
     * @param {string} addressId Address identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteAddressUsingDELETE(addressId: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteAddressUsingDELETE(addressId, baseSiteId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a customer\'s credit card payment details based on a specified paymentDetailsId.
     * @summary Delete customer\'s credit card payment details.
     * @param {string} paymentDetailsId Payment details identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deletePaymentInfoUsingDELETE(paymentDetailsId: string, baseSiteId: string, userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deletePaymentInfoUsingDELETE(paymentDetailsId, baseSiteId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns detailed information about address with a given id.
     * @summary Get info about address
     * @param {string} addressId Address identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAddressUsingGET(addressId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAddressUsingGET(addressId, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns customer\'s addresses.
     * @summary Get customer\'s addresses
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAddressesUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAddressesUsingGET(baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all customer groups of a customer.
     * @summary Get all customer groups of a customer.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllCustomerGroupsForCustomerUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllCustomerGroupsForCustomerUsingGET(baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a customer\'s credit card payment details for the specified paymentDetailsId.
     * @summary Get customer\'s credit card payment details.
     * @param {string} paymentDetailsId Payment details identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPaymentDetailsUsingGET(paymentDetailsId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPaymentDetailsUsingGET(paymentDetailsId, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return customer\'s credit card payment details list.
     * @summary Get customer\'s credit card payment details list.
     * @param {boolean} saved Type of payment details.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPaymentInfosUsingGET(saved: boolean, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPaymentInfosUsingGET(saved, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns customer profile.
     * @summary Get customer profile
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserUsingGET(baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserUsingGET(baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the address. Only attributes provided in the request body will be changed.
     * @summary Updates the address
     * @param {string} addressId Address identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {Address} address Address object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchAddressUsingPATCH(addressId: string, baseSiteId: string, userId: string, address: Address, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchAddressUsingPATCH(addressId, baseSiteId, userId, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the address. Attributes not provided in the request will be defined again (set to null or default).
     * @summary Updates the address
     * @param {string} addressId Address identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {Address} address Address object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putAddressUsingPUT(addressId: string, baseSiteId: string, userId: string, address: Address, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putAddressUsingPUT(addressId, baseSiteId, userId, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates existing customer\'s credit card payment info based on the payment info ID. Attributes not given in request will be defined again (set to null or default).
     * @summary Updates existing customer\'s credit card payment info.
     * @param {string} paymentDetailsId Payment details identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {PaymentDetails} paymentDetails Payment details object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putPaymentInfoUsingPUT1(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putPaymentInfoUsingPUT1(paymentDetailsId, baseSiteId, userId, paymentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates customer profile. Attributes not provided in the request body will be defined again (set to null or default).
     * @summary Updates customer profile
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {User} user User\&#39;s object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserUsingPUT(baseSiteId: string, userId: string, user: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUserUsingPUT(baseSiteId, userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a customer. There are two options for registering a customer. The first option requires the following parameters: login, password, firstName, lastName, titleCode. The second option converts a guest to a customer. In this case, the required parameters are: guid, password.
     * @summary  Registers a customer
     * @param {string} baseSiteId Base site identifier
     * @param {UserSignUp} user User\&#39;s object.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public registerUserUsingPOST1(baseSiteId: string, user: UserSignUp, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).registerUserUsingPOST1(baseSiteId, user, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing customer\'s credit card payment details based on the specified paymentDetailsId. Only those attributes provided in the request will be updated.
     * @summary Updates existing customer\'s credit card payment details.
     * @param {string} paymentDetailsId Payment details identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {PaymentDetails} paymentDetails Payment details object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updatePaymentInfoUsingPATCH(paymentDetailsId: string, baseSiteId: string, userId: string, paymentDetails: PaymentDetails, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updatePaymentInfoUsingPATCH(paymentDetailsId, baseSiteId, userId, paymentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates customer profile. Only attributes provided in the request body will be changed.
     * @summary Updates customer profile
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {User} user User\&#39;s object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserUsingPATCH1(baseSiteId: string, userId: string, user: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserUsingPATCH1(baseSiteId, userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies address.
     * @summary Verifies address
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {Address} address Address object.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public verifyAddressUsingPOST1(baseSiteId: string, userId: string, address: Address, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).verifyAddressUsingPOST1(baseSiteId, userId, address, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
