import HorizontalBannerComponent from '@molecules/HorizontalBannerComponent/HorizontalBannerComponent';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import { useImpressionTracker } from 'impression-tracker-react-hook';
import logger from '@logger';

interface Props {
  data: HorizontalBannerType;
  context: SlotContextType;
}

const TrackedHorizontalBannerComponent = ({ data, context }: Props) => {
  const logImpression = () => {
    pushGTMEcommerceEvent({
      event: EcommerceEvent.VIEW_PROMOTION,
      ecommerce: {
        promotion_id: data.name || 'hero',
        creative_name: 'hero',
        creative_slot: `${context.slotName} ${context.slotPosition}`,
      },
    });
  };

  const handleError = (error: Error) => {
    logger.error({ error: 'HorizontalBannerComponent tracking impression failed', message: error.message });
  };

  const onClick = () => {
    pushGTMEcommerceEvent({
      event: EcommerceEvent.SELECT_PROMOTION,
      ecommerce: {
        promotion_id: data.name || 'hero',
        creative_name: 'hero',
        creative_slot: `${context.slotName} ${context.slotPosition}`,
      },
    });
  };

  const [ref] = useImpressionTracker({ logImpression, handleError, contentId: data.name || 'hero' });

  return <HorizontalBannerComponent data={data} intersectionRef={ref} onClick={onClick} />;
};

export default TrackedHorizontalBannerComponent;
