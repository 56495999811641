'use client';
import { IDGenerator, SymplifySDK } from '@symplify-conversion/sst-sdk-nodejs/lib/client';
import { createContext, ReactNode, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useConfig from '@hooks/useConfig';

type SymplifyContextType = {
  sstInstance?: SymplifySDK;
};

export const SymplifyContext = createContext<SymplifyContextType>({});

interface SymplifyContextProviderProps {
  children: ReactNode;
}
const SymplifyContextProvider = ({ children }: SymplifyContextProviderProps) => {
  const { config } = useConfig();

  const contextValue = useMemo(() => {
    if (!config?.SYMPLIFY_WEBSITE_ID) return {} as SymplifyContextType;

    return {
      sstInstance: new SymplifySDK(config.SYMPLIFY_WEBSITE_ID, {
        idGenerator: (() =>
          typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function'
            ? crypto.randomUUID()
            : uuidv4()) as IDGenerator,
        log: undefined,
      }),
    };
  }, [config?.SYMPLIFY_WEBSITE_ID]);
  return <SymplifyContext.Provider value={contextValue}>{children}</SymplifyContext.Provider>;
};

export default SymplifyContextProvider;
