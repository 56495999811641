import styled from 'styled-components';
import Link from '@atoms/Link/Link';
import { rem } from 'polished';
import variables from '@styles/variables';

export const EnergyLabelContainer = styled.div`
  text-align: right;
`;

export const EnergyLabelImage = styled.img<{ size: 'small' | 'large'; isLink: boolean }>`
  display: block;
  width: ${(props) => (props.size === 'large' ? rem(111) : rem(73))};
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};
`;

export const EnergyLabelTextLabel = styled(Link)`
  margin-top: ${rem(2)};
  margin-right: ${rem(4)};
  display: block;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(11)};
  line-height: 1.45;
  letter-spacing: ${rem(-0.02)};
  text-decoration: none;

  && {
    color: ${variables.colors.lighterBlack};
  }
`;
