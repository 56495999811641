import React, { useEffect, useState } from 'react';
import Skeleton from '@components/atoms/Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { SkeletonWrapper } from './TwoTilesComponent.skeleton.styles';

const TwoTilesComponentSkeleton = () => {
  const [skeletonWidth, setSkeletonWidth] = useState('100%');
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  useEffect(() => {
    setSkeletonWidth(isMobile ? '100%' : '50%');
  }, [isMobile]);

  return (
    <SkeletonWrapper>
      <Skeleton type="rect" height={340} width={skeletonWidth} />
      <Skeleton type="rect" height={340} width={skeletonWidth} />
    </SkeletonWrapper>
  );
};

export default TwoTilesComponentSkeleton;
