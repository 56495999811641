import { WishListApi } from '@api/generated/storefront/storefront/wish-list-api';
import {
  AxfoodBasicWishListViewModel,
  AxfoodWishListViewModel,
  WishlistEntryForm,
  WishlistForm,
} from '@api/generated/storefront/dto';
import { AxiosRequestConfig } from 'axios';
import { WishListSortOrder } from '@molecules/WishList/WishList';

const wishlistApi = new WishListApi(undefined, '');

export enum WishlistsSortOrder {
  LAST_UPDATED_DESC = 'LAST_UPDATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export const getUserWishlists = async (sorting = WishlistsSortOrder.LAST_UPDATED_DESC) => {
  const response = await wishlistApi.getUserWishListsUsingGET(true, sorting);
  return response.data.filter(hasNameAndId);
};

export const createNewWishList = (form: WishlistForm, options?: AxiosRequestConfig) =>
  wishlistApi.createWishlistUsingPOST(form, options);

export const getUserWishList = (id: string, options?: AxiosRequestConfig) =>
  wishlistApi.getUserWishlistUsingGET(id, options);

export const updateWishList = (id: string, wishListForm: WishlistForm, options?: AxiosRequestConfig) =>
  wishlistApi.updateWishlistUsingPOST(id, wishListForm, options);

export const wishListAddToCart = (id: string, wishListForm: WishlistForm, options?: AxiosRequestConfig) =>
  wishlistApi.addProductsToCartUsingPOST(id, wishListForm, options);

export const deleteWishList = (id: string, options?: AxiosRequestConfig) =>
  wishlistApi.deleteUserWishListUsingDELETE(id, options);

export const deleteMultipleWishLists = (wishListIds: string[], options?: AxiosRequestConfig) =>
  wishlistApi.deleteUserWishListsUsingDELETE(wishListIds, options);

export const updateWishListSorting = (id: string, sorting: WishListSortOrder, options?: AxiosRequestConfig) =>
  wishlistApi.updateWishlistSortingUsingPUT(id, { sorting }, options);

export const checkEntriesInWishList = (id: string, wishlistForm: WishlistForm) =>
  wishlistApi.checkEntriesInUserWishlistUsingPUT(id, wishlistForm);

export const getWishListEntriesStatus = (wishlistForm: WishlistForm, options?: AxiosRequestConfig) =>
  wishlistApi.validateWishlistEntriesUsingPOST(wishlistForm, options);

export const removeEntriesFromWishList = (id: string, identifiers: string[], options?: AxiosRequestConfig) =>
  wishlistApi.removeEntriesFromUserWishlistUsingDELETE(id, identifiers, options);

export const getListStatus = (listID: string) => wishlistApi.validateOrderEntriesUsingGET1(listID);

export const addToUserWishlist = async (id: string, productData: Array<WishlistEntryForm>) => {
  const form = makeForm(productData);
  const response = await wishlistApi.addProductsToUserWishlistUsingPOST(id, form);
  return response.data;
};

export interface AxfoodBasicWishListViewModelWithIdAndName extends AxfoodBasicWishListViewModel {
  id: string;
  name: string;
}

export interface AxfoodWishListViewModelWithIdAndName extends AxfoodWishListViewModel {
  id: string;
  name: string;
}

const hasNameAndId = (wishlist: AxfoodBasicWishListViewModel): wishlist is AxfoodBasicWishListViewModelWithIdAndName =>
  wishlist.hasOwnProperty('name') && wishlist.hasOwnProperty('id');

const isAxfoodWishListViewModelWithIdAndName = (
  wishlist: AxfoodWishListViewModel
): wishlist is AxfoodWishListViewModelWithIdAndName => {
  return wishlist.hasOwnProperty('name') && wishlist.hasOwnProperty('id');
};

const makeForm = (productData: Array<WishlistEntryForm>, listName?: string) => {
  const entries = productData.map((p) => {
    const { entryType, pickUnit, productCode, promotionCode, quantity } = p;
    if (entryType === 'PROMOTION') {
      return { entryType, pickUnit, productCode, promotionCode, quantity };
    }
    return { entryType, pickUnit, productCode, quantity };
  });
  return listName ? { name: listName, entries } : { entries };
};

export const createUserWishlist = async (listName: string, productData: Array<WishlistEntryForm>) => {
  const form = makeForm(productData, listName);
  const response = await wishlistApi.createWishlistUsingPOST(form);
  if (isAxfoodWishListViewModelWithIdAndName(response.data)) {
    return response.data;
  }
  return {
    id: '',
    name: '',
  };
};
