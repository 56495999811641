import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';
import { StyledLogoLink, StyledWillysLogo } from '@atoms/Logo/Logo.styles';
import { ComponentWsDTO, ContentSlotWsDTO } from '@api/generated/cms';
import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';

interface Props {
  slot: ContentSlotWsDTOWithComponent;
}

interface UrlComponentWsDTO extends ComponentWsDTO {
  urlLink: string;
}

const Logo = ({ slot }: Props) => {
  const components = slot.component;

  return (
    <>
      {components && components.length > 0 && (
        <div>
          {components
            .filter((slot): slot is UrlComponentWsDTO => 'urlLink' in slot)
            .map((component) => (
              <StyledLogoLink
                href={component.urlLink}
                key={component.uid}
                onClick={() => trackLinkNavigation('header', component.name)}
              >
                <StyledWillysLogo name="WillysLogo" title="Willys" />
              </StyledLogoLink>
            ))}
        </div>
      )}
    </>
  );
};

export default Logo;
