import { useState } from 'react';
import useSWR from 'swr';
import { getUserWishlists, WishlistsSortOrder } from '@api/interfaces/wishlistApi';
import useCustomer from '@hooks/useCustomer';

type KeyType = {
  sortOrder: WishlistsSortOrder;
};

const fetcher = async ({ sortOrder }: KeyType) => {
  return await getUserWishlists(sortOrder);
};

const useWishlists = (customerId?: string) => {
  const [sortOrder, setSortOrder] = useState(WishlistsSortOrder.LAST_UPDATED_DESC);
  const { customer } = useCustomer();

  const key = { endpoint: '/axfood/rest/user-wishlist', sortOrder, customerId };
  const shouldFetch = Boolean(customer);
  const { error, data, isLoading, mutate: refreshWishlists } = useSWR(shouldFetch ? key : null, fetcher);

  return {
    wishlists: data,
    isLoading,
    updateSortOrder: (sortOrder: WishlistsSortOrder) => {
      setSortOrder(sortOrder);
      refreshWishlists();
    },
    refreshWishlists,
    error,
  };
};

export default useWishlists;
