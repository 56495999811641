import { CancelToken } from 'axios';
import { ProductApi } from '@api/generated/storefront';

const productApi = new ProductApi(undefined, '');

export const getSearchResultsAsYouType = (query: string, page: number, size: number) =>
  productApi.getSearchResultsAsYouTypeUsingGET(query, page, size);

export const getSearchResults = async (
  query: string,
  page: number,
  size: number,
  show?: 'Page' | 'All',
  sort?: string
) => {
  const response = await productApi.getSearchResultsUsingGET1(query, page, size, show, sort);
  return response.data;
};

export const getSuggestedSearchTerms = (query: string) =>
  productApi.getAutocompleteSuggestionsUsingGET('SearchBox', query);

export const getSearchCleanResults = (q: string, page: number, size: number) =>
  productApi.getSearchCleanResultsUsingGET(q, page, size);

export const getProduct = async (productCode: string, cancelToken?: CancelToken) => {
  const response = await productApi.getProductUsingGET1(productCode, {
    cancelToken,
  });
  return response.data;
};

export const getPromotionProduct = async (storeId: string, promotionCode: string, cancelToken?: CancelToken) => {
  const response = await productApi.getProductUsingGET(storeId, promotionCode, { cancelToken });
  return response.data;
};
