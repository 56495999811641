import { createGlobalStyle, css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledHotJarStyleChanger = createGlobalStyle<{ hide: boolean }>`
  ._hj-widget-container {
    ${Config.MEDIA.IS_MOBILE} {
      position: absolute !important;
      // Z-index should be below toolbar, since a lot of modals originate from toolbar
      z-index: ${variables.zIndex.toolbar - 1} !important;
    }
    
    div[class*="__styles__surveyContainer"] {
      ${(props) =>
        props.hide &&
        css`
          display: none !important;
        `}
      
      button[class*="__styles__openStateButtonToggle"] {
        align-items: center !important;
        background-color: ${variables.colors.lighterBlack} !important;
        width: ${rem(68)} !important;
        justify-content: center !important;
        padding: 0 !important;
        height: ${rem(32)} !important;
        
        span[class*="__styles__buttonIconContainer"] {
          display: none !important;
        }
        
        span {
          font-family: ${variables.fonts.fontGothamMedium} !important;
          font-size: ${rem(13)} !important;
        }
      }
      
    }
  }
`;
