import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Head from 'next/head';
import type { PageWithSlots } from '@api/interfaces/pageApi';

interface Props {
  cmsPage?: PageWithSlots;
}

const CMSHead = ({ cmsPage }: Props) => {
  const { t } = useTranslation('metadata');
  const title = `${cmsPage?.title ? cmsPage.title : t('metadata->title')} ${t('metadata->titleSuffix')}`;
  const description = `${cmsPage?.description ? cmsPage.description : t('metadata->description')}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Head>
  );
};

export default CMSHead;
