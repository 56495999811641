import { ContentSlotWsDTO, PageAdaptedData, PageApi } from '@api/generated/cms';
import Config from '@config';
import { AxiosRequestConfig } from 'axios';
import { getSlotsForPage } from '@helpers/cmsTemplateHelper';

export interface Params {
  api: PageApi;
  label?: string;
  cmsTicketId?: string;
  pageType?: string;
  config?: AxiosRequestConfig;
  code?: string;
}

type PageAdaptedDataWithoutContentSlots = Omit<PageAdaptedData, 'contentSlots'>;

export interface PageWithSlots extends PageAdaptedDataWithoutContentSlots {
  slots: ContentSlotWsDTO[];
  catalogVersionUuid?: string;
}

type PageType = 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage';

function narrowPageType(pageType: string) {
  const validPageTypes = ['ContentPage', 'ProductPage', 'CategoryPage'];
  if (validPageTypes.includes(pageType)) {
    return pageType as PageType;
  } else {
    throw new Error('Invalid page type');
  }
}

export const getPage = async ({ label, cmsTicketId, pageType = 'ContentPage', code = '', config, api }: Params) => {
  const response = await api.getPage(
    Config.SITE_NAME,
    narrowPageType(pageType),
    label,
    code,
    'DEFAULT',
    cmsTicketId,
    config
  );

  const filteredSlots = getSlotsForPage(response.data);
  const partialData = response.data as Partial<PageAdaptedData>;
  delete partialData.contentSlots;

  return {
    ...partialData,
    breadcrumbs: response.data.breadcrumbs,
    slots: filteredSlots,
  } as PageWithSlots;
};
