import { useEffect, useState } from 'react';
import Modal from '@organisms/Modal/Modal';
import Heading from '@atoms/Heading/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useDispatch } from 'react-redux';
import deleteCard from '@api/deleteCard';
import axios from 'axios';
import webToAppApi from '@api/web-to-app';
import logger from '@logger';
import trackCardPaymentOption, { CardPaymentOption } from '@helpers/analyticsHelpers/trackCardPaymentOption';
import { isAxiosError } from 'axios';
import { setSelectedCard } from '@slices/checkoutSlice';
import useCustomer from '@hooks/useCustomer';

interface Props {
  cardId: string;
  setCardId: (cardId: string) => void;
}

const DeleteCardModal = ({ cardId, setCardId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modal');
  const source = axios.CancelToken.source();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refreshCustomer } = useCustomer();

  const onClose = () => {
    source.cancel();
    setCardId('');
  };

  const onConfirm = async () => {
    try {
      setIsSubmitting(true);
      const customerResp = await deleteCard(cardId, source.token);
      refreshCustomer(customerResp.data);
      webToAppApi.eventCreditCardUpdate();
      setCardId('');
      dispatch(setSelectedCard({}));
      trackCardPaymentOption(CardPaymentOption.REMOVE);
    } catch (e) {
      if (isAxiosError(e)) {
        logger.error({ error: `Could not delete card with id ${cardId}`, message: e.message });
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cardId && (
        <Modal
          size="small"
          onClose={onClose}
          onConfirm={onConfirm}
          isSubmitting={isSubmitting}
          closeOnEscape
          cancelButtonText={t('modal->cancel')}
          confirmButtonText={t('modal->deleteCard->confirm')}
          buttonWidth={100}
        >
          <Heading variant="h3">{t('modal->deleteCard->title')}</Heading>
        </Modal>
      )}
    </>
  );
};

export default DeleteCardModal;
