import {
  StyledContentWrapper,
  StyledGotoAllProductsBtn,
  StyledNoResultsText,
  StyledResultColumn,
  StyledResultsList,
  StyledResultsListHeading,
  StyledSearchAsYouTypeResults,
  StyledSearchTermHeading,
} from './SearchAsYouTypeResults.styles';
import {
  AutocompleteResultData,
  AutocompleteSuggestionData,
  AxfoodBasicProductViewModel,
  AxfoodProductDetailsViewModel,
  ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel,
} from '@api/generated/storefront';
import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import SearchAsYouTypeEditorialSuggestion from './SearchAsYouTypeEditorialSuggestion/SearchAsYouTypeEditorialSuggestion';
import SearchTermSuggestions from '@organisms/SearchAsYouType/SearchAsYouTypeResults/SearchTermSuggestions/SearchTermSuggestions';
import CategorySuggestions from '@organisms/SearchAsYouType/SearchAsYouTypeResults/CategorySuggestions/CategorySuggestions';
import useResponsive from '@hooks/useResponsive';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import { setToolbarSearchIsExpanded } from '@slices/toolbarSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useEffect } from 'react';
import {
  trackEmptyResultShown,
  trackSuggestionGoToAllProductsClick,
} from '@helpers/analyticsHelpers/trackSearchAsYouType';
import TrackedComponent from '@organisms/TrackedComponent/TrackedComponent';
import ProductListItem from '@molecules/Autocomplete/ListItems/SearchAsYouTypeListItem';
import useProductImpressionTracking from '@hooks/useProductImpressionsTracking';

export type SearchAsYouTypeResultsProps = {
  usedQuery: string;
  userInputQuery: string;
  results: ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel;
  suggestions: AutocompleteResultData;
  maxResults: number;
  onSuggestionClick: (suggestion: AutocompleteSuggestionData) => void;
  onProductClick: (product: AxfoodBasicProductViewModel) => void;
};

const getHeadingTextToken = (count?: number) => {
  if (count === 1) {
    return 'singleResultText';
  } else {
    return 'multipleResultsText';
  }
};

const SearchAsYouTypeResults = ({
  results,
  suggestions,
  maxResults,
  onSuggestionClick,
  onProductClick,
  usedQuery,
  userInputQuery,
}: SearchAsYouTypeResultsProps) => {
  const { t } = useTranslation('search');
  const { fromDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const { onProductShown } = useProductImpressionTracking(`search_as_you_type | ${usedQuery}`);
  const emptyResultsFragment = (results: ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel) => {
    if (results.customSuggestion) {
      return <SearchAsYouTypeEditorialSuggestion suggestion={results.customSuggestion} searchTerm={usedQuery} />;
    } else {
      return (
        <StyledNoResultsText type="body" align="center">
          {t('asYouType->noResultsText')}
        </StyledNoResultsText>
      );
    }
  };

  useEffect(() => {
    if (!results.results) {
      trackEmptyResultShown(usedQuery ?? '');
    }
  }, [results.results, usedQuery]);

  return (
    <StyledSearchAsYouTypeResults data-testid="searchAsYouTypeResult">
      {suggestions.suggestions && suggestions.suggestions.length > 0 && (
        <SearchTermSuggestions
          suggestions={suggestions}
          searchTerm={usedQuery ?? ''}
          onSuggestionClick={onSuggestionClick}
        />
      )}
      <StyledSearchTermHeading variant="h3" center={!results.results}>
        {t('asYouType->usedQueryHeading', { usedQuery })}
      </StyledSearchTermHeading>

      {results.results ? (
        <>
          <StyledResultsListHeading>
            <Text type="body">
              {t(`asYouType->${getHeadingTextToken(results.results?.length)}`, {
                count: results.results?.length,
                total: results.pagination?.totalNumberOfResults,
              })}
            </Text>

            {(results.pagination?.totalNumberOfResults ?? 0) >= maxResults && (
              <Link
                href={`${paths.SEARCH}?q=${encodeURIComponent(usedQuery)}&userInputQuery=${encodeURIComponent(
                  userInputQuery
                )}`}
                data-testid="show-all-link"
              >
                <Button
                  theme="link"
                  onClick={() => {
                    dispatch(setToolbarSearchIsExpanded(false));
                    if (usedQuery) {
                      trackSuggestionGoToAllProductsClick(usedQuery);
                    }
                  }}
                >
                  {t('asYouType->showAllLinkText')}
                </Button>
              </Link>
            )}
          </StyledResultsListHeading>

          <StyledContentWrapper>
            <StyledResultColumn>
              <StyledResultsList>
                {results.results?.map((product, i) => {
                  return (
                    <TrackedComponent
                      callback={() => onProductShown(product as AxfoodProductDetailsViewModel, i)}
                      index={i}
                      product={product as AxfoodProductDetailsViewModel}
                      key={`search_as_you_type-${product.code}`}
                    >
                      <ProductListItem product={product} onProductClick={onProductClick} searchTerm={usedQuery} />
                    </TrackedComponent>
                  );
                })}
              </StyledResultsList>

              {(results.pagination?.totalNumberOfResults ?? 0) >= maxResults && (
                <StyledGotoAllProductsBtn
                  href={`${paths.SEARCH}?q=${encodeURIComponent(usedQuery)}&userInputQuery=${encodeURIComponent(
                    userInputQuery
                  )}`}
                >
                  <Button
                    theme="primary"
                    onClick={() => {
                      dispatch(setToolbarSearchIsExpanded(false));
                      if (usedQuery) {
                        trackSuggestionGoToAllProductsClick(usedQuery);
                      }
                    }}
                  >
                    {t('asYouType->gotoAllProductsLinkText')}
                  </Button>
                </StyledGotoAllProductsBtn>
              )}
            </StyledResultColumn>

            {fromDesktop && <CategorySuggestions navigationCategories={results.navigationCategories ?? []} />}
          </StyledContentWrapper>
        </>
      ) : (
        emptyResultsFragment(results)
      )}
    </StyledSearchAsYouTypeResults>
  );
};

export default SearchAsYouTypeResults;
