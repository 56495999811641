import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import UserAgent, { NavigatorPropKeys, RouterPropKeys } from '@helpers/useragent';

export type UserAgentReturnType = Record<NavigatorPropKeys | RouterPropKeys, boolean>;

const initialContext = {};

export const UserAgentContext = React.createContext<Partial<UserAgentReturnType>>(initialContext);

export interface UserAgentProvider {
  children: React.ReactChild;
}

export const UserAgentProvider = ({ children }: UserAgentProvider) => {
  const [props, setProps] = useState<Partial<UserAgentReturnType>>({});
  const router = useRouter();

  useEffect(() => {
    setProps((prevState) => {
      return {
        ...prevState,
        ...Object.entries(UserAgent).reduce((obj, [key, value]) => {
          // The Ecommerce helpers are skipped here to be handled on the second
          // useEffect to update on every router?.query?.viewconfig change.
          if (key.includes('Ecommerce')) return obj;
          return {
            ...obj,
            [key]: value(),
          };
        }, {}),
      };
    });
  }, []);

  useEffect(() => {
    setProps((prevState) => {
      return {
        ...prevState,
        isEcommerce: UserAgent.isEcommerce(router),
        isNativeAndEcommerce: UserAgent.isNativeAndEcommerce(router),
        isWebOrNativeAndEcommerce: UserAgent.isWebOrNativeAndEcommerce(router),
      };
    });
  }, [router?.query?.viewconfig]);

  return <UserAgentContext.Provider value={props}>{children}</UserAgentContext.Provider>;
};

export default () => useContext(UserAgentContext);
