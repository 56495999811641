import { Key, CSSProperties } from 'react';
import Link from '@atoms/Link/Link';
import { UrlObject } from 'url';

interface Props {
  title: string;
  onClick: (tabKey: Key) => void;
  className?: string;
  tabkey: Key;
  styling?: CSSProperties | undefined;
  routeTo?: string | UrlObject;
  routeAs?: string | UrlObject;
  replace: boolean;
}

const Tab = ({ title, onClick, className, tabkey, styling, routeTo, routeAs, replace = false }: Props) => {
  return routeTo !== undefined ? (
    <Link
      internal
      replace={replace}
      asProp={routeAs}
      href={routeTo}
      customStyle={styling}
      className={className}
      onClick={() => onClick(tabkey)}
      scroll={false}
    >
      {title}
    </Link>
  ) : (
    <button style={styling} type="button" onClick={() => onClick(tabkey)} className={className}>
      {title}
    </button>
  );
};

export default Tab;
