/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProductList } from '../cms-dto';
/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used for product export. Depending on the timestamp parameter, it can return all products or only products modified after the given time.
         * @summary Get a list of product exports.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [catalog] The catalog to retrieve products from. The catalog must be provided along with the version.
         * @param {string} [version] The catalog version. The catalog version must be provided along with the catalog.
         * @param {string} [timestamp] When this parameter is set, only products modified after the given time will be returned. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsUsingGET: async (baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, catalog?: string, version?: string, timestamp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('exportProductsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/export/products`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (catalog !== undefined) {
                localVarQueryParameter['catalog'] = catalog;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * Used for product export. Depending on the timestamp parameter, it can return all products or only products modified after the given time.
         * @summary Get a list of product exports.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [catalog] The catalog to retrieve products from. The catalog must be provided along with the version.
         * @param {string} [version] The catalog version. The catalog version must be provided along with the catalog.
         * @param {string} [timestamp] When this parameter is set, only products modified after the given time will be returned. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProductsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, catalog?: string, version?: string, timestamp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportProductsUsingGET(baseSiteId, fields, currentPage, pageSize, catalog, version, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * Used for product export. Depending on the timestamp parameter, it can return all products or only products modified after the given time.
         * @summary Get a list of product exports.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [catalog] The catalog to retrieve products from. The catalog must be provided along with the version.
         * @param {string} [version] The catalog version. The catalog version must be provided along with the catalog.
         * @param {string} [timestamp] When this parameter is set, only products modified after the given time will be returned. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, catalog?: string, version?: string, timestamp?: string, options?: any): AxiosPromise<ProductList> {
            return localVarFp.exportProductsUsingGET(baseSiteId, fields, currentPage, pageSize, catalog, version, timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * Used for product export. Depending on the timestamp parameter, it can return all products or only products modified after the given time.
     * @summary Get a list of product exports.
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {number} [currentPage] The current result page requested.
     * @param {number} [pageSize] The number of results returned per page.
     * @param {string} [catalog] The catalog to retrieve products from. The catalog must be provided along with the version.
     * @param {string} [version] The catalog version. The catalog version must be provided along with the catalog.
     * @param {string} [timestamp] When this parameter is set, only products modified after the given time will be returned. This parameter should be in ISO-8601 format (for example, 2018-01-09T16:28:45+0000).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportProductsUsingGET(baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, catalog?: string, version?: string, timestamp?: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportProductsUsingGET(baseSiteId, fields, currentPage, pageSize, catalog, version, timestamp, options).then((request) => request(this.axios, this.basePath));
    }
}
