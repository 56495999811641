import axios, { AxiosResponse } from 'axios';
import { AxfoodCartViewModel } from '@api/generated/storefront';

const postReplaceEko = (replaceEko: boolean): Promise<AxiosResponse<AxfoodCartViewModel>> => {
  const API_URL = `/axfood/rest/cart/update-ekoreplacement`;
  const data = new FormData();
  data.append('replaceEko', replaceEko.toString());
  return axios.post(API_URL, data);
};

export default postReplaceEko;
