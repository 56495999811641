import Carousel from '@molecules/Carousel/Carousel';
import { StyledProductDummy } from '@molecules/MixMatchBeam/MixMatchBeam.styles';
import MultiSearchNoResult from '@organisms/MultiSearchComponent/MultiSearchNoResult';
import ProductBeamSkeleton from '@molecules/ProductBeam/ProductBeam.skeleton';
import { useEffect, useRef, useState } from 'react';
import { getSearchCleanResults } from '@api/interfaces/productApi';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { MULTI_SEARCH } from '@organisms/MultiSearchComponent/MultiSearchComponent';
import KEYS from '@helpers/keys';
import { trackEditWithNoResults, trackNoResults } from '@helpers/analyticsHelpers/trackMultiSearch';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import TrackedComponent from '@organisms/TrackedComponent/TrackedComponent';
import Product from '@molecules/Product/Product';
import useProductImpressionTracking from '@hooks/useProductImpressionsTracking';

interface Props {
  searchWord: string;
  searchWordsArray: string[];
  setSearchWordsArray: (searchWordsArray: string[]) => void;
  index: number;
}

const MultiSearchResult = ({ searchWord, searchWordsArray, setSearchWordsArray, index }: Props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [productBeam, setProductBeam] = useState<any>({});
  const [tempSearchValue, setTempSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const ref = useRef<HTMLDivElement>(null);

  const { onProductShown } = useProductImpressionTracking('multisearch');

  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });
  const fromTablet = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_PORTRAIT,
  });
  const fromTabletLandscape = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE,
  });
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });
  const fromDesktopLarge = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_LARGE,
  });
  const fromXLargeDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_XLARGE,
  });

  const getAmountOfItemsPerSlide = () => {
    if (fromXLargeDesktop) return 7;
    if (fromDesktopLarge) {
      if (sideMenuIsOpen && isCartPreviewOpen) return 4;
      if (sideMenuIsOpen || isCartPreviewOpen) return 5;
      return 6;
    }
    if (fromDesktop) {
      if (sideMenuIsOpen && isCartPreviewOpen) return 3;
      if (sideMenuIsOpen || isCartPreviewOpen) return 4;
      return 5;
    }

    if (fromTabletLandscape) return 4;
    if (fromTablet) return 3;
    return 2;
  };

  const getProductElements = (items: Array<AxfoodProductDetailsViewModel>) =>
    items.map((product, i) => (
      <TrackedComponent
        index={i}
        product={product}
        key={`multisearch-${product.code}`}
        callback={() => onProductShown(product, i, `multisearch | ${productBeam?.freeTextSearch}`)}
      >
        <Product product={product} variant="multisearch" category={`multisearch | ${productBeam.freeTextSearch}`} />
      </TrackedComponent>
    ));

  const fetchProducts = async (searchWord: string, page: number) => {
    let foundProducts = false;
    const productResponse = await getSearchCleanResults(
      searchWord,
      page,
      getAmountOfItemsPerSlide() * MULTI_SEARCH.AMOUNT_OF_SLIDES_FIRST_LOAD
    );
    if (productResponse.data.results !== null) foundProducts = true;

    setProductBeam(productResponse.data);
    setIsFetching(false);

    return foundProducts;
  };

  const noResultOnFocusHandler = (e: any) => {
    setTempSearchValue(e.currentTarget.textContent);
  };

  const noResultOnBlurHandler = async (e: any) => {
    const textContent = e?.currentTarget?.textContent;
    const foundProducts = await fetchProducts(textContent, 0);
    if (foundProducts) {
      const tempWordArray = searchWordsArray;
      const foundWord = tempWordArray.find((word) => word.includes(tempSearchValue));
      let wordIndex = 0;
      if (foundWord != null) wordIndex = tempWordArray.indexOf(foundWord);
      if (wordIndex > 0) tempWordArray[wordIndex] = textContent;
      setSearchWordsArray(tempWordArray);
    } else {
      trackEditWithNoResults(textContent);
    }
  };

  const noResultOnKeyDownHandler = (e: any) => {
    switch (e.key) {
      case KEYS.ENTER:
      case KEYS.ESCAPE:
        e.preventDefault();
        return noResultOnBlurHandler(e);
      default:
        return;
    }
  };

  useEffect(() => {
    fetchProducts(searchWord, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord]);

  useEffect(() => {
    if (isFirstMount && productBeam?.results === null) {
      trackNoResults(productBeam.freeTextSearch);
      setIsFirstMount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBeam]);

  useEffect(() => {
    if (index === 0 && isMobile) {
      if (ref?.current) ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref}>
      {isFetching && <ProductBeamSkeleton />}

      {!isFetching && productBeam?.results?.length > 0 && (
        <Carousel
          elements={getProductElements(productBeam.results)}
          elementsPerSlide={getAmountOfItemsPerSlide()}
          elementDummy={<StyledProductDummy />}
          variant="multisearch"
          title={productBeam.freeTextSearch}
          key={`${productBeam.freeTextSearch}`}
          fetchMore={() => fetchProducts(productBeam.freeTextSearch, currentPage + 1)}
          pages={productBeam.pages}
        />
      )}
      {!isFetching && productBeam?.results === null && (
        <MultiSearchNoResult
          noResult={productBeam}
          noResultOnFocusHandler={noResultOnFocusHandler}
          noResultOnBlurHandler={noResultOnBlurHandler}
          noResultOnKeyDownHandler={noResultOnKeyDownHandler}
          key={`${productBeam.freeTextSearch}`}
        />
      )}
    </div>
  );
};

export default MultiSearchResult;
