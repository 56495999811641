import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { StyledBreadcrumb } from './Breadcrumbs.styles';
import paths from '@constants/paths';
import useConfig from '@hooks/useConfig';
import { AxfoodBreadCrumbsViewModel } from '@api/generated/storefront';

export interface UrlAxfoodBreadCrumbsViewModel extends AxfoodBreadCrumbsViewModel {
  pageUrl?: string;
}
import AccordionRightSmall from '@icons/accordion-right_small.svg';
import Icon from '@atoms/Icon/Icon';
interface Props {
  breadcrumbs: UrlAxfoodBreadCrumbsViewModel[];
}

const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const { t } = useTranslation('breadcrumbs');
  const { config } = useConfig();

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    name: 'Product Breadcrumbs',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: t('home'),
        item: config?.API_URL,
      },
      ...(breadcrumbs || []).map((child, i) => ({
        '@type': 'ListItem',
        position: i + 2,
        name: child.name,
        item: `${config?.API_URL}${paths.CATEGORY}/${child.url}`,
      })),
    ],
  };

  const buildBreadcrumb = (data: UrlAxfoodBreadCrumbsViewModel, i: number) => {
    if (data.url || data.pageUrl) {
      const url = data.url ? `${paths.CATEGORY}/${data.url}` : data.pageUrl || '';
      return (
        <StyledBreadcrumb internal href={url} key={url} prefetch={false}>
          <span>{data.name}</span>
          {i + 1 < breadcrumbs.length && <Icon svg={AccordionRightSmall} size={8} color="lighterBlack" />}
        </StyledBreadcrumb>
      );
    }
    return (
      <StyledBreadcrumb as="span" key={data.name} aria-current="page">
        {data.name}
        {i + 1 < breadcrumbs.length && <Icon svg={AccordionRightSmall} size={8} color="lighterBlack" />}
      </StyledBreadcrumb>
    );
  };

  return (
    <>
      <Head>
        <script type="application/ld+json" key="breadcrumbs">
          {JSON.stringify(structuredData)}
        </script>
      </Head>
      <StyledBreadcrumb href="/" internal>
        <span>{t('home')}</span>
        {breadcrumbs.length > 0 && <Icon svg={AccordionRightSmall} size={8} color="lighterBlack" />}
      </StyledBreadcrumb>
      {breadcrumbs?.map(buildBreadcrumb)}
    </>
  );
};

export default Breadcrumbs;
