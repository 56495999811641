import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
import { defaultPreviewDateRange } from '@pages/erbjudanden/PromotionPage';

const dateFormat = 'yyyy-MM-dd';
export const formatDate = (date: Date | number = new Date()) => format(date, dateFormat, { locale: sv });
export const createDateObj = (from: Date, to: Date) => ({
  from: formatDate(from),
  to: formatDate(to),
});

export const parseDateQuery = (query: NodeJS.Dict<string | string[]>) =>
  query && query.from && query.to
    ? { from: query.from as string, to: query.to as string }
    : createDateObj(defaultPreviewDateRange.from, defaultPreviewDateRange.to);
