import axios, { AxiosResponse, CancelToken } from 'axios';
import {
  AxfoodPaginationViewModelOfAxfoodBasicProductViewModel,
  AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel,
} from '@api/generated/storefront';

interface GetMarketingProductsParams {
  productCode: string;
  variant: string;
  page: number;
  pageSize: number;
}

const getMarketingProducts = (
  data: GetMarketingProductsParams,
  cancelToken: CancelToken
): Promise<AxiosResponse<AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel>> => {
  const { productCode, variant, page, pageSize } = data;
  const API_URL = `/axfood/rest/products/${productCode}/${variant}`;

  return axios.get(API_URL, {
    params: {
      page,
      pageSize,
    },
    cancelToken,
  });
};

export default getMarketingProducts;
