import Text from '@atoms/Text/Text';
import { StyledAccordionButton, StyledAccordionButtonIcon } from './Accordion.styles';
import AccordionDownSmall from '@icons/accordion-down_small.svg';

export type AccordionButtonProps = {
  title: string;
  onClick: (accordionKey: string) => void;
  accordionKey: string;
  active: boolean;
};

const AccordionButton = ({ title, onClick, accordionKey, active }: AccordionButtonProps) => {
  return (
    <StyledAccordionButton active={active} type="button" onClick={() => onClick(accordionKey)}>
      <Text type="body" color="black">
        {title}
      </Text>
      <StyledAccordionButtonIcon svg={AccordionDownSmall} size={12} active={active} />
    </StyledAccordionButton>
  );
};

export default AccordionButton;
