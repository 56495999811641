import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Config from '@config';

export const StyledPaymentModes = styled.div`
  width: 100%;
  padding: ${rem(24)} ${rem(20)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    border: ${rem(1)} solid ${variables.colors.mediumGray};
    padding-bottom: ${rem(28)};
  }
`;

export const StyledDivider = styled.div<{ first: boolean }>`
  width: 100%;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(28)};
    margin-top: ${(props) => (props.first ? rem(20) : rem(28))};
  }
`;

export const StyledSubText = styled.span`
  display: block;
  margin-left: ${rem(14)};
`;

export const StyledPaymentModeDescription = styled(Text)<{ disabled?: boolean }>`
  margin-left: ${rem(14)};
  ${(props) =>
    props.disabled &&
    css`
      color: ${variables.colors.mediumGray} !important;
    `}
`;

export const StyledPaymentModesWrapper = styled.div<{ openOrder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${rem(20)};

  ${(props) =>
    props.openOrder &&
    css`
      &:not(:last-child) {
        margin-bottom: ${rem(20)};
      }
    `}
`;

export const StyledPaymentIconWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: ${rem(12)};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const StyledCardIconImg = styled.img`
  width: auto;
  height: ${rem(24)};
`;

export const StyledKlarnaIconImg = styled.img`
  width: auto;
  height: ${rem(32)};
`;
