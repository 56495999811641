import { StyledIframeResizer } from './FaqComponent.styles';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useConfig from '@hooks/useConfig';

const FaqComponent = () => {
  const router = useRouter();
  const [iframeUrl, setIframeUrl] = useState('');
  const { config } = useConfig();

  useEffect(() => {
    if (router.query?.imboxUrl) {
      setIframeUrl(router.query.imboxUrl as string);
    }
  }, [router.query]);

  return (
    <>
      {config?.IMBOX_URL && (
        <StyledIframeResizer
          src={config.IMBOX_URL + iframeUrl}
          title="Willys faq"
          style={{ width: '1px', minWidth: '100%' }}
        ></StyledIframeResizer>
      )}
    </>
  );
};

export default FaqComponent;
