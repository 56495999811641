/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PointOfService } from '../cms-dto';
// @ts-ignore
import { StoreFinderSearchPage } from '../cms-dto';
/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the number of all store locations that are near the location specified in a query, or based on latitude and longitude.
         * @summary Get a header with the number of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLocationSearchUsingHEAD: async (baseSiteId: string, query?: string, latitude?: number, longitude?: number, radius?: number, accuracy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('countLocationSearchUsingHEAD', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/stores`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (accuracy !== undefined) {
                localVarQueryParameter['accuracy'] = accuracy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns store location based on its unique name.
         * @summary Get a store location
         * @param {string} storeId Store identifier (currently store name)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationDetailsUsingGET: async (storeId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('locationDetailsUsingGET', 'storeId', storeId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('locationDetailsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all store locations that are near the location specified in a query or based on latitude and longitude.
         * @summary Get a list of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationSearchUsingGET: async (baseSiteId: string, query?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, sort?: string, radius?: number, accuracy?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('locationSearchUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/stores`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (accuracy !== undefined) {
                localVarQueryParameter['accuracy'] = accuracy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the number of all store locations that are near the location specified in a query, or based on latitude and longitude.
         * @summary Get a header with the number of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countLocationSearchUsingHEAD(baseSiteId: string, query?: string, latitude?: number, longitude?: number, radius?: number, accuracy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countLocationSearchUsingHEAD(baseSiteId, query, latitude, longitude, radius, accuracy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns store location based on its unique name.
         * @summary Get a store location
         * @param {string} storeId Store identifier (currently store name)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationDetailsUsingGET(storeId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationDetailsUsingGET(storeId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists all store locations that are near the location specified in a query or based on latitude and longitude.
         * @summary Get a list of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationSearchUsingGET(baseSiteId: string, query?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, sort?: string, radius?: number, accuracy?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreFinderSearchPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationSearchUsingGET(baseSiteId, query, latitude, longitude, currentPage, pageSize, sort, radius, accuracy, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * In the response header, the \"x-total-count\" indicates the number of all store locations that are near the location specified in a query, or based on latitude and longitude.
         * @summary Get a header with the number of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLocationSearchUsingHEAD(baseSiteId: string, query?: string, latitude?: number, longitude?: number, radius?: number, accuracy?: number, options?: any): AxiosPromise<void> {
            return localVarFp.countLocationSearchUsingHEAD(baseSiteId, query, latitude, longitude, radius, accuracy, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns store location based on its unique name.
         * @summary Get a store location
         * @param {string} storeId Store identifier (currently store name)
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationDetailsUsingGET(storeId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PointOfService> {
            return localVarFp.locationDetailsUsingGET(storeId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all store locations that are near the location specified in a query or based on latitude and longitude.
         * @summary Get a list of store locations
         * @param {string} baseSiteId Base site identifier
         * @param {string} [query] Location in natural language i.e. city or country.
         * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
         * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
         * @param {number} [currentPage] The current result page requested.
         * @param {number} [pageSize] The number of results returned per page.
         * @param {string} [sort] Sorting method applied to the return results.
         * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
         * @param {number} [accuracy] Accuracy in meters.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationSearchUsingGET(baseSiteId: string, query?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, sort?: string, radius?: number, accuracy?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<StoreFinderSearchPage> {
            return localVarFp.locationSearchUsingGET(baseSiteId, query, latitude, longitude, currentPage, pageSize, sort, radius, accuracy, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI {
    /**
     * In the response header, the \"x-total-count\" indicates the number of all store locations that are near the location specified in a query, or based on latitude and longitude.
     * @summary Get a header with the number of store locations
     * @param {string} baseSiteId Base site identifier
     * @param {string} [query] Location in natural language i.e. city or country.
     * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
     * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
     * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
     * @param {number} [accuracy] Accuracy in meters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public countLocationSearchUsingHEAD(baseSiteId: string, query?: string, latitude?: number, longitude?: number, radius?: number, accuracy?: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).countLocationSearchUsingHEAD(baseSiteId, query, latitude, longitude, radius, accuracy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns store location based on its unique name.
     * @summary Get a store location
     * @param {string} storeId Store identifier (currently store name)
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public locationDetailsUsingGET(storeId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).locationDetailsUsingGET(storeId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists all store locations that are near the location specified in a query or based on latitude and longitude.
     * @summary Get a list of store locations
     * @param {string} baseSiteId Base site identifier
     * @param {string} [query] Location in natural language i.e. city or country.
     * @param {number} [latitude] Coordinate that specifies the north-south position of a point on the Earth\&#39;s surface.
     * @param {number} [longitude] Coordinate that specifies the east-west position of a point on the Earth\&#39;s surface.
     * @param {number} [currentPage] The current result page requested.
     * @param {number} [pageSize] The number of results returned per page.
     * @param {string} [sort] Sorting method applied to the return results.
     * @param {number} [radius] Radius in meters. Max value: 40075000.0 (Earth\&#39;s perimeter).
     * @param {number} [accuracy] Accuracy in meters.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public locationSearchUsingGET(baseSiteId: string, query?: string, latitude?: number, longitude?: number, currentPage?: number, pageSize?: number, sort?: string, radius?: number, accuracy?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).locationSearchUsingGET(baseSiteId, query, latitude, longitude, currentPage, pageSize, sort, radius, accuracy, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
