import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface PromotionPageState {
  promotionStoreId: string | null;
}

const initialState: PromotionPageState = {
  promotionStoreId: null,
};

export const promotionPageSlice = createAppSlice({
  name: 'promotionPage',
  initialState,
  reducers: (create) => ({
    setPromotionPageStoreId: create.reducer((state, action: PayloadAction<string>) => {
      state.promotionStoreId = action.payload;
    }),
  }),
  selectors: {
    selectPromotionPage: (promotionPage) => promotionPage,
    selectPromotionStoreId: (promotionPage) => promotionPage.promotionStoreId,
  },
});

// Actions
export const { setPromotionPageStoreId } = promotionPageSlice.actions;

// Selectors
export const { selectPromotionPage, selectPromotionStoreId } = promotionPageSlice.selectors;
