import { PromotionApi } from '@api/generated/storefront/storefront/promotion-api';
import { AxiosRequestConfig } from 'axios';

const promotionApi = new PromotionApi(undefined, '');

export const getOfflinePreviewCampaigns = async (
  q?: string,
  fields?: Array<string>,
  page?: number,
  size?: number,
  dateFrom?: number,
  dateTo?: number,
  options?: AxiosRequestConfig
) => {
  const response = await promotionApi.getOfflineCampaignsPreviewUsingGET(
    q,
    fields,
    page,
    size,
    dateFrom,
    dateTo,
    options
  );
  return response.data;
};

export const getOfflineCampaigns = async (
  q: string,
  type?: string,
  page?: number,
  size?: number,
  options?: AxiosRequestConfig
) => {
  const response = await promotionApi.getOfflineCampaignsUsingGET(q, type, page, size, options);
  return response.data;
};

export const getOnlineCampaigns = async (
  q: string,
  type?: string,
  page?: number,
  size?: number,
  options?: AxiosRequestConfig
) => {
  const response = await promotionApi.getOnlineCampaignsUsingGET(q, type, page, size, options);
  return response.data;
};
