/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EnergyDeclaration } from './energy-declaration';
// May contain unused imports in some cases
// @ts-ignore
import { ImageData } from './image-data';
// May contain unused imports in some cases
// @ts-ignore
import { MediaContainerData } from './media-container-data';
// May contain unused imports in some cases
// @ts-ignore
import { PriceData } from './price-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionRestrictionData } from './promotion-restriction-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionThemeData } from './promotion-theme-data';

/**
 * 
 * @export
 * @interface PromotionData
 */
export interface PromotionData {
    /**
     * 
     * @type {boolean}
     * @memberof PromotionData
     */
    'applied'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'brands'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'campaign'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'campaignType'?: PromotionDataCampaignTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'cartLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'commercialName2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'commercialName3'?: string;
    /**
     * 
     * @type {PriceData}
     * @memberof PromotionData
     */
    'comparePrice'?: PriceData;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'comparisonPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'conditionLabel'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'couldFireMessages'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'description'?: string;
    /**
     * 
     * @type {PriceData}
     * @memberof PromotionData
     */
    'discountPrice'?: PriceData;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionData
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'endDate'?: number;
    /**
     * 
     * @type {EnergyDeclaration}
     * @memberof PromotionData
     */
    'energyDeclaration'?: EnergyDeclaration;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'firedMessages'?: Array<string>;
    /**
     * 
     * @type {Array<ImageData>}
     * @memberof PromotionData
     */
    'images'?: Array<ImageData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {PriceData}
     * @memberof PromotionData
     */
    'lowestHistoricalPrice'?: PriceData;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'mainProductCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'percentage'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'pointOfServices'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'priority'?: number;
    /**
     * 
     * @type {ImageData}
     * @memberof PromotionData
     */
    'productBanner'?: ImageData;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'productBasketType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionData
     */
    'productCodes'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'productPrice'?: number;
    /**
     * 
     * @type {Array<MediaContainerData>}
     * @memberof PromotionData
     */
    'promotionBanners'?: Array<MediaContainerData>;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'promotionGroup'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'promotionLastModifiedForImageUpdate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'promotionPrice'?: number;
    /**
     * 
     * @type {PromotionThemeData}
     * @memberof PromotionData
     */
    'promotionTheme'?: PromotionThemeData;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'promotionType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'qualifyingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'ranking'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionData
     */
    'realMixAndMatch'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'redeemLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'redeemLimitLabel'?: string;
    /**
     * 
     * @type {Array<PromotionRestrictionData>}
     * @memberof PromotionData
     */
    'restrictions'?: Array<PromotionRestrictionData>;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'rewardLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'savePrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'solrSearchScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'sorting'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'splashTitleText'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'startDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'textLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'textLabelGenerated'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'textLabelManual'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'timesUsed'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'totalSavedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'validFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionData
     */
    'validUntil'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionData
     */
    'weightVolume'?: string;
}

export const PromotionDataCampaignTypeEnum = {
    General: 'GENERAL',
    Loyalty: 'LOYALTY',
    Segmented: 'SEGMENTED'
} as const;

export type PromotionDataCampaignTypeEnum = typeof PromotionDataCampaignTypeEnum[keyof typeof PromotionDataCampaignTypeEnum];


