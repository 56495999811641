import { CartApi } from '@api/generated/storefront/storefront/cart-api';
import type { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import type { AddToCartForm, AxfoodCartEntryStatusViewModel, CustomerContactForm } from '@api/generated/storefront';

const cartApi = new CartApi(undefined, '');

export const restoreCart = (orderID: string) => {
  return cartApi.restoreCartAndCloseOpenOrdersUsingPOST(orderID);
};
export const addToCart = async (products: AddToCartForm[], storeId?: string) => {
  const response = await cartApi.addToCartUsingPOST({
    products,
    storeId,
  });
  return response.data;
};

// TODO: https://axfood.atlassian.net/browse/B2C-28347
export interface CartStatus extends AxfoodCartEntryStatusViewModel {
  cartStatus: AxfoodCartEntryStatusViewModel[];
  reason: string;
}
export const getCartStatus = (slotCode?: string, storeId?: string, cancelToken?: CancelToken) => {
  const response = cartApi.checkCartStatusUsingGET(slotCode, storeId, undefined, {
    cancelToken,
  }) as unknown as Promise<AxiosResponse<CartStatus>>;
  return response;
};

export const setDeliveryMode = (
  deliveryModeCode: 'homeDelivery' | 'pickUpInStore',
  newSuggestedStoreId?: string,
  options?: AxiosRequestConfig
) => cartApi.setDeliveryModeUsingPOST(deliveryModeCode, newSuggestedStoreId, options);

export const getPersistedCart = async () => {
  const response = await cartApi.getMergablePersistentCartUsingGET();
  return response.data;
};

export const getCart = async () => {
  const response = await cartApi.getCartUsingGET();
  return response.data;
};

export const setCartContactInfo = async (contactInfo: Partial<AddressType>) => {
  const response = await cartApi.setCustomerContactUsingPOST(contactInfo as CustomerContactForm);
  return response.data;
};
