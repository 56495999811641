import useTranslation from 'next-translate/useTranslation';

import { EnergyLabelTextLabel, EnergyLabelImage, EnergyLabelContainer } from '@atoms/EnergyLabel/EnergyLabel.styles';
import getEnergyDeclarationArrowImageUrl from '@helpers/getEnergyDeclarationArrowImageUrl';
import { EnergyDeclaration } from '@api/generated/storefront';

export interface EnergyLabelProps {
  energyDeclaration: EnergyDeclaration;
  onClick?: () => void;
  size?: 'large' | 'small';
}

const EnergyLabel = ({ energyDeclaration, onClick, size = 'small' }: EnergyLabelProps) => {
  const { t } = useTranslation('product');

  const imageUrl = getEnergyDeclarationArrowImageUrl(energyDeclaration.value, 'LEFT');
  if (!imageUrl) {
    return null;
  }

  return (
    <EnergyLabelContainer>
      <EnergyLabelImage
        data-testid="energy-label-image"
        src={imageUrl}
        size={size}
        onClick={onClick}
        isLink={!!onClick}
      />
      <EnergyLabelTextLabel external target="_blank" href={energyDeclaration.link as string}>
        {t('product->label->energy_label')}
      </EnergyLabelTextLabel>
    </EnergyLabelContainer>
  );
};

export default EnergyLabel;
