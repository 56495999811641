import { ReactNode, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import TwoTilesFoldoutComponent from '@molecules/TwoTilesFoldoutComponent/TwoTilesFoldoutComponent';
import GridRow from '@molecules/GridRow/GridRow';
import GridCol from '@molecules/GridCol/GridCol';
import Link from '@atoms/Link/Link';
import { useRouter } from 'next/router';
import useResponsiveCMSMedia from '@hooks/useResponsiveCMSMedia';
import useUserAgent from '@hooks/useUserAgent';
import Config from '@config';
import TwoTilesComponentSkeleton from '@molecules/TwoTilesComponent/TwoTilesComponent.skeleton';
import useShouldShowSkeleton from '@hooks/useShouldShowSkeleton';
import {
  StyledArrow,
  StyledButton,
  StyledHeading,
  StyledText,
  StyledTileWrapper,
  StyledTwoTilesWrapper,
} from './TwoTilesComponent.styles';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';

interface Props {
  data: TwoTilesComponentType;
  intersectionRef?: (node?: Element | null | undefined) => void;
  onClick?: () => void;
}

const TwoTilesComponent = ({ data, intersectionRef, onClick }: Props) => {
  const router = useRouter();
  const [hideInApp, setHideInApp] = useState(false);
  const [toggleProductList, setToggleProductList] = useState(false);
  const scrollAnchorRef = useRef<HTMLDivElement>(null);
  const { image } = useResponsiveCMSMedia({ media: data.media });
  const { showSkeleton } = useShouldShowSkeleton({ image });
  const { isNativeApp } = useUserAgent();
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);

  const { TOOLBAR_HEIGHT, MARGIN_HEIGHT } = {
    TOOLBAR_HEIGHT: 60,
    MARGIN_HEIGHT: 20,
  };
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  const onClickHandler = (actionType: TwoTilesActionType) => {
    if (actionType === 'PRODUCT_LIST') {
      setToggleProductList(!toggleProductList);
    }
  };

  const createImageTile = (imageUrl: string, imageAlignment: string) => {
    return (
      <StyledTileWrapper
        first={imageAlignment === 'LEFT'}
        clickable={!!data?.imageClickAction}
        onClick={() => onClickHandler(data?.imageClickAction)}
        image={imageUrl}
      />
    );
  };

  const tileButton = (
    <StyledButton
      backgroundColor={data?.buttonColor}
      textColor={data?.buttonTextColor}
      onClick={() => onClickHandler(data?.buttonAction)}
    >
      {data?.buttonText}
    </StyledButton>
  );

  const wrapWithLink = (content: ReactNode, actionType: TwoTilesActionType): ReactNode => {
    if (actionType === 'PRODUCT_DETAIL') {
      const query = {
        ...router.query,
        name: encodeURIComponent(data?.product?.url),
        productCode: data?.product?.code,
        showInModal: true,
      };
      return (
        <Link
          href={{ pathname: router.pathname, query }}
          asProp={{ pathname: data?.product?.url }}
          internal
          shallow
          scroll={false}
        >
          {content}
        </Link>
      );
    }
    return <Link href={data?.urlLink}>{content}</Link>;
  };

  useEffect(() => {
    if (isNativeApp === undefined) return;
    if (data.showInApp === 'false' && isNativeApp) {
      setHideInApp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNativeApp]);

  useEffect(() => {
    toggleProductList &&
      scrollAnchorRef.current &&
      window.scrollTo({
        top: scrollAnchorRef?.current?.offsetTop - (isMobile ? MARGIN_HEIGHT : TOOLBAR_HEIGHT + MARGIN_HEIGHT),
        behavior: 'smooth',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleProductList]);

  return (
    <div ref={intersectionRef}>
      {showSkeleton && <TwoTilesComponentSkeleton />}
      {!showSkeleton && data && !hideInApp && (
        <StyledTwoTilesWrapper onClick={onClick}>
          <GridRow noGap>
            <GridCol size={{ startRow: { s: 2, m: 1 }, spanCol: { s: 4, m: 6 } }}>
              <StyledTileWrapper
                backgroundColor={data?.backgroundColor}
                first={data.imageAlignment !== 'LEFT'}
                reducedPadding={isCartPreviewOpen && sideMenuIsOpen}
              >
                <StyledHeading textColor={data?.titleTextColor} variant="h2">
                  {data?.title}
                </StyledHeading>
                <StyledText textColor={data?.preambleTextColor} type="body">
                  {data?.preamble}
                </StyledText>
                {data?.buttonAction === 'URL_LINK' || data?.buttonAction === 'PRODUCT_DETAIL'
                  ? wrapWithLink(tileButton, data?.buttonAction)
                  : tileButton}
                {toggleProductList ? <StyledArrow /> : null}
              </StyledTileWrapper>
            </GridCol>
            <GridCol
              size={{
                startCol: { m: data.imageAlignment === 'LEFT' ? 1 : 7 },
                startRow: { s: 1 },
                spanCol: { s: 4, m: 6 },
              }}
            >
              {image.url && (data?.imageClickAction === 'URL_LINK' || data?.imageClickAction === 'PRODUCT_DETAIL')
                ? wrapWithLink(createImageTile(image.url, data.imageAlignment), data?.imageClickAction)
                : createImageTile(image.url, data.imageAlignment)}
            </GridCol>
          </GridRow>
          <div ref={scrollAnchorRef}>
            <TwoTilesFoldoutComponent data={data} show={toggleProductList} title={data.productListTitle} />
          </div>
        </StyledTwoTilesWrapper>
      )}
    </div>
  );
};

export default TwoTilesComponent;
