import React from 'react';
import Button from '@atoms/Button/Button';
import {
  StyledSubCategoryLink,
  StyledSubCategoryLinksWrapper,
} from '@molecules/SubCategoryLinks/SubCategoryLinks.styles';
import { CategoryData } from '@api/generated/storefront';

interface Props {
  categories: Array<CategoryData>;
}

const SubCategoryLinks = ({ categories }: Props) => {
  return (
    <>
      {categories.length > 0 && (
        <StyledSubCategoryLinksWrapper>
          {categories
            .filter((category): category is Required<CategoryData> => category.url !== undefined)
            .map((category) => (
              <StyledSubCategoryLink href={category.url} key={category.url}>
                <Button theme="tile" size="xsmall">
                  {category.name}
                </Button>
              </StyledSubCategoryLink>
            ))}
        </StyledSubCategoryLinksWrapper>
      )}
    </>
  );
};

export default SubCategoryLinks;
