import { createAppSlice } from '@slices/createAppSlice';
import type { CreditCardPaymentInfoData } from '@api/generated/storefront';
import { PossiblyWrongStoreConfirmedState } from '@molecules/CheckoutPaymentButton/CheckoutPaymentButton';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CheckoutState {
  delivery: {
    isCompleted: boolean;
    isEditing: boolean;
    isDisabled: boolean;
    isValid: boolean;
    errorMessage: string;
  };
  contactInfo: {
    isCompleted: boolean;
    isEditing: boolean;
    isDisabled: boolean;
  };
  payment: {
    isCompleted: boolean;
    isEditing: boolean;
    isDisabled: boolean;
    selectedCard: CreditCardPaymentInfoData;
    saveCard: boolean;
    selectedMode: PaymentType;
    possiblyWrongStoreConfirmed: PossiblyWrongStoreConfirmedState;
  };
}

export const initialState: CheckoutState = {
  delivery: {
    isCompleted: false,
    isEditing: false,
    isDisabled: false,
    isValid: true,
    errorMessage: '',
  },
  contactInfo: {
    isCompleted: false,
    isEditing: false,
    isDisabled: false,
  },
  payment: {
    isCompleted: false,
    isEditing: false,
    isDisabled: false,
    selectedCard: { cardType: '', defaultCard: false, expireDate: '', expired: false, id: '', maskedNumber: '' },
    saveCard: true,
    selectedMode: '',
    possiblyWrongStoreConfirmed: 0,
  },
};

export const checkoutSlice = createAppSlice({
  name: 'checkout',
  initialState,
  reducers: (create) => ({
    setCompleted: create.reducer(
      (state, action: PayloadAction<{ boxName: 'delivery' | 'contactInfo' | 'payment'; isCompleted: boolean }>) => {
        state[action.payload.boxName].isCompleted = action.payload.isCompleted;
      }
    ),
    setEditing: create.reducer(
      (state, action: PayloadAction<{ boxName: 'delivery' | 'contactInfo' | 'payment'; isEditing: boolean }>) => {
        state[action.payload.boxName].isEditing = action.payload.isEditing;
      }
    ),
    setValid: create.reducer((state, action: PayloadAction<boolean>) => {
      state['delivery'].isValid = action.payload;
    }),
    setDisabled: create.reducer(
      (state, action: PayloadAction<{ boxName: 'delivery' | 'contactInfo' | 'payment'; isDisabled: boolean }>) => {
        state[action.payload.boxName].isDisabled = action.payload.isDisabled;
      }
    ),
    setSelectedCard: create.reducer((state, action: PayloadAction<CreditCardPaymentInfoData>) => {
      state.payment.selectedCard = action.payload;
    }),
    setSaveCard: create.reducer((state, action: PayloadAction<boolean>) => {
      state.payment.saveCard = action.payload;
    }),
    setSelectedMode: create.reducer((state, action: PayloadAction<PaymentType>) => {
      state.payment.selectedMode = action.payload;
    }),
    setErrorMessage: create.reducer((state, action: PayloadAction<string>) => {
      state['delivery'].errorMessage = action.payload;
    }),
    setPossiblyWrongStoreConfirmed: create.reducer((state, action: PayloadAction<PossiblyWrongStoreConfirmedState>) => {
      state.payment.possiblyWrongStoreConfirmed = action.payload;
    }),
  }),
  selectors: {
    selectCheckout: (checkout) => checkout,
    selectCheckoutPayment: (checkout) => checkout.payment,
    selectPossiblyWrongStoreConfirmed: (checkout) => checkout.payment.possiblyWrongStoreConfirmed,
  },
});

// Actions
export const {
  setCompleted,
  setEditing,
  setValid,
  setDisabled,
  setSelectedCard,
  setSaveCard,
  setSelectedMode,
  setErrorMessage,
  setPossiblyWrongStoreConfirmed,
} = checkoutSlice.actions;

// Selectors
export const { selectCheckout, selectCheckoutPayment, selectPossiblyWrongStoreConfirmed } = checkoutSlice.selectors;
