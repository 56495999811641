import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { StyledFaqWidget } from './FaqWidget.styles';
import { trackFaqWidgetToggle } from '@helpers/analyticsHelpers/trackFaqWidget';
import CloseSmall from '@icons/close_small.svg';
import FaqWidgetIcon from '@icons/non-standard/FAQ-WIDGET-ICON.svg';
import Icon from '@atoms/Icon/Icon';

const amountOfRetries = 10;
const retryInterval = 100;

interface Props {
  hideWidget: boolean;
}

const FaqWidget = ({ hideWidget }: Props) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [imboxWidgetFetch, setImboxWidgetFetch] = useState(0);
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const isCartOpen = useAppSelector(selectMiniCartPreviewIsOpen);

  const onClickHandler = () => {
    window?._imbox?.push(['toggle']);
  };

  useEffect(() => {
    window?._imbox?.push([
      'onToggle',
      (e: any) => {
        setOpen(e.isOpen);
        trackFaqWidgetToggle(e.isOpen);
      },
    ]);
  }, []);

  const setVisibility = () => {
    if (hideWidget) setVisible(false);
    else setVisible(true);
  };

  useEffect(() => {
    if (widgetLoaded) {
      setVisibility();
      return;
    }
    if (imboxWidgetFetch !== amountOfRetries) {
      const imboxElement = document?.getElementsByClassName('imbox-show');
      if (imboxElement.length > 0) {
        window?._imbox?.push(['hideLauncher']);
        setVisibility();
        setWidgetLoaded(true);
      } else {
        setTimeout(() => {
          setImboxWidgetFetch(imboxWidgetFetch + 1);
        }, retryInterval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imboxWidgetFetch, hideWidget]);

  return (
    <StyledFaqWidget size="xlarge" color="white" cartIsOpen={isCartOpen} onClick={onClickHandler} show={visible}>
      {open ? <Icon svg={CloseSmall} size={16} color="white" /> : <Icon svg={FaqWidgetIcon} color="white" />}
    </StyledFaqWidget>
  );
};

export default FaqWidget;
