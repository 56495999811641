import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import { StyledGenericPersonalCard } from '@molecules/GenericPersonalCard/GenericPersonalCard.styles';

export const StyledPersonalElementsCard = styled(StyledGenericPersonalCard)<{ variant: string }>`
  ${(props) =>
    props.variant === 'voucher' &&
    css`
      background-color: ${variables.colors.plus};
    `}
`;

export const StyledTextAndIconWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
`;
export const StyledPrice = styled.span`
  color: ${variables.colors.primary};
`;

export const StyledTextWithMargin = styled(Text)`
  margin-bottom: ${rem(25)};
`;

export const StyledVoucherHeading = styled(Heading)`
  margin-bottom: ${rem(25)};
`;

export const StyledOrderHeading = styled(Heading)`
  margin-bottom: ${rem(12)};
`;

export const StyledText = styled(Text)`
  white-space: break-spaces;
`;
