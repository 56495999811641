import GridRow from '@molecules/GridRow/GridRow';
import Checkbox from '@atoms/Checkbox/Checkbox';
import DateRangePicker from '@molecules/DateRangePicker/DateRangePicker';
import { addMonths, subMonths } from 'date-fns';
import Heading from '@atoms/Heading/Heading';
import PromotionOffersFragment from '@molecules/PromotionOffersFragment/PromotionOffersFragment';
import Container from '@atoms/Container/Container';
import React from 'react';
import { DateRange } from 'react-day-picker';
import { createDateObj } from '@helpers/formatDate';
import useTranslation from 'next-translate/useTranslation';
import { defaultPreviewDateRange } from '@pages/erbjudanden/PromotionPage';
import {
  StyledEmptySearchMessageBox,
  StyledOffersPreviewWrapper,
  StyledPreviewSpinner,
  StyledPromtionPreviewGridCol,
  StyledPromtionPreviewHeading,
} from './PromotionPreviewContent.styles';
import useCustomRouter from '@hooks/useCustomRouter';
import { FacetSearchPageData } from '@api/generated/storefront';

interface Props {
  previewDateRange: { from: string; to: string };
  setPreviewDateRange: ({ from, to }: { from: string; to: string }) => void;
  showProductsWithoutImages: boolean;
  setShowProductsWithoutImages: (state: boolean) => void;
  isLoading: boolean;
  offersPreview: FacetSearchPageData;
  loadMore: (storeType: onlineOfflineType, track: boolean, reset?: boolean) => void;
}

const PromotionPreviewContent = ({
  previewDateRange,
  setPreviewDateRange,
  showProductsWithoutImages,
  setShowProductsWithoutImages,
  isLoading,
  offersPreview,
  loadMore,
}: Props) => {
  const { t } = useTranslation('promotionPage');
  const router = useCustomRouter();

  const onPreviewDateRangePickerChange = ({ from, to }: DateRange) => {
    if (from && to) {
      const range = createDateObj(from, to);
      if (previewDateRange.from !== range.from || previewDateRange.to !== range.to) {
        router.replace({ pathname: router.pathname, query: { ...router.query, from: range.from, to: range.to } });
        setPreviewDateRange(range);
      }
    }
  };

  return (
    <Container>
      <StyledPromtionPreviewHeading variant="h2">{t('preview->title')}</StyledPromtionPreviewHeading>
      <GridRow>
        <StyledPromtionPreviewGridCol size={{ spanCol: { s: 6, m: 4, l: 3 } }}>
          <Checkbox
            checked={showProductsWithoutImages}
            onChange={() => setShowProductsWithoutImages(!showProductsWithoutImages)}
            id="showProductsWithoutImagesCheckbox"
            testId="showProductsWithoutImagesCheckbox"
          >
            {t('preview->showProductsWithoutImages')}
          </Checkbox>
        </StyledPromtionPreviewGridCol>
        <StyledPromtionPreviewGridCol size={{ spanCol: { s: 6, m: 4, l: 3 } }}>
          <DateRangePicker
            onChange={onPreviewDateRangePickerChange}
            defaultRange={{ from: new Date(previewDateRange.from), to: new Date(previewDateRange.to) }}
            fromDate={subMonths(defaultPreviewDateRange.to, 13)}
            toDate={addMonths(defaultPreviewDateRange.to, 13)}
            align="left"
            testId="PromotionsPreviewDateRangePicker"
          />
          {isLoading && <StyledPreviewSpinner color="primary" />}
        </StyledPromtionPreviewGridCol>
      </GridRow>
      <StyledOffersPreviewWrapper>
        {offersPreview?.pagination?.totalNumberOfResults === 0 ? (
          <StyledEmptySearchMessageBox>
            {showProductsWithoutImages ? (
              <Heading variant="h3">{t('preview->noMatchesDateRangeAndFilter')}</Heading>
            ) : (
              <Heading variant="h3">{t('preview->noMatchesDateRange')}</Heading>
            )}
          </StyledEmptySearchMessageBox>
        ) : (
          <PromotionOffersFragment
            storeType="offline"
            generalCampaigns={offersPreview}
            isLoading={isLoading}
            loadMore={() => loadMore('offline', false, false)}
            mode="preview"
            showProgress
            isPreviewMode
          />
        )}
      </StyledOffersPreviewWrapper>
    </Container>
  );
};

export default PromotionPreviewContent;
