/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SaveCartResult } from '../cms-dto';
/**
 * SaveCartApi - axios parameter creator
 * @export
 */
export const SaveCartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Explicitly clones a cart.
         * @summary Explicitly clones a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [name] The name that should be applied to the cloned cart.
         * @param {string} [description] The description that should be applied to the cloned cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneSaveCartUsingPOST: async (cartId: string, baseSiteId: string, userId: string, name?: string, description?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cloneSaveCartUsingPOST', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('cloneSaveCartUsingPOST', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cloneSaveCartUsingPOST', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/clonesavedcart`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flags a cart for deletion (the cart doesn\'t have corresponding save cart attributes anymore). The cart is not actually deleted from the database. But with the removal of the saved cart attributes, this cart will be taken care of by the cart removal job just like any other cart.
         * @summary Flag a cart for deletion.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagForDeletionUsingPATCH: async (cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('flagForDeletionUsingPATCH', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('flagForDeletionUsingPATCH', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('flagForDeletionUsingPATCH', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/flagForDeletion`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a saved cart for an authenticated user. The cart is identified using the \"cartId\" parameter.
         * @summary Get a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedCartUsingGET: async (cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('getSavedCartUsingGET', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getSavedCartUsingGET', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSavedCartUsingGET', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/savedcart`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restore a saved cart.
         * @summary Restore a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreSavedCartUsingPATCH: async (cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('restoreSavedCartUsingPATCH', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('restoreSavedCartUsingPATCH', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('restoreSavedCartUsingPATCH', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/restoresavedcart`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Explicitly saves a cart.
         * @summary Explicitly saves a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [saveCartName] The name that should be applied to the saved cart.
         * @param {string} [saveCartDescription] The description that should be applied to the saved cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCartUsingPATCH: async (cartId: string, baseSiteId: string, userId: string, saveCartName?: string, saveCartDescription?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('saveCartUsingPATCH', 'cartId', cartId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('saveCartUsingPATCH', 'baseSiteId', baseSiteId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveCartUsingPATCH', 'userId', userId)
            const localVarPath = `/{baseSiteId}/users/{userId}/carts/{cartId}/save`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (saveCartName !== undefined) {
                localVarQueryParameter['saveCartName'] = saveCartName;
            }

            if (saveCartDescription !== undefined) {
                localVarQueryParameter['saveCartDescription'] = saveCartDescription;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaveCartApi - functional programming interface
 * @export
 */
export const SaveCartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaveCartApiAxiosParamCreator(configuration)
    return {
        /**
         * Explicitly clones a cart.
         * @summary Explicitly clones a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [name] The name that should be applied to the cloned cart.
         * @param {string} [description] The description that should be applied to the cloned cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneSaveCartUsingPOST(cartId: string, baseSiteId: string, userId: string, name?: string, description?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCartResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneSaveCartUsingPOST(cartId, baseSiteId, userId, name, description, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Flags a cart for deletion (the cart doesn\'t have corresponding save cart attributes anymore). The cart is not actually deleted from the database. But with the removal of the saved cart attributes, this cart will be taken care of by the cart removal job just like any other cart.
         * @summary Flag a cart for deletion.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flagForDeletionUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCartResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flagForDeletionUsingPATCH(cartId, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a saved cart for an authenticated user. The cart is identified using the \"cartId\" parameter.
         * @summary Get a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedCartUsingGET(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCartResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedCartUsingGET(cartId, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restore a saved cart.
         * @summary Restore a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreSavedCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCartResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreSavedCartUsingPATCH(cartId, baseSiteId, userId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Explicitly saves a cart.
         * @summary Explicitly saves a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [saveCartName] The name that should be applied to the saved cart.
         * @param {string} [saveCartDescription] The description that should be applied to the saved cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, saveCartName?: string, saveCartDescription?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCartResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCartUsingPATCH(cartId, baseSiteId, userId, saveCartName, saveCartDescription, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaveCartApi - factory interface
 * @export
 */
export const SaveCartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaveCartApiFp(configuration)
    return {
        /**
         * Explicitly clones a cart.
         * @summary Explicitly clones a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [name] The name that should be applied to the cloned cart.
         * @param {string} [description] The description that should be applied to the cloned cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneSaveCartUsingPOST(cartId: string, baseSiteId: string, userId: string, name?: string, description?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SaveCartResult> {
            return localVarFp.cloneSaveCartUsingPOST(cartId, baseSiteId, userId, name, description, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Flags a cart for deletion (the cart doesn\'t have corresponding save cart attributes anymore). The cart is not actually deleted from the database. But with the removal of the saved cart attributes, this cart will be taken care of by the cart removal job just like any other cart.
         * @summary Flag a cart for deletion.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flagForDeletionUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SaveCartResult> {
            return localVarFp.flagForDeletionUsingPATCH(cartId, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a saved cart for an authenticated user. The cart is identified using the \"cartId\" parameter.
         * @summary Get a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedCartUsingGET(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SaveCartResult> {
            return localVarFp.getSavedCartUsingGET(cartId, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Restore a saved cart.
         * @summary Restore a saved cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreSavedCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SaveCartResult> {
            return localVarFp.restoreSavedCartUsingPATCH(cartId, baseSiteId, userId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Explicitly saves a cart.
         * @summary Explicitly saves a cart.
         * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
         * @param {string} baseSiteId Base site identifier
         * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
         * @param {string} [saveCartName] The name that should be applied to the saved cart.
         * @param {string} [saveCartDescription] The description that should be applied to the saved cart.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, saveCartName?: string, saveCartDescription?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<SaveCartResult> {
            return localVarFp.saveCartUsingPATCH(cartId, baseSiteId, userId, saveCartName, saveCartDescription, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaveCartApi - object-oriented interface
 * @export
 * @class SaveCartApi
 * @extends {BaseAPI}
 */
export class SaveCartApi extends BaseAPI {
    /**
     * Explicitly clones a cart.
     * @summary Explicitly clones a cart.
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [name] The name that should be applied to the cloned cart.
     * @param {string} [description] The description that should be applied to the cloned cart.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveCartApi
     */
    public cloneSaveCartUsingPOST(cartId: string, baseSiteId: string, userId: string, name?: string, description?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return SaveCartApiFp(this.configuration).cloneSaveCartUsingPOST(cartId, baseSiteId, userId, name, description, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flags a cart for deletion (the cart doesn\'t have corresponding save cart attributes anymore). The cart is not actually deleted from the database. But with the removal of the saved cart attributes, this cart will be taken care of by the cart removal job just like any other cart.
     * @summary Flag a cart for deletion.
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveCartApi
     */
    public flagForDeletionUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return SaveCartApiFp(this.configuration).flagForDeletionUsingPATCH(cartId, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a saved cart for an authenticated user. The cart is identified using the \"cartId\" parameter.
     * @summary Get a saved cart.
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveCartApi
     */
    public getSavedCartUsingGET(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return SaveCartApiFp(this.configuration).getSavedCartUsingGET(cartId, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restore a saved cart.
     * @summary Restore a saved cart.
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveCartApi
     */
    public restoreSavedCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return SaveCartApiFp(this.configuration).restoreSavedCartUsingPATCH(cartId, baseSiteId, userId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Explicitly saves a cart.
     * @summary Explicitly saves a cart.
     * @param {string} cartId Cart identifier: cart code for logged in user, cart guid for anonymous user, \&#39;current\&#39; for the last modified cart
     * @param {string} baseSiteId Base site identifier
     * @param {string} userId User identifier or one of the literals : \&#39;current\&#39; for currently authenticated user, \&#39;anonymous\&#39; for anonymous user
     * @param {string} [saveCartName] The name that should be applied to the saved cart.
     * @param {string} [saveCartDescription] The description that should be applied to the saved cart.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveCartApi
     */
    public saveCartUsingPATCH(cartId: string, baseSiteId: string, userId: string, saveCartName?: string, saveCartDescription?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return SaveCartApiFp(this.configuration).saveCartUsingPATCH(cartId, baseSiteId, userId, saveCartName, saveCartDescription, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
