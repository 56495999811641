import useSWR from 'swr';
import { getCart } from '@api/interfaces/cartApi';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { setHasFetchedCart, setIsFetchingCart, setIsUpdatingCart } from '@slices/cartUpdatingSlice';
import useCustomer from '@hooks/useCustomer';

export const getCartEndpoint = '/api/cart';

const setFetchCartStatus = (status: boolean): any => {
  return (dispatch: Dispatch) => {
    dispatch(setIsFetchingCart(status));
    dispatch(setIsUpdatingCart(status));
  };
};

const useCart = () => {
  const dispatch = useAppDispatch();
  const { customer } = useCustomer();

  const key = {
    getCartEndpoint,
    customerId: customer?.uid,
  };
  const shouldFetch = Boolean(customer);
  const { data, error, mutate, isLoading } = useSWR(shouldFetch ? key : null, getCart);

  useEffect(() => {
    // Set fetching/fetched statuses
    // TODO: Refactor to use SWR's built-in loading state
    dispatch(setFetchCartStatus(isLoading));
    dispatch(setHasFetchedCart(Boolean(data && !isLoading)));
  }, [dispatch, data, isLoading]);

  return {
    cart: data,
    isCartEmpty: !data?.products?.length,
    error,
    isLoading,
    refreshCart: mutate,
  };
};

export default useCart;
