
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useSWR from 'swr';
import { getOfflineCampaigns, getOfflinePreviewCampaigns, getOnlineCampaigns } from '@api/interfaces/promotionApi';
import useCustomRouter from '@hooks/useCustomRouter';
import Config from '@config';
import useCustomer from '@hooks/useCustomer';
import { GENERAL_CAMPAIGNS, PERSONAL_CAMPAIGNS } from '@pages/erbjudanden/PromotionPage';
import { useAppSelector } from '@hooks/useAppDispatch';
import { parseDateQuery } from '@helpers/formatDate';
import paths from '@constants/paths';
import { selectPromotionStoreId } from '@slices/promotionPageSlice';
import useStore from '@hooks/useStore';
type keyType = {
    query: string;
    isAnonymous: boolean;
    storeType: string;
    size?: number;
    page?: number;
    dateFrom?: number;
    dateTo?: number;
    fields?: string[];
};
const fetcher = async ({ query, isAnonymous, storeType, size = 20, page = 0, dateFrom, dateTo, fields }: keyType) => {
    switch (storeType) {
        case 'preview/offline':
            return {
                personalCampaigns: null,
                generalCampaigns: await getOfflinePreviewCampaigns(query, fields, page, size, dateFrom, dateTo),
            };
        case 'online':
            return {
                personalCampaigns: !isAnonymous && page === 0 ? await getOnlineCampaigns(query, PERSONAL_CAMPAIGNS, page, size) : null,
                generalCampaigns: await getOnlineCampaigns(query, GENERAL_CAMPAIGNS, page, size),
            };
        case 'offline':
            return {
                personalCampaigns: !isAnonymous && page === 0 ? await getOfflineCampaigns(query, PERSONAL_CAMPAIGNS, page, size) : null,
                generalCampaigns: await getOfflineCampaigns(query, GENERAL_CAMPAIGNS, page, size),
            };
    }
};
const usePromotionCampaigns = (isPreview: boolean, page: number, showProducts: boolean) => {
    const router = useCustomRouter();
    const fields = showProducts ? 'IMAGE' : undefined;
    const { from: dateFrom, to: dateTo } = parseDateQuery(router.query);
    const { customer } = useCustomer();
    const { query } = router;
    const mode = query?.mode || query?.name;
    const isStoreMode = mode === Config.ROUTES.promotionpage.store || router.asPath.startsWith(paths.PDP_OFFLINE);
    const storeType = isPreview ? 'preview/offline' : isStoreMode ? 'offline' : 'online';
    const { store } = useStore();
    const promotionStoreId = useAppSelector(selectPromotionStoreId);
    let q = (isStoreMode ? promotionStoreId : store?.storeId) || '';
    if (query?.q?.length) {
        // Only append query delimiter if none exists
        if ((query.q as string).startsWith(':')) {
            q += query.q;
        }
        else {
            q += `:${query.q}`;
        }
    }
    const key = q ? { query: q, isAnonymous: customer?.isAnonymous, storeType, dateFrom, dateTo, fields, page } : null;
    const { data, error, isLoading } = useSWR(key, fetcher);
    return {
        personalCampaigns: data?.personalCampaigns,
        generalCampaigns: data?.generalCampaigns,
        isLoading,
        error,
    };
};
export default usePromotionCampaigns;

    async function __Next_Translate__getStaticProps__1917a0456fb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/erbjudanden/usePromotionCampaigns.ts',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1917a0456fb__ as getStaticProps }
  