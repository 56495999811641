import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface CartUpdatingStatus {
  isUpdating: boolean;
  isFetching: boolean;
  isSubmitting: boolean;
  editReplacement: boolean;
  hasFetchedCart: boolean;
}

const initialState: CartUpdatingStatus = {
  isUpdating: false,
  isFetching: false,
  isSubmitting: false,
  editReplacement: false,
  hasFetchedCart: false,
};

export const cartUpdatingSlice = createAppSlice({
  name: 'cartUpdating',
  initialState,
  reducers: (create) => ({
    setIsUpdatingCart: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    }),
    setIsFetchingCart: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    }),
    setSubmitCartStatus: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
      state.isUpdating = action.payload;
    }),
    setHasFetchedCart: create.reducer((state, action: PayloadAction<boolean>) => {
      state.hasFetchedCart = action.payload;
    }),
    setEditReplacement: create.reducer((state, action: PayloadAction<boolean>) => {
      state.editReplacement = action.payload;
    }),
  }),
  selectors: {
    selectCartUpdating: (cartUpdating) => cartUpdating,
    selectCartUpdateStatus: (cartUpdating) => cartUpdating,
    selectCartIsUpdating: (cartUpdating) => cartUpdating.isUpdating,
    selectCartUpdatingEditReplacement: (cartUpdating) => cartUpdating.editReplacement,
    selectEditReplacementBool: (cartUpdating) => Boolean(cartUpdating.editReplacement),
    selectCartIsFetching: (cartUpdating) => cartUpdating.isFetching,
    selectCartHasFetched: (cartUpdating) => cartUpdating.hasFetchedCart,
  },
});

// Selectors
export const {
  selectCartUpdating,
  selectCartUpdateStatus,
  selectCartIsUpdating,
  selectCartIsFetching,
  selectCartUpdatingEditReplacement,
  selectEditReplacementBool,
  selectCartHasFetched,
} = cartUpdatingSlice.selectors;

// Actions
export const { setIsUpdatingCart, setHasFetchedCart, setIsFetchingCart, setSubmitCartStatus, setEditReplacement } =
  cartUpdatingSlice.actions;

export default cartUpdatingSlice.reducer;
