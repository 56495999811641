import doNotTrackUrls from '@constants/doNotTrackUrls';
import * as track from '@helpers/analytics';
import { useEffect } from 'react';
import { initializeGTM } from '@helpers/analytics';
import smoothscroll from 'smoothscroll-polyfill';
import axfoodAppToWebAPI from '@api/appToWebApi';
import pushGTMEvent, { DeviceDataLayerEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import UserAgent from '@helpers/useragent';
import webToAppApi from '@api/web-to-app';
import useCustomRouter from '@hooks/useCustomRouter';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useCustomer from '@hooks/useCustomer';
import useConfig from '@hooks/useConfig';
import paths from '@constants/paths';
import { trackLoginSuccess } from '@helpers/analyticsHelpers/trackLogin';

const useTracking = () => {
  const dispatch = useAppDispatch();
  const router = useCustomRouter();
  const { customer } = useCustomer();
  const { config } = useConfig();
  const trackRouteChange = (url: string) => {
    if (doNotTrackUrls.indexOf(url) > -1) return;
    track.pageview(url, document.title);
  };

  const setupPageviewAnalytics = () => {
    router.events.on('routeChangeComplete', trackRouteChange);
    return () => {
      router.events.off('routeChangeComplete', trackRouteChange);
    };
  };

  useEffect(() => {
    config && initializeGTM(config.GTM_YYYYYY);
    smoothscroll.polyfill();
  }, [config]);

  // Tracking config
  useEffect(() => {
    // Initialize AppToWeb Bridge with access to the redux dispatch
    (window as any).axfoodAppToWebAPI = axfoodAppToWebAPI(dispatch);

    // Send current device (Web/App/AppWithConsent) to Google Tag Manager
    // for it to determine what scripts it should inject (OneTrust Cookie Consent Banner etc.)
    let device: DeviceDataLayerEvent['device'] = 'Web';
    if (UserAgent.isNativeApp()) {
      device = window.OTExternalConsent ? 'AppWithConsent' : 'App';
    }
    pushGTMEvent({
      event: 'device',
      device,
    });

    // Send event to app that the web has finished loading
    webToAppApi.eventAppReady(); // Will need to be triggered after device as been sent to GTM

    trackRouteChange(router.asPath);

    return setupPageviewAnalytics();
  }, []);

  useEffect(() => {
    // Do not track if the user is still on the login route, we have separate tracking for that in useLoginModal.tsx
    if (customer && !customer.isAnonymous && !router.asPath.startsWith(paths.USER_LOGIN)) {
      trackLoginSuccess(customer, true);
    }
  }, [customer]);
};

export default useTracking;
