import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { useEffect, useState } from 'react';

type backgroundImageType = {
  widescreen: imageMediaType;
  desktop: imageMediaType;
  tablet: imageMediaType;
  mobile: imageMediaType;
};

function useResponsiveCMSMedia(cmsData: {
  title?: string;
  titleTablet?: string;
  titleMobile?: string;
  media: responsiveImageMediaType;
}) {
  const fromTabletPortrait = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_PORTRAIT,
  });
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });
  const fromWidescreen = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_XLARGE,
  });

  const [data, setData] = useState<{ image: imageMediaType; title?: string }>({
    image: {
      altText: '',
      code: '',
      mime: '',
      url: '',
    },
    title: '',
  });

  const pickImage = (backgroundImages: backgroundImageType) => {
    if (fromWidescreen) {
      return (
        backgroundImages.widescreen || backgroundImages.desktop || backgroundImages.tablet || backgroundImages.mobile
      );
    }
    if (fromDesktop) {
      return (
        backgroundImages.desktop || backgroundImages.widescreen || backgroundImages.tablet || backgroundImages.mobile
      );
    }

    if (fromTabletPortrait) {
      return (
        backgroundImages.tablet || backgroundImages.desktop || backgroundImages.widescreen || backgroundImages.mobile
      );
    }

    return backgroundImages.mobile || backgroundImages.tablet || backgroundImages.desktop || backgroundImages.widescreen;
  };

  const backgroundImages = {
    mobile: cmsData?.media?.mobile,
    tablet: cmsData?.media?.tablet,
    desktop: cmsData?.media?.desktop,
    widescreen: cmsData?.media?.widescreen,
  };

  const getTitle = () => {
    if (fromDesktop) {
      return cmsData?.title;
    }
    if (fromTabletPortrait) {
      return cmsData?.titleTablet;
    }
    return cmsData?.titleMobile;
  };

  useEffect(() => {
    setData({ image: pickImage(backgroundImages), title: getTitle() });
  }, [fromWidescreen, fromDesktop, fromTabletPortrait]);

  return data;
}

export default useResponsiveCMSMedia;
