import { useEffect, useMemo, useState } from 'react';
import { getStoreInformation } from '@api/interfaces/storeApi';
import { setPromotionPageStoreId } from '@slices/promotionPageSlice';
import Config from '@config';
import { MAPPED_TABS } from '@pages/erbjudanden/PromotionPage';
import useCustomRouter from '@hooks/useCustomRouter';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
  getPromotionStoreId,
  getPromotionTab as getPromotionTabLocalStorage,
  setPromotionTab as setPromotionTabLocalStorage,
} from '@helpers/localStorageFacade';
import { AxfoodStoreInfoViewModel } from '@api/generated/storefront';
import paths from '@constants/paths';
import useStore from '@hooks/useStore';

const logToNR = (message: string) => {
  if (typeof window.newrelic !== 'undefined') {
    const { newrelic } = window;
    newrelic.noticeError(new Error(`PromotionPageRouting: ${message}`));
  }
};

interface Props {
  enableRouting: boolean;
}

const usePromotionPageRouting = ({ enableRouting }: Props) => {
  const router = useCustomRouter();
  const dispatch = useAppDispatch();
  const { store } = useStore();
  const [promotionTab, setPromotionTab] = useState(
    typeof window !== 'undefined' ? getPromotionTabLocalStorage() || 'ehandel' : ''
  );
  const isPreview = useMemo(() => router.pathname.indexOf(paths.PDP_PREVIEW) !== -1, [router.pathname]);
  const [activeTab, setActiveTab] = useState<PromotionTabType>(
    () => (router.query.mode || router.query.name) as PromotionTabType
  );
  const [selectedOfflineStore, setSelectedOfflineStore] = useState({} as AxfoodStoreInfoViewModel);

  const updatePromotionTab = (promotionTab: string) => {
    setPromotionTabLocalStorage(promotionTab);
    setPromotionTab(promotionTab);
  };

  useEffect(() => {
    if (
      enableRouting &&
      router.asPath.indexOf('/erbjudanden') > -1 &&
      !router.asPath.startsWith(paths.PDP_ONLINE) &&
      !router.asPath.startsWith(paths.PDP_OFFLINE) &&
      router.isReady
    ) {
      const { name, mode: queryMode, productCode, ...query } = router.query;
      const pathname = '/erbjudanden/[mode]';
      const lsMode = getPromotionTabLocalStorage() as PromotionTabType;
      const mode = (queryMode || name || lsMode) as PromotionTabType;
      let onlineOrOffline = router.asPath?.split('-')[0]?.split('/')[2];

      if (onlineOrOffline) {
        [onlineOrOffline] = onlineOrOffline.split('?');
      }

      /* If mode is set but does not match pattern, redirect too root promotion page */
      if (mode && !`${mode}`.match(/^(ehandel|butik|online-.*|offline-.*|preview)$/)) {
        router.replace({ pathname: `/erbjudanden` });
      } else if (isPreview) {
        /* If we're coming from a product detail view we're checking if it's an online or offline product,
        and setting the correct tab. */
      } else if (onlineOrOffline === 'online' || onlineOrOffline === 'offline') {
        updatePromotionTab(MAPPED_TABS[onlineOrOffline] as string);
        setActiveTab(MAPPED_TABS[onlineOrOffline]);

        /* If we're coming from an email or link sent by axfood,
        we get the storeid param, but if we already have tab + store selected,
        we should go there. */
      } else if (
        (promotionTab === 'ehandel' && store?.storeId && store.activelySelected && query?.StoreID) ||
        (promotionTab === 'butik' && getPromotionStoreId() && query?.StoreID)
      ) {
        if (getPromotionStoreId() !== query?.StoreID) {
          logToNR(
            `Case 2: Using saved store ID: ${getPromotionStoreId()}, different from store ID in URL: ${query.StoreID}`
          );
        } else if (getPromotionStoreId() === query?.StoreID) {
          logToNR(`Case 3: Saved store ID: ${getPromotionStoreId()}, same as store ID in URL`);
        }

        router.replace({ pathname, query: { ...query, mode: lsMode || promotionTab } }, undefined, { shallow: true });

        if (lsMode !== promotionTab) {
          updatePromotionTab(lsMode as string);
        }

        setActiveTab(lsMode || promotionTab);
        /* If we're coming from an email, and we don't have tab + store selected,
        we should go to the tab and store provided in the email. But if query store points
        to a kpl store we should instead reroute to /erbjudanden/ehandel. */
      } else if (query?.StoreID && mode === 'butik') {
        logToNR(`Case 1: Using store ID from URL: ${query.StoreID}`);

        // TODO: useState on promotionPageStoreId
        dispatch(setPromotionPageStoreId(query?.StoreID as string));
        getStoreInformation(query.StoreID as string).then((res) => {
          if (!res?.external) {
            setSelectedOfflineStore(res);
            setActiveTab(mode);
            updatePromotionTab(mode as string);
          } else {
            router.replace(
              { pathname, query: { ...query, mode: Config.ROUTES.promotionpage.ecommerce } },
              `/erbjudanden/${Config.ROUTES.promotionpage.ecommerce}`,
              { shallow: true }
            );
            setActiveTab(Config.ROUTES.promotionpage.ecommerce as PromotionTabType);
            updatePromotionTab(Config.ROUTES.promotionpage.ecommerce);
          }
        });
        /* If we're coming in to the promotion page with a query mode (tab) in the url,
        we should go to that tab. */
      } else if (mode) {
        updatePromotionTab(mode);
        setActiveTab(mode as PromotionTabType);
        router.replace({ pathname, query: { ...query, mode } }, undefined, { shallow: true });
        /* If we're coming in to the promotion page with a tab saved in localstorage,
        we should go to that tab. */
      } else if (promotionTab !== null && `${promotionTab}`.match(/^(ehandel|butik)$/)) {
        router.replace({ pathname, query: { ...query, mode: promotionTab } }, `/erbjudanden/${promotionTab}`, {
          shallow: true,
        });
        setActiveTab(promotionTab as PromotionTabType);
        /* If we don't have any prior history on the promotionpage saved anywhere,
        and not provided in the url meaning we're just coming in to /erbjudanden ,
        we should reroute to /erbjudanden/ehandel. */
      } else {
        router.replace(
          { pathname, query: { ...query, mode: Config.ROUTES.promotionpage.ecommerce } },
          `/erbjudanden/${Config.ROUTES.promotionpage.ecommerce}`,
          { shallow: true }
        );
        setActiveTab(Config.ROUTES.promotionpage.ecommerce as PromotionTabType);
      }
    }
  }, [router.asPath, router.isReady]);

  return {
    promotionTab,
    activeTab,
    selectedOfflineStore,
    setSelectedOfflineStore,
    isPreview,
  };
};

export default usePromotionPageRouting;
