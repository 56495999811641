import { useState } from 'react';
import Text from '@atoms/Text/Text';
import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';
import {
  StyledContent,
  StyledFooterLink,
  StyledListContainer,
  StyledListItem,
  StyledTextWrapper,
  StyledToggleButton,
} from './MobileFooter.styles';
import { NavigationNodeWsDTO } from '@api/generated/cms';

interface Props {
  node: NavigationNodeWsDTO;
  parentNodes: Array<string>;
  isRoot?: boolean;
}

const MobileFooter = ({ node, parentNodes, isRoot = false }: Props) => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const hasChildren = !!node?.children && node.children.length > 0;
  const currentNodeHierarchy = parentNodes.concat(node.title || '');

  const childExpanded = (child: NavigationNodeWsDTO) => {
    return expandedNodes?.includes(child.title || '');
  };

  const onClickHandler = (child: NavigationNodeWsDTO) => {
    return childExpanded(child)
      ? setExpandedNodes(currentNodeHierarchy)
      : setExpandedNodes(currentNodeHierarchy.concat(child.title || ''));
  };

  const cookieSettingElement = (item: NavigationNodeWsDTO) => {
    return (
      <StyledTextWrapper style={{ paddingLeft: `${20 + parentNodes.length * 20}px` }}>
        <StyledFooterLink as={Text} className="optanon-toggle-display">
          {item.title}
        </StyledFooterLink>
      </StyledTextWrapper>
    );
  };

  const linkElement = (item: NavigationNodeWsDTO) => {
    const url = item?.links?.length ? item.links[0]?.url || '' : '';

    return (
      <StyledFooterLink
        variant="secondary"
        href={url}
        onClick={() => trackLinkNavigation('footer', item.title)}
        external={item?.links?.length ? item?.links[0]?.external : false}
      >
        <StyledTextWrapper style={{ paddingLeft: `${20 + parentNodes.length * 20}px` }}>{item.title}</StyledTextWrapper>
      </StyledFooterLink>
    );
  };

  return (
    <StyledListContainer>
      {hasChildren ? (
        <>
          {node.children?.map((child) => (
            <StyledListItem key={`mobile-footer-link-${child.uid}`} isExpanded={childExpanded(child)} isRoot={isRoot}>
              {!!child.children && child.children.length > 0 ? (
                <>
                  <StyledContent>
                    {linkElement(child)}
                    <StyledToggleButton type="button" onClick={() => onClickHandler(child)}>
                      {childExpanded(child) ? '–' : '+'}
                    </StyledToggleButton>
                  </StyledContent>
                  <MobileFooter node={child} parentNodes={currentNodeHierarchy} />
                </>
              ) : (
                <>{child.uid !== 'CookieSettings' ? linkElement(child) : cookieSettingElement(child)}</>
              )}
            </StyledListItem>
          ))}
        </>
      ) : (
        linkElement(node)
      )}
    </StyledListContainer>
  );
};

export default MobileFooter;
