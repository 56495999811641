/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AnimalData } from './animal-data';
// May contain unused imports in some cases
// @ts-ignore
import { AxfoodBreadCrumbsViewModel } from './axfood-bread-crumbs-view-model';
// May contain unused imports in some cases
// @ts-ignore
import { AxfoodProductLabelsViewModel } from './axfood-product-labels-view-model';
// May contain unused imports in some cases
// @ts-ignore
import { AxfoodPromotionViewModel } from './axfood-promotion-view-model';
// May contain unused imports in some cases
// @ts-ignore
import { BatteryInformationData } from './battery-information-data';
// May contain unused imports in some cases
// @ts-ignore
import { EnergyDeclaration } from './energy-declaration';
// May contain unused imports in some cases
// @ts-ignore
import { ImageData } from './image-data';
// May contain unused imports in some cases
// @ts-ignore
import { NutrientFactData } from './nutrient-fact-data';
// May contain unused imports in some cases
// @ts-ignore
import { ProductBasketTypeEnum } from './product-basket-type-enum';

/**
 * 
 * @export
 * @interface AxfoodProductDetailsViewModel
 */
export interface AxfoodProductDetailsViewModel {
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'activeSubstance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'addToCartDisabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'addToCartMessage'?: string;
    /**
     * 
     * @type {AnimalData}
     * @memberof AxfoodProductDetailsViewModel
     */
    'animalData'?: AnimalData;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'areBatteriesIncluded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'areBatteriesRequired': boolean;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'averageWeight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'bargainProduct'?: boolean;
    /**
     * 
     * @type {Array<BatteryInformationData>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'batteryInformation': Array<BatteryInformationData>;
    /**
     * 
     * @type {Array<AxfoodBreadCrumbsViewModel>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'breadCrumbs': Array<AxfoodBreadCrumbsViewModel>;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'categoryGroupText'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'comparePrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'comparePriceUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'compulsoryAdditiveLabelInformation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'consumerStorageInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'consumerUsageInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'countryOfOriginStatement'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'depositPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'dietTypeInformation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'displayVolume'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'ean'?: string;
    /**
     * 
     * @type {EnergyDeclaration}
     * @memberof AxfoodProductDetailsViewModel
     */
    'energyDeclaration'?: EnergyDeclaration;
    /**
     * 
     * @type {Array<AxfoodProductLabelsViewModel>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'extendedLabels'?: Array<AxfoodProductLabelsViewModel>;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'extraInfoCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'foodContactAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'foodContactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'fruitAndVegetableVariety'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'gdprTrackingIncompliant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'googleAnalyticsCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'hazards': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'healthClaimDescription'?: string;
    /**
     * 
     * @type {ImageData}
     * @memberof AxfoodProductDetailsViewModel
     */
    'image'?: ImageData;
    /**
     * 
     * @type {Array<AxfoodPromotionViewModel>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'inactivePotentialPromotions': Array<AxfoodPromotionViewModel>;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'incrementValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'ingredients'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'instructions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'isDrugProduct'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'manufacturer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'maxStorageTemperature'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'medicineLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'medicineType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'minStorageTemperature'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'newsSplashProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nicotineMedicalProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nonEkoProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'notAllowedAnonymous': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'notAllowedB2b': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nutritionDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nutritionFacts'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nutritionalClaim'?: string;
    /**
     * 
     * @type {Array<NutrientFactData>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'nutritionsFactList': Array<NutrientFactData>;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'online': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'originCountry'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'otherCountries': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'outOfStock'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'pickupProductLine2'?: string;
    /**
     * 
     * @type {Array<AxfoodPromotionViewModel>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'potentialPromotions': Array<AxfoodPromotionViewModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AxfoodProductDetailsViewModel
     */
    'precautionaryStatements': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'preparationDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'preparationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'priceNoUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'priceUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'priceValue'?: number;
    /**
     * 
     * @type {ProductBasketTypeEnum}
     * @memberof AxfoodProductDetailsViewModel
     */
    'productBasketType'?: ProductBasketTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'productLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'provinceStatement'?: string;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'ranking'?: number;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'regulatoryAct'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'salableOnline': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'saleStop'?: AxfoodProductDetailsViewModelSaleStopEnum;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'savingsAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'seoDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'seoTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'servingSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'showGoodPriceIcon': boolean;
    /**
     * 
     * @type {number}
     * @memberof AxfoodProductDetailsViewModel
     */
    'solrSearchScore'?: number;
    /**
     * 
     * @type {ImageData}
     * @memberof AxfoodProductDetailsViewModel
     */
    'thumbnail'?: ImageData;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'tobaccoFreeNicotineProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodProductDetailsViewModel
     */
    'tobaccoProduct': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'tradeItemCountryOfOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodProductDetailsViewModel
     */
    'variantType'?: string;
}

export const AxfoodProductDetailsViewModelSaleStopEnum = {
    Hard: 'HARD',
    Soft: 'SOFT'
} as const;

export type AxfoodProductDetailsViewModelSaleStopEnum = typeof AxfoodProductDetailsViewModelSaleStopEnum[keyof typeof AxfoodProductDetailsViewModelSaleStopEnum];


