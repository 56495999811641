/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MemberList } from '../cms-dto';
// @ts-ignore
import { UserGroup } from '../cms-dto';
// @ts-ignore
import { UserGroupList } from '../cms-dto';
/**
 * CustomerGroupsApi - axios parameter creator
 * @export
 */
export const CustomerGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assigns user(s) to a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Assigns user(s) to a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to assign to customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUserToCustomerGroupUsingPATCH1: async (groupId: string, baseSiteId: string, members: MemberList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('assignUserToCustomerGroupUsingPATCH1', 'groupId', groupId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('assignUserToCustomerGroupUsingPATCH1', 'baseSiteId', baseSiteId)
            // verify required parameter 'members' is not null or undefined
            assertParamExists('assignUserToCustomerGroupUsingPATCH1', 'members', members)
            const localVarPath = `/{baseSiteId}/customergroups/{groupId}/members`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(members, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new customer group that is a direct subgroup of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Creates a new customer group.
         * @param {string} baseSiteId Base site identifier
         * @param {UserGroup} userGroup User group object with id and name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCustomerGroupUsingPOST1: async (baseSiteId: string, userGroup: UserGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('createNewCustomerGroupUsingPOST1', 'baseSiteId', baseSiteId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('createNewCustomerGroupUsingPOST1', 'userGroup', userGroup)
            const localVarPath = `/{baseSiteId}/customergroups`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all customer groups that are direct subgroups of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get all subgroups of a customergroup.
         * @param {string} baseSiteId Base site identifier
         * @param {number} [currentPage] Current page number (starts with 0).
         * @param {number} [pageSize] Number of customer group returned in one page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerGroupsUsingGET: async (baseSiteId: string, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getAllCustomerGroupsUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/customergroups`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a customer group with a specific groupId.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get a specific customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroupUsingGET: async (groupId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getCustomerGroupUsingGET', 'groupId', groupId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getCustomerGroupUsingGET', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/customergroups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes user from a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Delete a user from a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} userId User identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromCustomerGroupUsingDELETE: async (groupId: string, userId: string, baseSiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('removeUsersFromCustomerGroupUsingDELETE', 'groupId', groupId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUsersFromCustomerGroupUsingDELETE', 'userId', userId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('removeUsersFromCustomerGroupUsingDELETE', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/customergroups/{groupId}/members/{userId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets members for a user group. The list of existing members is overwritten with a new one.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Sets members for a user group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to set for customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserListForCustomerGroupUsingPUT1: async (groupId: string, baseSiteId: string, members: MemberList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('setUserListForCustomerGroupUsingPUT1', 'groupId', groupId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('setUserListForCustomerGroupUsingPUT1', 'baseSiteId', baseSiteId)
            // verify required parameter 'members' is not null or undefined
            assertParamExists('setUserListForCustomerGroupUsingPUT1', 'members', members)
            const localVarPath = `/{baseSiteId}/customergroups/{groupId}/members`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(members, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerGroupsApi - functional programming interface
 * @export
 */
export const CustomerGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * Assigns user(s) to a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Assigns user(s) to a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to assign to customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignUserToCustomerGroupUsingPATCH1(groupId: string, baseSiteId: string, members: MemberList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignUserToCustomerGroupUsingPATCH1(groupId, baseSiteId, members, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new customer group that is a direct subgroup of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Creates a new customer group.
         * @param {string} baseSiteId Base site identifier
         * @param {UserGroup} userGroup User group object with id and name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewCustomerGroupUsingPOST1(baseSiteId: string, userGroup: UserGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewCustomerGroupUsingPOST1(baseSiteId, userGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all customer groups that are direct subgroups of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get all subgroups of a customergroup.
         * @param {string} baseSiteId Base site identifier
         * @param {number} [currentPage] Current page number (starts with 0).
         * @param {number} [pageSize] Number of customer group returned in one page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomerGroupsUsingGET(baseSiteId: string, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomerGroupsUsingGET(baseSiteId, currentPage, pageSize, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a customer group with a specific groupId.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get a specific customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerGroupUsingGET(groupId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGroupUsingGET(groupId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes user from a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Delete a user from a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} userId User identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUsersFromCustomerGroupUsingDELETE(groupId: string, userId: string, baseSiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUsersFromCustomerGroupUsingDELETE(groupId, userId, baseSiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets members for a user group. The list of existing members is overwritten with a new one.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Sets members for a user group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to set for customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserListForCustomerGroupUsingPUT1(groupId: string, baseSiteId: string, members: MemberList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserListForCustomerGroupUsingPUT1(groupId, baseSiteId, members, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerGroupsApi - factory interface
 * @export
 */
export const CustomerGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerGroupsApiFp(configuration)
    return {
        /**
         * Assigns user(s) to a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Assigns user(s) to a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to assign to customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUserToCustomerGroupUsingPATCH1(groupId: string, baseSiteId: string, members: MemberList, options?: any): AxiosPromise<void> {
            return localVarFp.assignUserToCustomerGroupUsingPATCH1(groupId, baseSiteId, members, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new customer group that is a direct subgroup of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Creates a new customer group.
         * @param {string} baseSiteId Base site identifier
         * @param {UserGroup} userGroup User group object with id and name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCustomerGroupUsingPOST1(baseSiteId: string, userGroup: UserGroup, options?: any): AxiosPromise<void> {
            return localVarFp.createNewCustomerGroupUsingPOST1(baseSiteId, userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all customer groups that are direct subgroups of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get all subgroups of a customergroup.
         * @param {string} baseSiteId Base site identifier
         * @param {number} [currentPage] Current page number (starts with 0).
         * @param {number} [pageSize] Number of customer group returned in one page.
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerGroupsUsingGET(baseSiteId: string, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<UserGroupList> {
            return localVarFp.getAllCustomerGroupsUsingGET(baseSiteId, currentPage, pageSize, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a customer group with a specific groupId.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Get a specific customer group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerGroupUsingGET(groupId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<UserGroup> {
            return localVarFp.getCustomerGroupUsingGET(groupId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes user from a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Delete a user from a customer group.
         * @param {string} groupId Group identifier.
         * @param {string} userId User identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromCustomerGroupUsingDELETE(groupId: string, userId: string, baseSiteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeUsersFromCustomerGroupUsingDELETE(groupId, userId, baseSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets members for a user group. The list of existing members is overwritten with a new one.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
         * @summary Sets members for a user group.
         * @param {string} groupId Group identifier.
         * @param {string} baseSiteId Base site identifier
         * @param {MemberList} members List of users to set for customer group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserListForCustomerGroupUsingPUT1(groupId: string, baseSiteId: string, members: MemberList, options?: any): AxiosPromise<void> {
            return localVarFp.setUserListForCustomerGroupUsingPUT1(groupId, baseSiteId, members, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerGroupsApi - object-oriented interface
 * @export
 * @class CustomerGroupsApi
 * @extends {BaseAPI}
 */
export class CustomerGroupsApi extends BaseAPI {
    /**
     * Assigns user(s) to a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Assigns user(s) to a customer group.
     * @param {string} groupId Group identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {MemberList} members List of users to assign to customer group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public assignUserToCustomerGroupUsingPATCH1(groupId: string, baseSiteId: string, members: MemberList, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).assignUserToCustomerGroupUsingPATCH1(groupId, baseSiteId, members, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new customer group that is a direct subgroup of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Creates a new customer group.
     * @param {string} baseSiteId Base site identifier
     * @param {UserGroup} userGroup User group object with id and name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public createNewCustomerGroupUsingPOST1(baseSiteId: string, userGroup: UserGroup, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).createNewCustomerGroupUsingPOST1(baseSiteId, userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all customer groups that are direct subgroups of a customergroup.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Get all subgroups of a customergroup.
     * @param {string} baseSiteId Base site identifier
     * @param {number} [currentPage] Current page number (starts with 0).
     * @param {number} [pageSize] Number of customer group returned in one page.
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public getAllCustomerGroupsUsingGET(baseSiteId: string, currentPage?: number, pageSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).getAllCustomerGroupsUsingGET(baseSiteId, currentPage, pageSize, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a customer group with a specific groupId.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Get a specific customer group.
     * @param {string} groupId Group identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public getCustomerGroupUsingGET(groupId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).getCustomerGroupUsingGET(groupId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes user from a customer group.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Delete a user from a customer group.
     * @param {string} groupId Group identifier.
     * @param {string} userId User identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public removeUsersFromCustomerGroupUsingDELETE(groupId: string, userId: string, baseSiteId: string, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).removeUsersFromCustomerGroupUsingDELETE(groupId, userId, baseSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets members for a user group. The list of existing members is overwritten with a new one.  To try out the methods of the Customer Groups controller, you must authorize a user who belongs to the “customermanagergroup”.
     * @summary Sets members for a user group.
     * @param {string} groupId Group identifier.
     * @param {string} baseSiteId Base site identifier
     * @param {MemberList} members List of users to set for customer group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public setUserListForCustomerGroupUsingPUT1(groupId: string, baseSiteId: string, members: MemberList, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).setUserListForCustomerGroupUsingPUT1(groupId, baseSiteId, members, options).then((request) => request(this.axios, this.basePath));
    }
}
