import {
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodOfflineProductViewModel,
  AxfoodOfflinePromotionViewModel,
  AxfoodOrderProductViewModel,
  AxfoodProductDetailsViewModel,
  AxfoodPromotionViewModel,
} from '@api/generated/storefront';

export const isOnlineProduct = (
  product:
    | AxfoodProductDetailsViewModel
    | AxfoodOfflineProductViewModel
    | AxfoodOrderProductViewModel
    | AxfoodBasicProductViewModel
): product is AxfoodProductDetailsViewModel | AxfoodOrderProductViewModel | AxfoodBasicProductViewModel => {
  return product?.online === true;
};

export const isOfflineProduct = (
  product: AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel | AxfoodBasicProductViewModel
): product is AxfoodOfflineProductViewModel => {
  return product?.online === false;
};

export const isCartProduct = (
  product: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel
): product is AxfoodCartProductViewModel => 'totalDeposit' in product || 'appliedPromotions' in product;

export const isOfflinePromotion = (
  promotion: AxfoodOfflinePromotionViewModel | AxfoodPromotionViewModel | undefined
): promotion is AxfoodOfflinePromotionViewModel => (promotion ? 'name' in promotion : false);
