import { useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useCustomRouter from '@hooks/useCustomRouter';
import Link from '@atoms/Link/Link';
import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import paths from '@constants/paths';
import webToAppApi from '@api/web-to-app';

import {
  StyledFilterCheckBoxAndCount,
  StyledFilterList,
  StyledFilterListCheckbox,
  StyledFilterListLabel,
  StyledFilterListOptions,
  StyledFilterListOptionsContainer,
  StyledFilterListSelectedText,
  StyledFilterListShowAll,
  StyledFilterListSpan,
} from './FilterList.styles';
import { StyledCloseIcon } from '@atoms/Tile/Tile.styles';
import Text from '@atoms/Text/Text';
import { BreadcrumbDataOfSearchStateData, FacetDataOfobject, FacetValueDataOfobject } from '@api/generated/storefront';
import { BreadcrumbDataWithFaceValueName } from '@molecules/SelectedFilters/SelectedFilters';
import PlusSmall from '@icons/plus_small.svg';
import MinusSmall from '@icons/minus_small.svg';
import CloseSmall from '@icons/close_small.svg';

type Props = {
  code: string;
  name: string;
  option: FacetDataOfobject;
  expandedListName: string;
  toggleList: () => void;
  isMobile?: boolean;
  breadcrumbs: Array<BreadcrumbDataOfSearchStateData>;
};

const FilterList = ({
  option = {} as FacetDataOfobject,
  name,
  expandedListName,
  toggleList,
  isMobile,
  breadcrumbs,
  code,
}: Props) => {
  const filterListOptions = useRef<HTMLDivElement>(null);
  // TODO: Can we remove this state?
  const [showAllButton, setShowAllButton] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const { t } = useTranslation('productFilter');
  const router = useCustomRouter();
  const { pathname } = router;

  const notifyApp = (filterValue: string) => {
    if (router.pathname === paths.OFFERS) {
      webToAppApi.eventOffersFiltered(filterValue);
    }
  };

  return (
    <>
      {option && (
        <>
          <StyledFilterList expanded={expandedListName === option.name} onClick={toggleList} key={option.name}>
            <div>
              <StyledFilterListSpan>{name}</StyledFilterListSpan>
              {expandedListName !== option.name && breadcrumbs.length > 0 && (
                <div>
                  {breadcrumbs
                    .filter((breadcrumb) => breadcrumb.facetCode === code)
                    .filter(
                      (breadcrumb): breadcrumb is BreadcrumbDataWithFaceValueName =>
                        breadcrumb.facetValueName !== undefined
                    )
                    .map((breadcrumb, index) => (
                      <StyledFilterListSelectedText
                        type="body"
                        size="small"
                        key={`${breadcrumb.facetValueName}-${index}`}
                      >
                        {`${index > 0 ? ', ' : ''}${breadcrumb.facetValueName}`}
                      </StyledFilterListSelectedText>
                    ))}
                </div>
              )}
            </div>
            {expandedListName === option.name ? (
              <Icon svg={MinusSmall} size={12} color="primary" />
            ) : (
              <Icon svg={PlusSmall} size={12} />
            )}
          </StyledFilterList>
          <StyledFilterListOptions
            expanded={expandedListName === option.name}
            showAll={expandedListName === option.name && (isMobile || showAllFilters)}
            ref={filterListOptions}
          >
            <StyledFilterListOptionsContainer>
              {option?.values?.map((value: any) => {
                /*
                                  TODO: Refactor to only use value?.query?.query?.value
                                  This code is required because the value property contains a lot of Solr-specific
                                  internals (boost rules etc.) that we do not want in the URL.
                                  Temporary solution is to use value?.query?.url (only for /erbjudanden) but it contains
                                  the full categoryPath so it cannot be used for the category page.
                                 */

                let filterQuery = value?.query?.query?.value;
                if (router.pathname?.startsWith('/erbjudanden')) {
                  filterQuery = decodeURIComponent(value?.query?.url);
                }

                if (value.name && value.code && (filterQuery || (!filterQuery && value?.selected))) {
                  return (
                    <Link
                      internal
                      scroll={false}
                      href={{ pathname, query: { ...router.query, q: filterQuery } }}
                      onClick={() => notifyApp(value.name)}
                      key={value.code}
                      shallow
                    >
                      <StyledFilterListLabel>
                        <Text type="body" size="medium">
                          {value.name}
                        </Text>

                        <StyledFilterCheckBoxAndCount>
                          {value.count && (
                            <Text type="body" size="medium">
                              {value.count}
                            </Text>
                          )}
                          {!value.count && <StyledCloseIcon svg={CloseSmall} size={9} />}
                          <StyledFilterListCheckbox
                            checked={value.selected}
                            onChange={() => !value.selected}
                            id={value.name}
                          />
                        </StyledFilterCheckBoxAndCount>
                      </StyledFilterListLabel>
                    </Link>
                  );
                }
                return false;
              })}
            </StyledFilterListOptionsContainer>
          </StyledFilterListOptions>

          {showAllButton && expandedListName === option.name && (
            <StyledFilterListShowAll>
              <Button onClick={() => setShowAllFilters(!showAllFilters)} theme="link">
                {showAllFilters ? t('showLess') : t('showAll')}
              </Button>
            </StyledFilterListShowAll>
          )}
        </>
      )}
    </>
  );
};

export default FilterList;
