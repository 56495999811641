import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import Modal from '@organisms/Modal/Modal';
import Text from '@atoms/Text/Text';
import useTranslation from 'next-translate/useTranslation';
import { StyledHeading } from './OrderCloseTimePassedModal.styles';
import useCustomRouter from '@hooks/useCustomRouter';
import { useEffect, useState } from 'react';
import paths from '@constants/paths';
import { setOrderCloseTimePassedModalVisibility, selectOrderCloseTimePassedModal } from '@slices/modalSlice';
import clearCart from '@api/clearCart';
import UserAgent from '@helpers/useragent';
import webToAppApi from '@api/web-to-app';
import useCart from '@hooks/useCart';

const OrderCloseTimePassedModal = () => {
  const { t } = useTranslation('orderCloseTimePassedModal');
  const dispatch = useAppDispatch();
  const router = useCustomRouter();

  const { cart, refreshCart } = useCart();
  const { visible } = useAppSelector(selectOrderCloseTimePassedModal);
  const [orderReference, setOrderReference] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      (async () => {
        setLoading(true);
        setOrderReference(cart?.orderReference as string);
        // Stop changing order when modal appears
        const emptiedCart = await clearCart(true);
        await refreshCart(emptiedCart.data);

        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const close = async () => {
    dispatch(setOrderCloseTimePassedModalVisibility(false));

    if (UserAgent.isNativeApp()) {
      webToAppApi.eventCancelOrderUpdate(0);
    } else {
      router.replace(
        { pathname: paths.ACCOUNT_ORDER_DETAILS, query: { ...router.query } },
        { pathname: `${paths.ACCOUNT_ORDERS}/${orderReference}` }
      );
    }
  };

  return (
    <>
      {visible && (
        <Modal
          size="small"
          onConfirm={close}
          confirmButtonText={t('modal->buttons->ok')}
          closeOnEscape
          buttonWidth={100}
          isSubmitting={loading}
        >
          <StyledHeading variant="h2" size="small">
            {t('modal->title')}
          </StyledHeading>

          <Text type="body">{t('modal->text->line1')}</Text>
          <Text type="body">{t('modal->text->line2')}</Text>
        </Modal>
      )}
    </>
  );
};

export default OrderCloseTimePassedModal;
